import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import {Observable} from 'rxjs';
import * as fromFeature from './../reducers/insurance.reducer';
import * as fromActions from './../actions/issuer-navigation.actions';
const debug = Debug('modeso:twint-lib-admin-fs NavigationIssuerProvider');

@Injectable({
    providedIn: 'root'
})
export class NavigationIssuerProvider {
    constructor(private store: Store<fromFeature.AppState>) { }

    public dispatchSetNavigationTab(navTab: string) {
        this.store.dispatch(fromActions.onNavigateToNewTab({payload: navTab}));
    }

    public getNavigationTab$(): Observable<string> {
       return this.store.pipe(select(fromFeature.selectNavTab));
    }
}
