import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DefaultFraudLimitsDescriptionsHelper {
    private defaultFraudLimitsDescriptionsMap: Map<string, string>;

    constructor() {
        this.defaultFraudLimitsDescriptionsMap = new Map([
            ["BLOCKING_PERIOD", "Blocking period by day for user"],
            ["TOTAL_PURCHASING_THRESHOLD", "Maximum amount in CHF to be purchased by day for shop"],
            ["MULTIPLY_BY_LIMIT", "Percentage to increase in case of unblocking for shop or product"],
            ["MAX_DUPLICATE_MAIL_LIMIT", "Maximum repetition for user email"],
            ["USER_PURCHASING_THRESHOLD", "Maximum amount in CHF to be purchased by day for user"],
            ["PRODUCTLINE_PURCHASING_THRESHOLD", "Maximum amount in CHF to be purchased by day for product line"]
        ]);
    }

    getDefaultFraudLimitDescription(defaultFraudLimitName: string): string {
        return this.defaultFraudLimitsDescriptionsMap.get(defaultFraudLimitName);
    }
}
