import { HttpErrorResponse } from '@angular/common/http';
import { IIssuerAllSpotlightOrder, ISpotlight } from '@modeso/types__ihub-lib-products-be';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from '../actions/spotlight.actions';
import Debug from 'debug';
export const adminSpotlightFeatureKey = 'modesoSpotlightAdmin';
const debug = Debug('ihub:spotlight:reducer');

// State Declarations - START

export interface FeatureState {
    spotlights: Array<ISpotlight>;
    spotlightsOrder: Array<IIssuerAllSpotlightOrder>;
    activeSpotlight: ISpotlight | null;
    error: HttpErrorResponse;
}

export interface AppState {
    modesoSpotlightAdmin: FeatureState;
}

// State Declarations - END


// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoSpotlightAdmin;

export const featureSpotlights = createSelector(
    selectFeature,
    (state: FeatureState) => state.spotlights
);
export const featureSpotlightsOrder = createSelector(
    selectFeature,
    (state: FeatureState) => state.spotlightsOrder
);
export const featureActiveSpotlights = createSelector(
    selectFeature,
    (state: FeatureState) => state.activeSpotlight
);

export const featureError = createSelector(
    selectFeature,
    (state: FeatureState) => state.error,
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    spotlights: new Array<ISpotlight>(),
    spotlightsOrder: new Array<IIssuerAllSpotlightOrder>(),
    activeSpotlight: null,
    error: undefined,
};

const spotlightReducer = createReducer(
    initialState,
    on(fromActions.onGetSpotlights, (state, action) => ({
        ...state, spotlights: new Array<ISpotlight>(), error: undefined,
    })),

    on(
        fromActions.onGetSpotlightsSuccessfully,
        fromActions.onDeleteSpotlightSuccessfully, (state, action) => {
        debug("onGetSpotlightsSuccessfully/onDeleteSpotlightSuccessfully: ", action);
        const allIssuerSpotlights = action.payload;
        if (allIssuerSpotlights.length > 0) {
            const spotlights = [...allIssuerSpotlights[0].visibleSpotlights, ...allIssuerSpotlights[0].hiddenSpotlights];
            return { ...state, spotlights, spotlightsOrder: action.payload, error: undefined };
        } else {
            return { ...state, spotlights: [], spotlightsOrder: action.payload, error: undefined };
        }
    }),
    on(fromActions.onGetSpotlightsFailed, (state, action) => ({
        ...state, spotlights: undefined, error: action.payload,
    })),
    on(fromActions.onAddSpotlight, (state, action) => ({
        ...state, activeSpotlight: null
    })),
    on(fromActions.onAddSpotlightSuccessfully, (state: FeatureState, action) => {
        return { ...state, spotlights: [...state.spotlights, action.payload], activeSpotlight: action.payload, error: undefined };
    }),
    on(fromActions.onSelectSpotlight, (state: FeatureState, action) => {
        debug("select: ", action);
        const filterd = state.spotlights.filter((x) => x._id === action.payload);
        if (filterd.length > 0) {
            debug("filterd 1: ", filterd);
            return { ...state, activeSpotlight: filterd[0], error: undefined };
        } else {
            debug("filterd: ", filterd);
            return { ...state, activeSpotlight: null, error: undefined };

        }
    }),
    on(fromActions.onAddSpotlightFailed, (state, action) => ({
        ...state, error: action.payload,
    })),
    on(fromActions.onUpdateSpotlight, (state, action) => ({
        ...state, activeSpotlight: undefined,
    })),
    on(fromActions.onUpdateSpotlightSuccessfully, (state: FeatureState, action) => {
        return { ...state, spotlights: [...state.spotlights, action.payload],
            activeSpotlight: action.payload, error: undefined };
    }),
    on(fromActions.onUpdateSpotlightFailed, (state, action) => ({
        ...state, error: action.payload
    })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return spotlightReducer(state, action);
}
