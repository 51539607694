import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminProductProvider } from '../../shared/provider/product.provider';
import { BasePageComponent } from '../base.page';
import {take} from 'rxjs/operators';
import { IProductWithDelayedPayments } from '../../shared/models/productWithDelayedPayment.model';
@Component({
  selector: 'app-delay-payment-for-product',
  templateUrl: './delay-payment-for-product.component.html',
  styleUrls: ['./delay-payment-for-product.component.scss']
})
export class DelayPaymentForProductComponent extends BasePageComponent implements OnInit {
  public keys: string[];
  public products: IProductWithDelayedPayments[];
  public productsForm: FormGroup;
  constructor(private fb: FormBuilder, private adminProductProvider: AdminProductProvider, injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(
        this.adminProductProvider.getAllProductsInAllLanguages$().subscribe((allProducts: any[]) => {
          if(!allProducts) {
            return;
          }
          this.products = allProducts;
          this.initForm();
    }));
   
  }

  get product() {
    return this.productsForm.controls["product"] as FormArray;
  }

  public editRow(group: FormControl) {
    group.get('isEditMode').setValue(true);
  }

  public cancel(index: number) {
    if (this.products[index]) {
      const prepareForm =  this.createTableRow({...this.products[index], isEditMode: false});
      this.product.at(index).setValue(prepareForm.value);
    } else {
      this.product.controls.pop();
    }
  }

  public saveEditList(product: FormControl) {
    const productId = product.get('productId').value;
    const delayedPayment = product.get('delayPaymentByMonth').value;
    this.adminProductProvider.updateProductDelayedPayments(productId, delayedPayment);
    this.adminProductProvider.getDelayedPaymentState$().pipe(take(2)).subscribe((state: boolean) => {
      if(!state) return;
      product.get('isEditMode').setValue(false);
    });
  }

  private initForm() {
    const formInitializerArray = this.prepareFormControlRows();
    this.productsForm = this.fb.group({
      product: this.fb.array(formInitializerArray)
    });
  }
  private prepareFormControlRows() {
    return this.products.map((x) => this.createTableRow({...x, isEditMode: false}));
  }

  private createTableRow(product: any): FormGroup {
    return this.fb.group({
      productId: new FormControl(product.product),
      title:  new FormControl(product.title['en-us']),
      delayPaymentByMonth: new FormControl(product.delayPaymentByMonth, [Validators.min(0)]),
      isEditMode: new FormControl(product.isEditMode || false),
    });
  }
}
