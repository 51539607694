import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '../admin.config';
import { ISpecailDealAdmin } from '../models/administration/specialDealsAdmin.model';


@Injectable({
    providedIn: 'root',
})
export class AdminUnLockingService {
    private readonly adminUsers = 'admin/users';
    private readonly server = '';
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }
    public getAllLockedUsers() {
        const url = `${this.server}${this.adminUsers}/locked`;
        return this.http.get<ISpecailDealAdmin[]>(url);
    }
    public unLockedAdminUser(id: string) {
        const url = `${this.server}${this.adminUsers}/unlocked/${id}`;
        return this.http.put<ISpecailDealAdmin>(url, {});
    }
}
