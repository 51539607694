export enum PaymentStatusEnum {
    PAID = 0,
    DECLINE_IN_PREMIUMS = 1,
    FISRT_REMINDER = 2,
    SECOND_REMINDER = 3,
    THIRD_REMINDER = 4,
    FOURTH_REMINDER = 5,
    LAST_REMINDER = 6,
    SUSPENDED= 7
}
