import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-unlock-users.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AdminUnLockingService } from '../services/admin-unlock-admin-users';

const debug = Debug('modeso:twint-lib-admin-fe:AdminUnlockingEffects');

@Injectable()
export class AdminUnlockingEffects {
    constructor(private actions$: Actions, private service: AdminUnLockingService) { }
    getAllLockedUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetLockedAdminUsers.type),
            mergeMap(
                () => {
                    return this.service.getAllLockedUsers()
                        .pipe(
                            map((response) => fromActions.onGetLockedAdminUsersSuccessfully({ payload: response })

                            ),
                            catchError((error) => of(fromActions.onGetLockedAdminUsersFailed({ payload: error })))
                        );
                }
            )
        )
    );

    errorOnGetAllLockedUsers = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetLockedAdminUsersFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    unLockUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUnLockAdminUser.type),
            mergeMap(
                (payload: fromActions.ActionWithPayload<string>) => {
                    return this.service.unLockedAdminUser(payload.payload)
                        .pipe(
                            map((response) => fromActions.onUnLockedAdminUserSuccessfully({ payload: response })

                            ),
                            catchError((error) => of(fromActions.onUnLockedAdminUsersFailed({ payload: error })))
                        );
                }
            )
        )
    );

    errorOnUnLockUserss = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUnLockedAdminUsersFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }

}


