import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import { ProductContentUpdate, ProductContentUpdateRequest, ProductContentUpdateResponse } from '../models/manageProductContent/updateProductContent.model';
import { ProductEnforceTcUpdateRequest, ProductEnforceTcUpdateResponse } from '../models/manageProductContent/updateProductEnforceTC.model';

export const getAllProductContent = createAction(
    '[Admin ManageProductContent] getAllProductContent'
);

export const onGetAllProductContentSuccessfully = createAction(
    '[Admin ManageProductContent] getAllProductContent successfully',
    props<{ payload: ManageProductsResponse }>()
);

export const onGetAllProductContentFailed = createAction(
    '[Admin ManageProductContent] getAllProductContent failed',
    props<{ payload: HttpErrorResponse }>()
);

export const updateContentByProductLine = createAction(
    '[Admin ManageProductContent] updateContentByProductLine',
    props<{ payload: ProductContentUpdateRequest }>()
);

export const onUpdateContentByProductLineSuccessfully = createAction(
    '[Admin ManageProductContent] updateContentByProductLine successfully',
    props<{ payload: ProductContentUpdateResponse }>()
);

export const onUpdateContentByProductLineFailed = createAction(
    '[Admin ManageProductContent] updateContentByProductLine failed',
    props<{ payload: HttpErrorResponse }>()
);

export const updateEnforceTcByProductLine = createAction(
    '[Admin ManageProductContent] updateEnforceTcByProductLine',
    props<{ payload: ProductEnforceTcUpdateRequest }>()
);

export const onUpdateEnforceTcByProductLineSuccessfully = createAction(
    '[Admin ManageProductContent] updateEnforceTcByProductLine successfully',
    props<{ payload: ProductEnforceTcUpdateResponse }>()
);

export const onUpdateEnforceTcByProductLineFailed = createAction(
    '[Admin ManageProductContent] updateEnforceTcByProductLine failed',
    props<{ payload: HttpErrorResponse }>()
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
