import { Injectable } from '@angular/core';
import * as fromFeature from './../reducers/insurance.reducer';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from './../actions/insurance.actions';
import { IInsuranceForAnalytics, IInsurancePayment } from '../models/insurance.models';
import Debug from 'debug';
import { AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import { InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';

const debug = Debug('modeso:twint-lib-admin-fe:AdminInsuranceProvider');

@Injectable({
    providedIn: 'root'
})
export class AdminInsuranceProvider {

    constructor(private store: Store<fromFeature.AppState>) { }


    public getInsurance$(): Observable<IInsuranceForAnalytics[]> {
        return this.store.pipe(select(fromFeature.featureInsurances));
    }

    public fetchInsurancesByName(filter1: any): void {
        this.store.dispatch(fromActions.getInsurances({ payload: filter1 }));
    }

    public fetchInsurancesByPolicy(filter1: any): void {
        this.store.dispatch(fromActions.getInsurances({ payload: filter1 }));
    }

    public fetchPayments(filter1: AnalyticsReportRequest): void {
        this.store.dispatch(fromActions.getPayments({ payload: filter1 }));
    }
    public getPayments$(): Observable<IInsurancePayment[]> {
        return this.store.pipe(select(fromFeature.featurePayments));
    }

    public updateInsuranceStatus( id: string, endDate: Date, insuranceStatus: InsuranceStatusEnum) {
      this.store.dispatch(fromActions.updateInsuranceStatus( { payload: {id, endDate, insuranceStatus}}));
    }
    public dispatchGetFailedPayments(filter1: AnalyticsReportRequest): void {
        this.store.dispatch(fromActions.getFailedPayments({ payload: filter1 }));
    }
    public getFailedPayments$(): Observable<IInsurancePayment[]> {
        return this.store.pipe(select(fromFeature.featureFailedPayments));
    }
    public dispatchCancelInsurance(id: string, endDate: Date,insuranceStatus:InsuranceStatusEnum): void {
        this.store.dispatch(fromActions.cancelInusrance({ payload: {id, endDate , insuranceStatus}}));
    }

    public importInsurances(body) {
        this.store.dispatch(fromActions.importInusrances({payload: body}));
        return this.store.pipe(select(fromFeature.selectImportInsurance));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null)
        );
    }
}

