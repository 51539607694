export const environment = {
  production: true,
  allowLoginWithUsernameAndPassword: true,
  modeso: {
    apiGateway: 'https://api-ihub-testing.modesoventures.com/api/'
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch'],
  enableDebugging: 'modeso:*, dgoods:*, ihub:*'
};
