import { UserRoles } from '@modeso/twint-lib-admin-fe';
import { PermissionHelper } from './util/permission.helper';
import * as i0 from "@angular/core";
import * as i1 from "./services/localStorage.service";
import * as i2 from "@angular/router";
export class IssuerPageGuard {
    constructor(localStorageService, router) {
        this.localStorageService = localStorageService;
        this.router = router;
    }
    canActivate() {
        if (this.hasPermission()) {
            return true;
        }
        else {
            this.router.navigate(['']);
            return false;
        }
    }
    hasPermission() {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
IssuerPageGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IssuerPageGuard_Factory() { return new IssuerPageGuard(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Router)); }, token: IssuerPageGuard, providedIn: "root" });
