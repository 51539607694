<div class="container" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
  <div class="row">
    <div class="table-container">
      <div>
        <mat-form-field style="width: 270px">
          <input
            matInput
            (keyup)="applyFilter($event.target.value)"
            placeholder="Filter (Fulltext in all languages and keys)"
          />
        </mat-form-field>
      </div>
      <table mat-table [dataSource]="dataSource">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell fxFlex="100px" *matHeaderCellDef></th>
          <td mat-cell fxFlex="100px" *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Key Column -->
        <ng-container matColumnDef="Key">
          <th mat-header-cell *matHeaderCellDef>Key</th>
          <td mat-cell *matCellDef="let element">{{element.key}}</td>
        </ng-container>

        <!-- de-ch Column -->
        <ng-container matColumnDef="de-ch">
          <th mat-header-cell *matHeaderCellDef>de-ch</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHtml]='element.text["de-ch"]'
          ></td>
        </ng-container>

        <!-- en-us Column -->
        <ng-container matColumnDef="en-us">
          <th mat-header-cell *matHeaderCellDef>en-us</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHtml]='element.text["en-us"]'
          ></td>
        </ng-container>

        <!-- fr-ch Column -->
        <ng-container matColumnDef="fr-ch">
          <th mat-header-cell *matHeaderCellDef>fr-ch</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHtml]='element.text["fr-ch"]'
          ></td>
        </ng-container>

        <!-- it-ch Column -->
        <ng-container matColumnDef="it-ch">
          <th mat-header-cell *matHeaderCellDef>it-ch</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHtml]='element.text["it-ch"]'
          ></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="manageContentColumns;  sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: manageContentColumns;"
          (click)="selection.toggle(row)"
        ></tr>
      </table>
      <mat-paginator
        #localizationsPaginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <div class="row">
    <div class="messages">
      <span class="failure" *ngIf="isLocalizationsError">{{errorMessage}}</span>
      <span class="failure" *ngIf="isServerError">{{serverErrorMessage}}</span>
    </div>

    <div class="action-button">
      <button
        *ngIf="hasWritePermission()"
        id="updateLocalizationButton"
        [disabled]="localizationButtonStatus()"
        (click)="onUpdateLocalization()"
      >
        Update
      </button>
    </div>
  </div>
</div>
