/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-delete-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirm-delete-modal.component";
import * as i8 from "@modeso/twint-lib-admin-fe";
var styles_ConfirmDeleteModalComponent = [i0.styles];
var RenderType_ConfirmDeleteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDeleteModalComponent, data: {} });
export { RenderType_ConfirmDeleteModalComponent as RenderType_ConfirmDeleteModalComponent };
export function View_ConfirmDeleteModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Delete Sorting Irder"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["Are You Sure Want to delete this Sorting Order?"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).dialogRef.close(i1.ɵnov(_v, 9).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["color", "warn"], ["mat-raised-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.confirmDeletion() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Delete"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = ""; _ck(_v, 9, 0, currVal_5); var currVal_10 = true; _ck(_v, 13, 0, currVal_10); var currVal_11 = "warn"; _ck(_v, 14, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).ariaLabel || null); var currVal_2 = i1.ɵnov(_v, 9).type; var currVal_3 = (i1.ɵnov(_v, 10).disabled || null); var currVal_4 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 13).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 13).type; var currVal_8 = (i1.ɵnov(_v, 14).disabled || null); var currVal_9 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ConfirmDeleteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-delete-modal", [], null, null, null, View_ConfirmDeleteModalComponent_0, RenderType_ConfirmDeleteModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmDeleteModalComponent, [i2.MAT_DIALOG_DATA, i8.AdminIssuersProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDeleteModalComponentNgFactory = i1.ɵccf("app-confirm-delete-modal", i7.ConfirmDeleteModalComponent, View_ConfirmDeleteModalComponent_Host_0, {}, {}, []);
export { ConfirmDeleteModalComponentNgFactory as ConfirmDeleteModalComponentNgFactory };
