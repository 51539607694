import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminFraudLimitsService } from '../services/admin-fraudLimits.service';
import * as fromActions from '../actions/admin-fraudLimits.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import { IssuerLimitsOfAddOrUpdateRequest, IssuerLimitsOfDeleteRequest } from '../models/fraudLimits/issuerLimits.model';

const debug = Debug('modeso:twint-lib-admin-fe:AdminFraudLimitsEffects');


@Injectable()
export class AdminFraudLimitsEffects {
    constructor(private actions$: Actions, private service: AdminFraudLimitsService) { }

    getFraudLimits$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getFraudLimits.type),
            mergeMap(
                () => {
                    return this.service.getFraudLimits()
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onGetFraudLimitsSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetFraudLimitsFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetFraudLimits$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetFraudLimitsFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    updateDefaultFraudLimit$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateDefaultFraudLimit.type),
            mergeMap(
                (payload: any) => {
                    const updatedDefaultFraud: FraudLimitsResponse = {
                        name: payload.payload.fraudLimit,
                        value: +payload.payload.fraudLimitValue
                    };
                    return this.service.updateDefaultFraudLimit(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onUpdateDefaultFraudLimitSuccessfully({ payload: updatedDefaultFraud });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onUpdateDefaultFraudLimitFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnUpdatedefaultFraudLimit$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateDefaultFraudLimitFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    addIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.addIssuer.type),
            mergeMap(
                (payload: any) => {
                    const issuer: IssuerLimitsOfAddOrUpdateRequest = {
                        name: payload.payload.name,
                        value: payload.payload.value,
                        timeframeName: payload.payload.timeframeName,
                        timeframeValue: payload.payload.timeframeValue
                    };
                    return this.service.addIssuer(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onAddIssuerSuccessfully({ payload: issuer });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onAddIssuerFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnAddIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddIssuerFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    updateIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateIssuer.type),
            mergeMap(
                (payload: any) => {
                    const issuer: IssuerLimitsOfAddOrUpdateRequest = {
                        name: payload.payload.name,
                        value: payload.payload.value,
                        timeframeName: payload.payload.timeframeName,
                        timeframeValue: payload.payload.timeframeValue
                    };
                    return this.service.updateIssuer(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onUpdateIssuerSuccessfully({ payload: issuer });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onUpdateIssuerFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnUpdateIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateIssuerFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    deleteIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.deleteIssuer.type),
            mergeMap(
                (payload: any) => {
                    const issuerName: IssuerLimitsOfDeleteRequest = {
                        issuerName: payload.payload.issuerName
                    };
                    return this.service.deleteIssuer(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onDeleteIssuersuccessfully({ payload: issuerName });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onDeleteIssuerFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnDeleteIssuer$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onDeleteIssuerFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}

