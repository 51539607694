import * as fromFeature from './../reducers/product.reducer';
import { select } from '@ngrx/store';
import Debug from 'debug';
import * as fromActions from './../actions/product.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
const debug = Debug('modeso:admin:AdminProductProvider');
export class AdminProductProvider {
    constructor(store) {
        this.store = store;
    }
    getAllProductsInAllLanguages$() {
        this.store.dispatch(fromActions.getAllProductsInAllLanguages());
        return this.store.pipe(select(fromFeature.selectFeatureGetAllProductsAllLanguages));
    }
    updateProductDelayedPayments(productId, delayedPayment) {
        debug('updateProductDelayedPayments', productId, delayedPayment);
        this.store.dispatch(fromActions.updateDelayedPayments({ payload: { productId, delayedPayment } }));
    }
    getDelayedPaymentState$() {
        return this.store.pipe(select(fromFeature.selectFeatureDelayedPayment));
    }
}
AdminProductProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminProductProvider_Factory() { return new AdminProductProvider(i0.ɵɵinject(i1.Store)); }, token: AdminProductProvider, providedIn: "root" });
