import { Injectable } from '@angular/core';
import * as fromFeature from './../reducers/product.reducer';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { AllAvailbleProducts } from '@modeso/twint-lib-admin-fe';
import * as fromActions from './../actions/product.actions';
import {Observable } from 'rxjs';
import { IProductWithDelayedPayments } from '../models/productWithDelayedPayment.model';
const debug = Debug('modeso:admin:AdminProductProvider');

@Injectable({
    providedIn: 'root'
})
export class AdminProductProvider {

    constructor(private store: Store<fromFeature.AppState>) { }
    public getAllProductsInAllLanguages$(): Observable<IProductWithDelayedPayments[]> {
        this.store.dispatch(fromActions.getAllProductsInAllLanguages());
        return this.store.pipe(select(fromFeature.selectFeatureGetAllProductsAllLanguages));
    }

    public updateProductDelayedPayments(productId: string, delayedPayment: number) {
        debug('updateProductDelayedPayments', productId, delayedPayment);
        this.store.dispatch(fromActions.updateDelayedPayments({payload : {productId, delayedPayment}}));
    }

    public getDelayedPaymentState$(): Observable<boolean> {
       return this.store.pipe(select(fromFeature.selectFeatureDelayedPayment));
    }

}