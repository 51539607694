import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminInsuranceProvider } from '../../shared/provider/insurance.provider';
import { MatSelectChange, MatTableDataSource } from '@angular/material';
import moment from 'moment';
import { AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import Debug from 'debug';
import { ExportType, ExcelOptions } from 'mat-table-exporter';
import { IInsurancePayment } from '../../shared/models/insurance.models';

const debug = Debug('modeso:ihub-admin:PaymentHistoryPage');
@Component({
    selector: 'app-payment-history',
    templateUrl: './payment-history.page.html',
    styleUrls: ['./payment-history.page.scss']
})
export class PaymentHistoryPage extends BasePageComponent implements OnInit {

    displayedColumns: string[] = [
        'twintPaymentReference',
        'policy',
        'ihubTranscationReference',
        'transactionDate',
        'transactionTime',
        'product',
        'family',
        'variation',
        'price',
    ];
    dataSource;

    timeFrameControl = new FormControl('', Validators.required);
    selectedDate: Date = new Date();
    selectedTimeFrame;
    timeFrames = ['Hour', 'Day', 'Week', 'Month', 'Year', 'All'];
    exportType = ExportType.XLSX;
    exportPaymentOptions: ExcelOptions = { fileName: 'Payments', sheet: 'Payments', columnWidths: undefined };
    @ViewChild('timePicker', { static: false }) timePicker: ElementRef;

    constructor(injector: Injector, private router: Router,
        private localStorageService: LocalStorageService, private adminInsurnaceProvider: AdminInsuranceProvider) {
        super(injector);

    }

    ngOnInit() {
        super.ngOnInit();
        this.adminInsurnaceProvider.getPayments$().subscribe((payments) => {
            debug(payments);
            this.dataSource = new MatTableDataSource<any>(payments);
        });
    }

    getPackage(family: boolean) {
        if (family) {
            return "Family";
        } else {
            return "Single";
        }
    }

    //TODO:
    /*
    - Request all insurances
    - 
    */
    getSelectedTimeFilter(event: MatSelectChange) {
        this.selectedTimeFrame = event.value;
    }
    getDateAsString() {
        if (!this.selectedDate) {
            return null;
        }
        return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    getSelectedHour() {
        return this.timePicker.nativeElement.value;
    }
    getDate() {
        const getSelectedDate = this.getDateAsString();

        if (!getSelectedDate) {
            return 'now';
        }
        const selectedTime = this.getSelectedHour();
        if (!selectedTime) {
            return getSelectedDate;
        }
        return getSelectedDate + '-' + selectedTime;
    }


    onSearch() {
        if (this.timeFrameControl.invalid) {
            return;
        }
        const reportRequest: AnalyticsReportRequest = {
            kpi: 'PAYMENT',
            timePeriod: this.selectedTimeFrame,
            date: this.getDate()
        };

        this.adminInsurnaceProvider.fetchPayments(reportRequest);
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);

    }

}
