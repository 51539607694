<ng-container *ngIf="productsForm">
    <form [formGroup]="productsForm">
      <table class="table">
        <thead>
          <th>Product</th>
          <th>Discounted Months</th>
        </thead>
        <tbody>
          <ng-container formArrayName="product" *ngFor="let group of product.controls; let i=index">
            <tr *ngIf="group.get('isEditMode').value" [formGroupName]="i">
                <td> {{group.get('title').value}}</td>
              <td>
                <mat-form-field>
                  <mat-label>Delayed Payment</mat-label>
                  <input matInput type="number" min="0" formControlName="delayPaymentByMonth">
                </mat-form-field>
              </td>
              <td>
               <button type="button" class="btn-done" mat-icon-button [disabled]="group.invalid" (click)="saveEditList(group)" >
                 <mat-icon>done</mat-icon> 
               </button>
               <button type="button" mat-icon-button style="margin-left: 8px;" (click)="cancel(i)" >
                <mat-icon>cancel</mat-icon>
               </button>
                
              </td>
            </tr>
            <tr *ngIf="!group.get('isEditMode').value" >
              <td>
                {{group.get('title').value}}
              </td>
              <td>
                {{group.get('delayPaymentByMonth').value }}
              </td>
              <td>
                <button mat-button (click)="editRow(group)"><mat-icon>edit</mat-icon></button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </form>
  </ng-container>