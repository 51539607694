import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminIssuersProvider } from '@modeso/twint-lib-admin-fe';
import { IIssuerAllProductsOrder, ISortingOrder } from '@modeso/types__ihub-lib-products-be';
import {Subscription} from 'rxjs';
import Debug from 'debug';
import { MatDialog } from '@angular/material';
import { ConfirmDeleteModalComponent } from '../confirm-delete-modal/confirm-delete-modal.component';
import { AdminSpotlightProvider } from '../../../shared/provider/spotlight.provider';
const debug = Debug('ihub:admin:ProductListComponenet');
@Component({
  selector: 'app-product-list-componenet',
  templateUrl: './product-list-componenet.component.html',
  styleUrls: ['./product-list-componenet.component.scss']
})
export class ProductListComponenet implements OnInit, OnDestroy {

  protected subscriptions: Subscription[] = [];
  public visibleProductList: any[];
  public hiddenProductList: any[];
  public featureProduct:any;
  issuerSortiingOrder: ISortingOrder;
  public issuer: IIssuerAllProductsOrder;
  protected issuerId: string;

  constructor(protected issuerProvider: AdminIssuersProvider, protected spotlightProvider: AdminSpotlightProvider,
              protected activatedRoute: ActivatedRoute, protected router: Router, protected dialog: MatDialog) {
    this.subscriptions.push((this.activatedRoute.params.subscribe((param) => {
      this.subscriptions.push(this.subscribeToList(param),this.subscribeToFeatureProduct());
    })));
  }

  protected subscribeToFeatureProduct(){
    return this.issuerProvider.getFeatureProduct$().subscribe((value)=>{
      this.featureProduct = value;
    })
  }

  protected subscribeToList(param): Subscription {
    this.issuerProvider.dispatchGetAllIssuers();
    return this.issuerProvider.getIssuerById$(param["issuer"]).subscribe((value: IIssuerAllProductsOrder) => {
      if (!value) {
        return;
      }
      this.issuer = value;
      this.issuerId = value.issuerId;
      this.visibleProductList = value.visibleProducts;
      this.hiddenProductList = value.hiddenProducts;
      this.featureProduct = value.featureProduct ? value.featureProduct[0]: null
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => subscribe.unsubscribe());
  }

  ngOnInit() {
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.visibleProductList, event.previousIndex, event.currentIndex);
  }

  hideProduct(product: any) {
    this.visibleProductList.splice(this.visibleProductList.indexOf(product), 1);
    this.hiddenProductList.push(product);
    if(product.productId === this.featureProduct){
      this.issuerProvider.removeFeatureProduct({issuerId:this.issuerId,type:"feature"});
    }
  }

  showProduct(product: any) {
    this.hiddenProductList.splice(this.hiddenProductList.indexOf(product), 1);
    this.visibleProductList.unshift(product);
  }

  editIssuer() {
    this.router.navigateByUrl(`/de-ch/product-managment/issuers/addIssuer/${this.issuer.issuerId}`);
  }

  mapVisibleProducts() {
    return this.visibleProductList.map((product) => product.productId);
  }

  getTypeToSave() {
    return 'product';
  }

  updateSortingOrder() {
    const newSortingOrder = this.mapVisibleProducts();
    const updatedSortedOrder: ISortingOrder = {
      issuerId: this.issuerId,
      products: newSortingOrder,
      type: this.getTypeToSave()
    };

    this.issuerProvider.dispatchCreateOrUpdateSortingOrder(updatedSortedOrder);
  }

  removeSortingOrder() {
    this.dialog.open(ConfirmDeleteModalComponent, {
      width: '250px',
      data: {issuerId: this.issuer.issuerId,type: this.getTypeToSave() },
    });
  }

  setFeatureProduct(product) {

    if(product !== this.featureProduct){
      const setFeatureProduct: ISortingOrder = {
        issuerId: this.issuerId,
        products: [product],
        type: 'feature'
      };
    
      this.issuerProvider.dispatchSetProductFeature(setFeatureProduct);
    }else{

      this.issuerProvider.removeFeatureProduct({issuerId:this.issuerId,type:"feature"});

    }
  
  }
}
