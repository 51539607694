<form [formGroup]="issuerForm" (ngSubmit)="onSubmit()">
      <div>
        <mat-form-field>
          <label>Issuer Name</label>
            <input matInput formControlName="issuerName"/>
            <mat-error *ngIf="issuerName.errors?.required">Issuer Name is Required</mat-error>
            <mat-error *ngIf="issuerName.errors?.alreadyExist">Issuer Name Already Exist</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Android Issuer Name</label>
            <input matInput formControlName="androidIssuer"/>
            <mat-error *ngIf="androidIssuer.invalid">Android Issuer Name is Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Ios Issuer Name</label>
            <input matInput formControlName="iosIssuer"/>
            <mat-error *ngIf="iosIssuer.invalid">Ios Issuer Name is Required</mat-error>
        </mat-form-field>
      </div>
      <button mat-button type="submit"  color="primary" [disabled]="issuerForm.invalid">Save</button>
</form>

<div *ngIf="alreadyExistErrorMessage">
   <mat-error>{{alreadyExistErrorMessage}}</mat-error>
</div>
