import { Action, createAction, props } from '@ngrx/store';


export const onNavigateToNewTab = createAction(
    '[Admin Navigations] onNavigateToNewTab',
     props<{ payload: string }>()
);
export interface ActionWithPayload<T> extends Action {
    payload: T;
}
