/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./samlAssert.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./samlAssert.page";
import * as i4 from "@modeso/twint-lib-admin-fe";
import * as i5 from "../../shared/services/localStorage.service";
import * as i6 from "@angular/router";
var styles_SamlAssertPage = [i0.styles];
var RenderType_SamlAssertPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SamlAssertPage, data: {} });
export { RenderType_SamlAssertPage as RenderType_SamlAssertPage };
function View_SamlAssertPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_SamlAssertPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SamlAssertPage_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isError; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SamlAssertPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-saml-assert", [], null, null, null, View_SamlAssertPage_0, RenderType_SamlAssertPage)), i1.ɵdid(1, 245760, null, 0, i3.SamlAssertPage, [i1.Injector, i4.AdminProvider, i5.LocalStorageService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SamlAssertPageNgFactory = i1.ɵccf("app-saml-assert", i3.SamlAssertPage, View_SamlAssertPage_Host_0, {}, {}, []);
export { SamlAssertPageNgFactory as SamlAssertPageNgFactory };
