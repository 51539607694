<div class="container" *ngIf="!hasReadPermission()">
    Sorry you don't have read permission.
  </div>
  <div class="container" *ngIf="hasReadPermission()">
    <div class="row">
      <div class="col-lg-3">
        <div>
          <h4>Select Time Filter</h4>
          <mat-form-field appearance="fill">
            <mat-label>Time Filter</mat-label>
            <mat-select
              [formControl]="timeFrameControl"
              (selectionChange)="getSelectedTimeFilter($event)"
              required
            >
              <mat-option
                *ngFor="let timeFrame of timeFrames"
                [value]="timeFrame"
              >
                {{timeFrame}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="timeFrameControl.hasError('required')"
              >Please choose a time period</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-3">
        <div>
          <h4>Select Date</h4>
          <mat-form-field appearance="fill">
            <mat-label>Select Date</mat-label>
            <input
              matInput
              [matDatepicker]="dp3"
              [(ngModel)]="selectedDate"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-3">
        <div>
          <h4>Select Time</h4>
          <mat-form-field appearance="fill" class="custom_mat_form_time">
            <div class="time-picker">
              <input
                matInput
                [ngxTimepicker]="toggleTimepicker"
                [disableClick]="true"
                readonly
                [format]="24"
                disabled
                #timePicker
                placeholder="Select time"
              />
              <ngx-material-timepicker-toggle
                [for]="toggleTimepicker"
              ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker
                #toggleTimepicker
              ></ngx-material-timepicker>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="search-container col-lg-1">
        <div class="search-button">
          <button id="search" (click)="onSearch()">Search</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="dataSource.data.length == 0">No Data to display</div>
      <div *ngIf="dataSource.data.length > 0">
        <div class="export-excel-button row">
          <button
            id="paymentExportExcelButton"
            (click)="paymentExporter.exportTable(exportType, exportPaymentOptions)"
          >
            Export Excel
          </button>
        </div>
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          matTableExporter
          #paymentExporter="matTableExporter"
        >
          <ng-container matColumnDef="twintPaymentReference">
            <th mat-header-cell *matHeaderCellDef>Order ID / Transaktions ID</th>
            <td mat-cell *matCellDef="let element">
              {{element.twintPaymentReference}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="policy">
            <th mat-header-cell *matHeaderCellDef>Policen-Nr.</th>
            <td mat-cell *matCellDef="let element">{{element.policy}}</td>
          </ng-container>
  
          <ng-container matColumnDef="ihubTranscationReference">
            <th mat-header-cell *matHeaderCellDef>UUID</th>
            <td mat-cell *matCellDef="let element">
              {{element.ihubTranscationReference}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="transactionDate">
            <th mat-header-cell *matHeaderCellDef>Transaktionsdatum</th>
            <td mat-cell *matCellDef="let element">
              {{element.transactionDate}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="transactionTime">
            <th mat-header-cell *matHeaderCellDef>Transaktionszeit</th>
            <td mat-cell *matCellDef="let element">
              {{element.transactionTime}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>Product</th>
            <td mat-cell *matCellDef="let element">{{element.product}}</td>
          </ng-container>
  
          <ng-container matColumnDef="family">
            <th mat-header-cell *matHeaderCellDef>Insurance Package</th>
            <td mat-cell *matCellDef="let element">
              {{ getPackage(element.family) }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="variation">
            <th mat-header-cell *matHeaderCellDef>Sum Insured</th>
            <td mat-cell *matCellDef="let element">{{element.variation}}</td>
          </ng-container>
  
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>Active Insurance Amount</th>
            <td mat-cell *matCellDef="let element">{{element.price}}</td>
          </ng-container>
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Reason</th>
            <td mat-cell *matCellDef="let element">{{element.response?.message}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
  