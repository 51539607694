import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserRoles } from '@modeso/twint-lib-admin-fe';
import { LocalStorageService } from './services/localStorage.service';
import { PermissionHelper } from './util/permission.helper';

@Injectable({
    providedIn: 'root'
})
export class IssuerPageGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) { }
    canActivate(): boolean {
        if (this.hasPermission()) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }

    hasPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
          UserRoles.DIGITAL_VAUCHERS_ADMIN,
          UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
      }
}
