import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,  Router } from '@angular/router';
import { ICreateOrUpdateSpotlight, ISpotlight, MultiLanguagesString } from '@modeso/types__ihub-lib-products-be';
import {take, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { NavigationIssuerProvider } from '../../../shared/provider/navigation.provider';
import Debug from 'debug';
import { AdminSpotlightProvider } from '../../../shared/provider/spotlight.provider';
import { MatDialog } from '@angular/material/dialog';
import { AddImageDialog } from '../../../shared/components/addImageDialog/addImageDialog';
import { IMultilanguageImage } from '../../../shared/models/upload.model';

const debug = Debug('ihub:admin:AddSpotlightComponent');
@Component({
  selector: 'app-add-spotlight',
  templateUrl: './add-spotlight.component.html',
  styleUrls: ['./add-spotlight.component.scss']
})
export class AddSpotlightComponent  implements OnInit, OnDestroy {
  spotlightForm: FormGroup;
  spotlight: ICreateOrUpdateSpotlight;
  subscriptions: Subscription[] = [];
  alreadyExistErrorMessage: string;
  constructor(private fb: FormBuilder, private spotlightProvider: AdminSpotlightProvider,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private navigationProvider: NavigationIssuerProvider,
              public dialog: MatDialog) {
    this.subscriptions.push(this.spotlightProvider.getError$().subscribe((err) => {
      if (!err) {
        this.alreadyExistErrorMessage = '';
        return;
      }
      if (err.error.duplicateKey) {
        const alreadyExistFieldValueError = Object.keys(err.error.duplicateKey)[0];
        const words = alreadyExistFieldValueError.split(/(?=[A-Z])/);
        const contorlName = `${words[0].charAt(0).toUpperCase()}${words[0].slice(1)} ${words.slice(1).join(' ')}`;
        this.alreadyExistErrorMessage = `${contorlName} already exists`;
      }
      debug(err);
    }));
    this.activatedRoute.params.pipe(take(1)).subscribe((param) => {
      if (!param.spotlightId) {
        debug('selectSpotlight' + param.spotlightId);
        this.spotlightProvider.selectSpotlight();
        this.initAddMode();
      } else {
        debug('selectSpotlight' + param.spotlightId);
        this.spotlightProvider.selectSpotlight(param.spotlightId);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {

    this.spotlightForm = this.fb.group({
      spotlightId: ['', []],
      spotlightName: ['', [Validators.required]],
      product: ['', [Validators.required]],
      image: this.fb.group({
        'de-ch': ['', [Validators.required]],
        'fr-ch': ['', [Validators.required]],
        'it-ch': ['', [Validators.required]],
        'en-us': ['', [Validators.required]],
      })
    });

    this.subscriptions.push(this.spotlightProvider.getActiveSpotlight$().subscribe((spotlight) => {
      if (!spotlight) {
        this.initAddMode();
      } else {
        this.spotlight = spotlight;
        this.updateForm(spotlight);
      }
    }));

  }

  initAddMode() {
    this.spotlight = {
      _id: '',
      spotlightName: 'new',
      product: '',
      image: { 'de-ch': '', 'fr-ch': '', 'it-ch': '', 'en-us': ''},
    } as ICreateOrUpdateSpotlight;
    this.updateForm(this.spotlight as ISpotlight);
  }

  // Checks if there is at least one image
  hasImages() {
    const x = Object.assign({}, this.spotlight.image);
    // tslint:disable-next-line:no-string-literal
    delete x['_id'];
    return Object.values(x).join('').length > 0;
  }

  updateForm(spotlight: ISpotlight) {

    if (spotlight &&  this.spotlightForm) {

      this.spotlightForm.setValue({
        spotlightId: spotlight._id,
        spotlightName: spotlight.spotlightName,
        product: spotlight.product,
        image: {
          'de-ch': spotlight.image['de-ch'],
          'fr-ch': spotlight.image['fr-ch'],
          'it-ch': spotlight.image['it-ch'],
          'en-us': spotlight.image['en-us'],
        }
      });
    }
    this.hasImages();
  }

  get spotlightName() {
    return this.spotlightForm.get('spotlightName');
  }
  get product() {
    return this.spotlightForm.get('product');
  }
  get image() {
    return this.spotlightForm.get('image');
  }

  onSubmit() {
    debug("onSubmit")
    let spotlight = {} as ICreateOrUpdateSpotlight;
    spotlight = this.spotlightForm.value;
    debug(spotlight.spotlightId);
    if (spotlight.spotlightId) {
      debug("update")
      spotlight.spotlightId = this.spotlight.spotlightId;
      this.spotlightProvider.dispatchUpdateSpotlight(spotlight);
      this.router.navigate([`../../`], {relativeTo: this.activatedRoute});
    } else {
      debug("new")
      this.spotlightProvider.dispatchCreateSpotlight(spotlight);
      this.router.navigate([`../`], {relativeTo: this.activatedRoute});
    }

   
  }

  cancel() {
    debug("cancel")
    this.router.navigate(['../..'], {relativeTo: this.activatedRoute});
  }

  addImage() {
    const dialogRef = this.dialog.open(AddImageDialog, {
        data: [],
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {

          debug('Images:', result);
          result.forEach((element) => {
            try {
              this.image.get(element.language).setValue(element.url);
              this.spotlight.image[element.language] = element.url;
            } catch (error) {
              debug('Images error:', error);
            }

          });
          debug('Images Value:', this.image.value);
        }
    });
  }

  editImage() {

    let visuals: IMultilanguageImage[];

    visuals = [
      {
        url: this.getFileUrl(this.image, 'de-ch'),
        language: 'de-ch',
        fileName: '',
      },
      {
        url: this.getFileUrl(this.image, 'fr-ch'),
        language: 'fr-ch',
        fileName: '',
      },
      {
        url: this.getFileUrl(this.image, 'it-ch'),
        language: 'it-ch',
        fileName: '',
      },
      {
        url: this.getFileUrl(this.image, 'en-us'),
        language: 'en-us',
        fileName: '',
      }
    ];


    const dialogRef = this.dialog.open(AddImageDialog, {
        data: visuals
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        debug('Images:', result);
        result.forEach((element) => {
          try {
            this.image.get(element.language).setValue(element.url);
            this.spotlight.image[element.language] = element.url;
          } catch (error) {
            debug('Images error:', error);
          }

        });
        debug('Images Value:', this.image.value);
      }
    });
  }

  getFileUrl(image: AbstractControl, lang: string) {

    try {
      return `${image.get(lang).value}`;
      
    } catch (error) {
      debug(error);
    }
    return "";
  }
}
