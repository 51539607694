import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UploadService {
    constructor(http) {
        this.http = http;
        this.server = '';
        this.microserviceName = 'files';
        this.server = environment.modeso.apiGateway;
    }
    getUploadUrl(filename) {
        return this.http.get(`${this.server}${this.microserviceName}/url-for/${filename}`);
    }
    uploadFile(url, file, contentType) {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': contentType,
        });
        return this.http.put(url, file, {
            headers,
        });
    }
}
UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.HttpClient)); }, token: UploadService, providedIn: "root" });
