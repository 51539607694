<div>

  <h2 style="text-align: center;" mat-dialog-title>Add Images</h2>
  <mat-dialog-content style="width: 848px;height:250px;">
    <div style="text-align: center;">
      <div *ngFor="let image of images; let i=index" class="upload-rectangle">
        <span>{{languages[i]}}</span>
        <ngx-file-drop style="width: 120px;" *ngIf="!uploadedImages[i]?.url" dropZoneLabel="Drop 8 here"
          (onFileDrop)="dropped($event,i)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

            <div>
              <button class="browse-button" type="button" (click)="openFileSelector()">
                <mat-icon style="color: grey;font-size: 50px;width: 50px; height: 50px;">add_circle</mat-icon>
              </button>
            </div>

          </ng-template>
        </ngx-file-drop>

        <div style="overflow: hidden;display:inline-block;width:200px;margin-bottom: 10px;">
          <img *ngIf="uploadedImages[i]?.url" class="upload-img" src="{{fileReader[i].url}}">
          <a *ngIf="uploadedImages[i]?.url" (click)="removeFile(i)" matSuffix>
            <mat-icon class="cancel-mat-icon">cancel</mat-icon>
          </a>

        </div>
        <div style="display: table-row;">
          <span *ngIf="errors[i]">Error While Uploading file Incompatible Format or File Size exceeds 15 MB</span>
        </div>

      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button (click)="save()" cdkFocusInitial [disabled]="!isValid()" class="save-button">Save</button>
    <button mat-button [mat-dialog-close]="false" class="cancel-button">Cancel</button>
  </mat-dialog-actions>
</div>

<div *ngIf="isUploadFinished" style=" display: flex; position: fixed; top: 0; left: 0; right: 0; bottom: 0;">
  <mat-spinner style="margin:auto;"></mat-spinner>
</div>