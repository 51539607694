import * as fromFeature from './../reducers/spotlight.reducer';
import { select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromActions from './../actions/spotlight.actions';
import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
const debug = Debug('modeso:ihub:AdminSpotlightProvider');
export class AdminSpotlightProvider {
    constructor(store) {
        this.store = store;
    }
    getSpotlightOrders$() {
        return this.store.pipe(select(fromFeature.featureSpotlightsOrder));
    }
    getSpotlights$() {
        return this.store.pipe(select(fromFeature.featureSpotlights));
    }
    getActiveSpotlight$() {
        return this.store.pipe(select(fromFeature.featureActiveSpotlights));
    }
    selectSpotlight(spotlightId) {
        this.store.dispatch(fromActions.onSelectSpotlight({ payload: spotlightId }));
    }
    dispatchGetAllSpotlights() {
        debug('Action Dispatched');
        this.store.dispatch(fromActions.onGetSpotlights());
    }
    dispatchDeleteSpotlight(spotlight) {
        debug('Action dispatchDeleteSpotlight Dispatched');
        this.store.dispatch(fromActions.onDeleteSpotlight({ payload: spotlight }));
    }
    dispatchCreateSpotlight(spotlight) {
        this.store.dispatch(fromActions.onAddSpotlight({ payload: spotlight }));
    }
    dispatchUpdateSpotlight(spotlight) {
        this.store.dispatch(fromActions.onUpdateSpotlight({ payload: spotlight }));
    }
    getError$() {
        return this.store.pipe(select(fromFeature.featureError), filter((error) => error != null));
    }
}
AdminSpotlightProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminSpotlightProvider_Factory() { return new AdminSpotlightProvider(i0.ɵɵinject(i1.Store)); }, token: AdminSpotlightProvider, providedIn: "root" });
