import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { AdminProvider, UserResponse, UserRolesResponse } from '@modeso/twint-lib-admin-fe';
import Debug from 'debug';
import { UserRoles } from '../../shared/enum/userrole.enum';
const debug = Debug('modeso:dgoods-admin:SidebarComponent');

interface INavigationItems {
  link: [string];
  text: string;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscriptions = new Array<Subscription>();
  public navigation = new Array<INavigationItems>();
  isUserLoggedIn: boolean;

  constructor(private localStorageService: LocalStorageService, private adminProvider: AdminProvider, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(this.adminProvider.getLoggedInUser$().subscribe(this.updateUserRole.bind(this)));
    this.subscriptions.push(this.adminProvider.getUserRoles$().subscribe(this.setUserRolesInStorage.bind(this)));
    this.subscriptions.push(LocalStorageService.UserRoleObserver$.subscribe(this.updateNavigation.bind(this)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  ngAfterViewChecked() {
    this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
    this.changeDetector.detectChanges();
  }

  public hasRole(roles: string[], role: string) {
    switch (role) {
      case 'ADMIN':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_ADMIN);
      case 'CONTENT_MANAGER':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER);
      case 'FRAUD_MANAGER':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER);
      case 'FRAUD_VIEWER':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER);
      case 'PRODUCT_MANAGER':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER);
      case 'REPORTING_MANAGER':
        return roles.some(role => role === UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER);
      case 'UNDEFINED':
        return roles.some(role => role === UserRoles.UNDEFINED);
    }
  }

  updateNavigation(roles: string[]) {
    debug('updateNavigation for role;');
    debug(roles);

    const navigationItems: INavigationItems[] = [
      { link: ['/de-ch/'], text: 'Home' },
      { link: ['/de-ch/changePassword'], text: 'Security' },
    ];

    if (roles) {

      // Admin
      if (this.hasRole(roles, 'ADMIN')) {
        navigationItems.push(
          { link: ['/de-ch/user-management/admin-users'], text: 'User Management' },
          { link: ['/de-ch/product-managment/issuers'], text: 'Product Managment' },
          { link: ['/de-ch/insurances-managment/insurances'], text: 'Insurances Managment' },
          { link: ['/de-ch/reports'], text: 'Reports' },
          { link: ['/de-ch/cms/text-and-labels'], text: 'Manage Content' },
          );
        navigationItems.push({ link: ['/de-ch/auditLogs'], text: 'Audit Logs' });
      }

      // Product Managment
      if (this.hasRole(roles, 'PRODUCT_MANAGER')
        || this.hasRole(roles, 'FRAUD_MANAGER')
        || this.hasRole(roles, 'FRAUD_VIEWER')
        || this.hasRole(roles, 'CONTENT_MANAGER')) {
        navigationItems.push({ link: ['/de-ch/product-managment/issuers'], text: 'Product Managment' });
      }

      // Reports
      if (this.hasRole(roles, 'REPORTING_MANAGER')
        || this.hasRole(roles, 'PRODUCT_MANAGER')) {
        navigationItems.push({ link: ['/de-ch/reports'], text: 'Reports' });
      }

      // Fraud Limits
      if (this.hasRole(roles, 'FRAUD_MANAGER')
        || this.hasRole(roles, 'PRODUCT_MANAGER')
        || this.hasRole(roles, 'FRAUD_VIEWER')) {
        navigationItems.push({ link: ['/de-ch/auditLogs'], text: 'Audit Logs' });
      }

      // Manage Content
      if (this.hasRole(roles, 'CONTENT_MANAGER')
        || this.hasRole(roles, 'PRODUCT_MANAGER')) {
        navigationItems.push({ link: ['/de-ch/cms/text-and-labels'], text: 'Manage Content' });
      }
    }
    this.navigation = navigationItems;
  }

  updateUserRole(user: UserResponse) {
    debug('updateUserRole:');
    debug(user);
    if (user) {
      this.localStorageService.setUserRole([user.role]);
    } else {
      debug('take from localstorage:');
      if (this.localStorageService.getUserRole() !== undefined) {
        this.updateNavigation(this.localStorageService.getUserRole());
      }
    }
  }

  setUserRolesInStorage(response: UserRolesResponse) {
    if (response && response.roles) {
      this.localStorageService.setUserRole(response.roles);
    }
  }

  getRole() {
    const roles = this.localStorageService.getUserRole();
    if (roles) {
      let roleStr = '';
      for (const role of roles) {
        switch (role) {
          case UserRoles.DIGITAL_VAUCHERS_ADMIN:
            roleStr = roleStr + 'Admin, ';
            break;
          case UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER:
            roleStr = roleStr + 'Content Manager, ';
            break;
          case UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER:
            roleStr = roleStr + 'Fraud Manager, ';
            break;
          case UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER:
            roleStr = roleStr + 'Fraud Viewer, ';
            break;
          case UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER:
            roleStr = roleStr + 'Product Manager, ';
            break;
          case UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER:
            roleStr = roleStr + 'Reporting Manager, ';
            break;
          default:
            roleStr = roleStr + 'UNDEFINED, ';
            break;
        }
      }
      return roleStr.substring(0, roleStr.length - 2);
    }
  }
}
