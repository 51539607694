import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminInsuranceProvider } from '../../shared/provider/insurance.provider';
import { InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { IInsuranceForAnalytics } from '../../shared/models/insurance.models';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-insurances',
    templateUrl: './insurances.page.html',
    styleUrls: ['./insurances.page.scss']
})
export class InsurancesPage extends BasePageComponent implements OnInit {

    displayedColumns: string[] = [
        'insuranceStatus',
        'userToken',
        'createdAt',
        'updatedAt',
        'policyNumber',
        'forename',
        'lastname',
        'email',
        'birthday',
        'endDate',
        'buttons'
    ];
    dataSource = [];

    profileForm = new FormGroup({
        forename: new FormControl(''),
        lastname: new FormControl(''),
    });

    policyForm = new FormGroup({
        policy: new FormControl(''),
    });

    InsuranceStatusEnum = InsuranceStatusEnum;
    fileName: string;
    file: any;
    importResponse;
    error = false;
    disableImportSubmitBtn = true;


    constructor(injector: Injector, private router: Router,
        private localStorageService: LocalStorageService, private adminInsurnaceProvider: AdminInsuranceProvider) {
        super(injector);

    }

    ngOnInit() {
        super.ngOnInit();
        this.adminInsurnaceProvider.getInsurance$().subscribe((insurances) => {
            this.dataSource = insurances;
            this.dataSource = this.dataSource.map((insurance) => {
                insurance.endDate =  insurance.endDate ? insurance.endDate : new Date();
                return insurance;
            });
        });
    }

    onSubmitByName() {
        this.adminInsurnaceProvider.fetchInsurancesByName(this.profileForm.value);
    }

    onSubmitByPolicy() {
        this.adminInsurnaceProvider.fetchInsurancesByPolicy(this.policyForm.value);
    }

    updateInsuranceStatus(insurance: IInsuranceForAnalytics, status: InsuranceStatusEnum) {
      this.adminInsurnaceProvider.updateInsuranceStatus(insurance._id, insurance.endDate, status);
    }

    cancelInsurance(insurance: IInsuranceForAnalytics) {
        insurance.insuranceStatus = InsuranceStatusEnum.CANCELLED;
        this.adminInsurnaceProvider.dispatchCancelInsurance(insurance._id, insurance.endDate, insurance.insuranceStatus );
    }

    dropExcelFile(droppedFile: NgxFileDropEntry[]): void {
        this.error = false;
        this.fileName = '';
        const fileEntry = droppedFile[0].fileEntry as FileSystemFileEntry;
        fileEntry.file( async (file: File) => {
          if (!this.isFileAllowed(file.name)) {
            this.error = true;
          } else {
            this.fileName = file.name;
            this.disableImportSubmitBtn = false;
            this.file = file;
          }
        });
    }

    importInsurances() {
        this.disableImportSubmitBtn = true;
        this.importResponse = undefined;
        const formData = new FormData();
        formData.append('file', this.file);
        this.adminInsurnaceProvider.importInsurances(formData).pipe(take(2)).subscribe((response) => {
            if (response) {
                this.importResponse = response;
            }
        });
    }

    private isFileAllowed(fileName: string) {
        let isFileAllowed = true;
        const allowedFiles = ['.xlsx', '.xls'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
          if (!allowedFiles.includes(extension[0])) {
            return isFileAllowed = false;
          }
        }
        return isFileAllowed;
    }
    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);

    }
}
