<form [formGroup]="spotlightForm" >
      <div>
        <mat-form-field>
          <label>Spotlight Name</label>
          <input matInput formControlName="spotlightName"/>
          <mat-error *ngIf="spotlightName.errors?.required">Spotlight Name is required</mat-error>
          <mat-error *ngIf="spotlightName.errors?.alreadyExist">Spotlight Name already exist</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Product</label>
            <input matInput formControlName="product"/>
            <mat-error *ngIf="product.invalid">Product is Required</mat-error>
        </mat-form-field>
      </div>
    
      <div style="padding: 20px">
        <h5>
          Image
          <span class="input_required">*</span>
        </h5>

        <div *ngIf="hasImages()">
          <img [src]="getFileUrl(image, 'de-ch')" /> 
          <img [src]="getFileUrl(image, 'fr-ch')" /> 
          <img [src]="getFileUrl(image, 'it-ch')" /> 
          <img [src]="getFileUrl(image, 'en-us')" /> 
        </div>

        <div class="add-image-div-btn" >
          <button
            *ngIf="!hasImages()"
            mat-raised-button
            type="button"
            (click)="addImage()"
            class="add-image-btn"
          >
          Add Images
          </button>
          <button
            *ngIf="hasImages()"
            mat-raised-button
            type="button"
            (click)="editImage()"
            class="add-image-btn"
          >
          Edit Image
          </button>
        </div>

        <!-- <div class="add-image-div-img">
          <!-- <div [ngClass]="{'upload-rectangle': !imageUrl}"></div> -->
          <!-- <div class="row"> -->
            <!-- <div *ngIf="imageUrl" class="col">
              <img [src]="imageUrl" (click)="editImage()" class="upload-image" />
            </div> -->
          <!-- </div>
        </div> --> 
      </div>
      <button mat-button (click)="cancel()"  color="secondary">Cancel</button>
      <button mat-button (click)="onSubmit()"  color="primary" [disabled]="spotlightForm.invalid">Save</button>
</form>

<div *ngIf="alreadyExistErrorMessage">
   <mat-error>{{alreadyExistErrorMessage}}</mat-error>
</div>
