import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSelectChange, MatTableDataSource } from '@angular/material';
import { AdminAuditLogsProvider, AuditLogsModel } from '@modeso/twint-lib-admin-fe';
import { AuditLogsResponse } from '@modeso/twint-lib-admin-fe';
import { BasePageComponent } from '../base.page';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { FormControl, Validators } from '@angular/forms';
import { AuditLogActions } from '../../shared/enum/auditLogsActions.enum';
import moment from 'moment';
import { AuditLogsRequest } from '@modeso/twint-lib-admin-fe/lib/models/auditLogs/auditLogsRequest.model';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './auditLogs.page.html',
  styleUrls: ['./auditLogs.page.scss']
})
export class AuditLogsPage extends BasePageComponent implements OnInit {

  auditLogs: AuditLogsModel[];
  auditLogsdataSource: MatTableDataSource<AuditLogsModel>;
  auditLogsColmuns: string[] = ['UserId', 'Action', 'Timestamp', 'Payload'];

  isError = false;
  errorMessage = 'Error occured';

  actions = [
    AuditLogActions.ALL,
    AuditLogActions.LOGIN,
    AuditLogActions.LOGOUT,
    AuditLogActions.FAILED_LOGIN,
    AuditLogActions.UNBLOCK_PRODUCT,
    AuditLogActions.UNBLOCK_SHOP,
    AuditLogActions.CHANGE_LIMIT,
    AuditLogActions.CREATE_ISSUER_LIMIT,
    AuditLogActions.UPDATE_ISSUER_LIMIT,
    AuditLogActions.DELETE_ISSUER_LIMIT
  ];

  timeFrames = ['Hour', 'Day', 'Week', 'Month', 'Year', 'All'];

  selectedAction: string;
  selectedDate: '';
  selectedTimeFrame: string;
  actionControl = new FormControl('', Validators.required);
  timeFrameControl = new FormControl('', Validators.required);

  @ViewChild('timepicker', { static: false }) timePicker: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  constructor(injector: Injector, private adminAuditLogsProvider: AdminAuditLogsProvider,
    private changeDetector: ChangeDetectorRef, private localStorageService: LocalStorageService) {
    super(injector);
  }

  ngOnInit() {
    this.initErrors();
  }

  getSelectedAction(event: MatSelectChange) {
    this.selectedAction = event.value;
  }

  getSelectedTimeFilter(event: MatSelectChange) {
    this.selectedTimeFrame = event.value;
  }

  getDateAsString() {
    if (!this.selectedDate) {
      return null;
    }
    return moment(this.selectedDate).format('YYYY-MM-DD');
  }

  getSelectedHour() {
    return this.timePicker.nativeElement.value;
  }

  getDate() {
    const getSelectedDate = this.getDateAsString();
    if (!getSelectedDate) {
      return 'now';
    }
    const selectedTime = this.getSelectedHour();
    if (!selectedTime) {
      return getSelectedDate;
    }
    return getSelectedDate + '-' + selectedTime;
  }

  buttonStatus() {
    return this.actionControl.invalid || this.timeFrameControl.invalid;
  }

  onSearch() {
    if (this.actionControl.invalid || this.timeFrameControl.invalid) {
      return;
    }
    const auditLogsRequest: AuditLogsRequest = {
      action: this.selectedAction,
      timePeriod: this.selectedTimeFrame,
      date: this.getDate()
    };
    this.getAuditLogs(auditLogsRequest);
  }

  getAuditLogs(auditLogsRequest: AuditLogsRequest) {
    this.subscriptions.push(
      this.adminAuditLogsProvider.getAuditLogs$(auditLogsRequest).subscribe((auditLogsResponse: AuditLogsResponse) => {
        this.isError = false;
        this.auditLogs = auditLogsResponse.auditLogs.map(this.mapAuditLogPayload);
        this.auditLogsdataSource = new MatTableDataSource<AuditLogsModel>(this.auditLogs);
        this.auditLogsdataSource.paginator = this.paginator;
        this.changeDetector.detectChanges();
      })
    );
  }

  mapAuditLogPayload = (auditLog) => {
    return { ...auditLog, payload: this.convertJsonToString(auditLog.payload) }
  }

  convertJsonToString(json: any): string {
    let str = "";
    for (var property in json)
      str += property + ":  " + json[property] + ", "
    return str.substring(0, str.length - 2);
  }

  initErrors() {
    this.adminAuditLogsProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {
      this.isError = true;
    });
  }

  hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.DIGITAL_VAUCHERS_ADMIN,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  hasWritePermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.DIGITAL_VAUCHERS_ADMIN,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }
}
