import { Component, Injector, ViewChild, ElementRef, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import moment from 'moment';
import { take } from 'rxjs/operators';
import {
    AnalyticsReportRequest, AdminReportProvider, GoodsBrandDetails, PageDetails,
    FraudDetetionLimitsDetails, TodayPurchasingAmountOfUsersDetails, ProductsLimitDetails,
    TodayPurchasingAmountOfproductLines, UsersDetails, Sessions, InsuranceBrandDetails
} from '@modeso/twint-lib-admin-fe';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { ExportType, ExcelOptions } from 'mat-table-exporter';

import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';


@Component({
    selector: 'app-page-reports',
    templateUrl: './reports.page.html',
    styleUrls: ['./reports.page.scss'],
})
export class ReportsPage extends BasePageComponent implements OnInit {
    kpis = ['Insurances', 'Users', 'Sessions', 'Views', 'Sales', 'Terminals'];
    timeFrames = ['Hour', 'Day', 'Week', 'Month', 'Year', 'All'];
    selectedKpi: string;
    selectedDate: '';
    selectedTimeFrame: string;
    selectedItem: string;
    kpiControl = new FormControl('', Validators.required);
    timeFrameControl = new FormControl('', Validators.required);
    tableHeaders: string[];
    isInsurance = false;
    isSales = false;
    isSessions = false;
    isUsers = false;
    isViews = false;
    isTerminal = false;
    isServerError = false;
    isBadRequest = false;
    serverError = 'Server Error';
    sessionSummary = 'Summary';
    badRequestErrorMessage: string;
    exportType = ExportType.XLSX;
    exportViewsOptions: ExcelOptions = { fileName: 'Views', sheet: 'Views', columnWidths: undefined };
    exportSessionsOptions: ExcelOptions = { fileName: 'Sessions', sheet: 'Sessions', columnWidths: undefined };
    exportUsersOptions: ExcelOptions = { fileName: 'Users', sheet: 'Users', columnWidths: undefined };
    exportSalesOptions: ExcelOptions = { fileName: 'Sales', sheet: 'Sales', columnWidths: undefined };
    exportGoodsOptions: ExcelOptions = { fileName: 'Insurances', sheet: 'Insurances', columnWidths: undefined };
    exportTerminalsOptions: ExcelOptions = { fileName: 'Terminals', sheet: 'Terminals', columnWidths: undefined };

    insurances: InsuranceBrandDetails[] = [];
    sales: any[] = [];
    users: UsersDetails[] = [];
    views: PageDetails[] = [];
    sessions: Sessions[] = [];
    terminals: any[] = [];
    blockedProductsAnalytics: string[] = [];
    // tables' columns
    goodsAndSessionsDisplayedColumns: string[] = ['productId', 'Terminal Id', 'Price', 'Active',
        'Canceled', 'WAITFORUOF', 'Draft', 'Suspended', 'Updated_At'];
    salesDisplayColums: string[] = ['forename', 'lastname', 'Reference', 'birthday', 'street','streetNumber', 'zipcode', 'city', 'phone', 'email', 'receiveEmails', 'Terminal', 'amount', 'User token', 'product', 'createdAt', 'updatedAt', 'endDate', 'policyNumber',
        'Insurance Status', 'Payment Status', 'Type', 'Variation', 'Language', 'CancelationReason', 'AdditionalAttributes', 'DisplayAttributes'];
    usersDisplayedColumns: string[] = ['ID', 'Terminal', 'User token', 'User Issuer', 'Created at', 'Updated at', 'Email', 'Twint T&C'];
    viewsDisplayedColumns: string[] = ['ID', 'Terminal Id', 'Total visits', 'Unique users', 'Average per person'];
    sessionsDisplayColumns: string[] = ['Terminal', 'Count of Sessions'];

    terminalColumns: string[] = ["Terminal", "Checkins", "Total Checkouts", "SuccessFul CheckOuts", "Failed CheckOuts", "Total Amount of CheckOuts", "Conversion Rate", "Drop Off Rate"]

    @ViewChild('timePicker', { static: false }) timePicker: ElementRef;
    @ViewChild('goodsPaginator', { static: false }) goodsPaginator: MatPaginator;
    @ViewChild('terminalPaginator', { static: false }) terminalPaginator: MatPaginator;
    @ViewChild('salesPaginator', { static: false }) salesPaginator: MatPaginator;
    @ViewChild('usersPaginator', { static: false }) usersPaginator: MatPaginator;
    @ViewChild('viewsPaginator', { static: false }) viewsPaginator: MatPaginator;

    @ViewChild('blockedUsersPaginator', { static: false }) blockedUsersPaginator: MatPaginator;
    @ViewChild('blockedProductsPaginator', { static: false }) blockedProductsPaginator: MatPaginator;
    @ViewChild('blockedShopPaginator', { static: false }) blockedShopPaginator: MatPaginator;
    @ViewChild('fraudPaginator', { static: false }) fraudPaginator: MatPaginator;
    @ViewChild('todayPurchasingProductPaginator', { static: false }) todayPurchasingProductPaginator: MatPaginator;
    @ViewChild('todayPurchasingUsersPaginator', { static: false }) todayPurchasingUsersPaginator: MatPaginator;
    @ViewChild('sessionsPaginator', { static: false }) sessionsPaginator: MatPaginator;
    @ViewChild('productLimitPaginator', { static: false }) productLimitPaginator: MatPaginator;



    // tables' data sources intialization
    insuranceDataSource = new MatTableDataSource<InsuranceBrandDetails>(this.insurances);
    salesDataSource = new MatTableDataSource<any>(this.sales);
    usersDataSource = new MatTableDataSource<UsersDetails>(this.users);
    viewsDataSource = new MatTableDataSource<PageDetails>(this.views);
    sessionsDataSource: MatTableDataSource<Sessions>;

    terminalsDataSource = new MatTableDataSource<any>(this.terminals);
    blockedProductsAnalyticsDataSource = new MatTableDataSource<string>(this.blockedProductsAnalytics);
    // tslint:disable-next-line: max-line-length

    constructor(injector: Injector, private adminReportProvider: AdminReportProvider, private changeDetector: ChangeDetectorRef,
        private localStorageService: LocalStorageService) {
        super(injector);
    }
    getSelectedKpi(event: MatSelectChange) {
        this.selectedKpi = event.value;
    }

    getSelectedTimeFilter(event: MatSelectChange) {
        this.selectedTimeFrame = event.value;
    }

    getDateAsString() {
        if (!this.selectedDate) {
            return null;
        }
        return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    getSelectedHour() {
        return this.timePicker.nativeElement.value;
    }
    getDate() {
        const getSelectedDate = this.getDateAsString();

        if (!getSelectedDate) {
            return 'now';
        }
        const selectedTime = this.getSelectedHour();
        if (!selectedTime) {
            return getSelectedDate;
        }
        return getSelectedDate + '-' + selectedTime;
    }

    buttonStatus() {
        return this.kpiControl.invalid || this.timeFrameControl.invalid;
    }

    onSearch() {
        if (this.kpiControl.invalid || this.timeFrameControl.invalid) {
            return;
        }

        const reportRequest: AnalyticsReportRequest = {
            kpi: this.selectedKpi,
            timePeriod: this.selectedTimeFrame,
            date: this.getDate()
        };

        this.subscriptions.push(
            this.adminReportProvider.getReport$(reportRequest).pipe(take(2)).subscribe((adminReportResponse: any) => {
                if (reportRequest.kpi === 'Insurances') {
                    this.insurances = [];

                    if (adminReportResponse && adminReportResponse.brands) {
                        this.insurances = adminReportResponse.brands;
                        const summary = adminReportResponse.summary;
                        summary._id = 'Summary';
                        this.insurances.push(summary);

                        this.isSessions = false;
                        this.isUsers = false;
                        this.isViews = false;
                        this.isServerError = false;
                        this.isBadRequest = false;
                        this.isInsurance = true;
                        this.isSales = false;
                        this.isTerminal = false;
                        this.insuranceDataSource = new MatTableDataSource<InsuranceBrandDetails>(this.insurances);
                        this.changeDetector.detectChanges();
                        this.insuranceDataSource.paginator = this.goodsPaginator;
                    }
                }
                if (reportRequest.kpi === 'Terminals') {
                    if (!adminReportResponse) {
                        return;
                    }
                    if (Object.keys(adminReportResponse).length !== 0) {
                        this.terminals = adminReportResponse;
                        this.isSessions = false;
                        this.isUsers = false;
                        this.isViews = false;
                        this.isServerError = false;
                        this.isBadRequest = false;
                        this.isInsurance = false;
                        this.isTerminal = true;
                        this.isSales = false;
                        this.terminalsDataSource = new MatTableDataSource<any>(this.terminals);
                        this.changeDetector.detectChanges();
                        this.terminalsDataSource.paginator = this.terminalPaginator;
                    }
                }


                if (reportRequest.kpi === 'Sessions') {

                    if (!adminReportResponse) {
                        return;
                    }
                    this.sessions = adminReportResponse.data;

                    this.isUsers = false
                    this.isViews = false;
                    this.isServerError = false;
                    this.isBadRequest = false;
                    this.isInsurance = false;
                    this.isSales = false;
                    this.isSessions = true;
                    this.isTerminal = false;
                    this.changeDetector.detectChanges();
                    this.sessionsDataSource = new MatTableDataSource<Sessions>(this.sessions);
                    this.sessionsDataSource.paginator = this.sessionsPaginator;

                }

                if (reportRequest.kpi === 'Users') {

                    this.users = [];
                    if (adminReportResponse) {

                        if (!adminReportResponse.result) {
                            this.users = [];
                        } else {

                            this.users = adminReportResponse.result;
                            const userSummary: UsersDetails = new UsersDetails();
                            userSummary._id = 'Summary';
                            userSummary.userToken = adminReportResponse.summary.newUsers;
                            userSummary.userIssuer = '';
                            userSummary.createdAt = '';
                            userSummary.updatedAt = '';
                            userSummary.email = adminReportResponse.summary.emailUsers;
                            userSummary.twintTC = adminReportResponse.summary.twintTcUsers;
                            this.users.push(userSummary);
                        }
                        this.isSessions = false;
                        this.isInsurance = false;
                        this.isSales = false;
                        this.isViews = false;
                        this.isServerError = false;
                        this.isBadRequest = false;
                        this.isUsers = true;
                        this.isTerminal = false;
                        this.usersDataSource = new MatTableDataSource<UsersDetails>(this.users);
                        this.changeDetector.detectChanges();
                        this.usersDataSource.paginator = this.usersPaginator;


                    }
                }

                if (reportRequest.kpi === 'Views') {
                    if (adminReportResponse && adminReportResponse.pages) {
                        this.views = [];

                        this.views = adminReportResponse.pages;
                        const summary: PageDetails = new PageDetails();
                        summary._id = 'Summary';
                        summary.totalVisits = adminReportResponse.summary.totalVisits;
                        summary.uniqueUsers = adminReportResponse.summary.nrOfUniqueUsers;
                        summary.avgPerUser = adminReportResponse.summary.avgPerUser;
                        this.views.push(summary);
                        this.isSessions = false;
                        this.isInsurance = false;
                        this.isSales = false;
                        this.isUsers = false;
                        this.isViews = true;
                        this.isServerError = false;
                        this.isBadRequest = false;
                        this.isTerminal = false;
                        this.viewsDataSource = new MatTableDataSource<PageDetails>(this.views);
                        this.changeDetector.detectChanges();
                        this.viewsDataSource.paginator = this.viewsPaginator;
                    }
                }

                if (reportRequest.kpi === 'Sales') {
                    this.sales = [];

                    if (adminReportResponse.length) {
                        this.sales = adminReportResponse;
                        this.isSessions = false;
                        this.isUsers = false;
                        this.isViews = false;
                        this.isServerError = false;
                        this.isTerminal = false;
                        this.isBadRequest = false;
                        this.isInsurance = false;
                        this.isSales = true;
                        this.salesDataSource = new MatTableDataSource<any>(this.sales);
                        this.changeDetector.detectChanges();
                        this.salesDataSource.paginator = this.salesPaginator;
                    }
                }

            })

        );

        this.subscriptions.push(
            this.adminReportProvider.getError$().subscribe((errorResponse: any) => {
                if (errorResponse) {
                    if (errorResponse.status.toString() === '400') {

                        this.isServerError = false;
                        this.isBadRequest = true;
                        this.badRequestErrorMessage = errorResponse.error.error;
                    } else {
                        this.isServerError = true;
                        this.isBadRequest = false;
                    }

                }

            })
        );
    }

    createExcelWorkSheet(data: any[], headers: string[]) {
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(workSheet, [headers]);
        XLSX.utils.sheet_add_json(workSheet, data, { origin: 'A2', skipHeader: true });
        return workSheet;
    }

    // onExportBlockedToExcel() {
    //     const workBook: XLSX.WorkBook = XLSX.utils.book_new();

    //     // Blocked Products Analytics
    //     const blockedProductsAnalyticsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
    //         this.blockedProductsAnalytics.map((item) => ({ value: item })), this.blockedProductsAnalyticsColumns);
    //     XLSX.utils.book_append_sheet(workBook, blockedProductsAnalyticsWorkSheet, 'BlockedProductsAnalytics');

    //     // Blocked Shop Analytics
    //     const blockedShopAnalyticsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
    //         this.blockedShopAnalytics.map((item) => ({ value: item })), this.blockedShopAnalyticsColumns);
    //     XLSX.utils.book_append_sheet(workBook, blockedShopAnalyticsWorkSheet, 'BlockedShopAnalytics');

    //     // Fraud Limits
    //     const fraudLimitsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(this.fraudDetectionLimits, this.fraudDetectionLimitsColumns);
    //     XLSX.utils.book_append_sheet(workBook, fraudLimitsWorkSheet, 'FraudLimits');

    //     // Today Purchasing Amount of Product Lines
    //     const todayPurchasingAmountOfProductLinesWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
    //         this.todayPurchasingAmountOfproductLines.map((item) => ({ ...item, productLineEntityId: item.productLineEntityId[0] })), this.todayPurchasingAmountOfproductLinesColumns);
    //     XLSX.utils.book_append_sheet(workBook, todayPurchasingAmountOfProductLinesWorkSheet, 'TodayPurchasingOfProdcutLines');

    //     // Today Purchasing Amount of Users
    //     const todayPurchasingAmountOfUsersWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(this.todayPurchasingAmountOfUsers, this.todayPurchasingAmountOfUsersColumns);
    //     XLSX.utils.book_append_sheet(workBook, todayPurchasingAmountOfUsersWorkSheet, 'TodayPurchasingOfUsers');

    //     // Products Limit
    //     const productsLimitWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(this.productsLimit, this.productsLimitcolumns);
    //     XLSX.utils.book_append_sheet(workBook, productsLimitWorkSheet, 'ProductsLimit');

    //     XLSX.writeFile(workBook, 'Blocked.xlsx');

    // }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);

    }
}
