/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./userManagement.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "./userManagement.page";
var styles_UserManagementPage = [i0.styles];
var RenderType_UserManagementPage = i1.ɵcrt({ encapsulation: 0, styles: styles_UserManagementPage, data: {} });
export { RenderType_UserManagementPage as RenderType_UserManagementPage };
function View_UserManagementPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.activeLink = _v.context.$implicit.link) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 147456, [[1, 4]], 0, i4.MatTabLink, [i4.MatTabNav, i1.ElementRef, i1.NgZone, i5.Platform, [2, i6.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_7 = _ck(_v, 2, 0, _v.context.$implicit.link); _ck(_v, 1, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 3).active ? "page" : null); var currVal_3 = i1.ɵnov(_v, 3).disabled; var currVal_4 = i1.ɵnov(_v, 3).tabIndex; var currVal_5 = i1.ɵnov(_v, 3).disabled; var currVal_6 = i1.ɵnov(_v, 3).active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_8); }); }
export function View_UserManagementPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "nav", [["class", "mat-tab-nav-bar mat-tab-header"], ["mat-tab-nav-bar", ""]], [[2, "mat-tab-header-pagination-controls-enabled", null], [2, "mat-tab-header-rtl", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null]], null, null, i9.View_MatTabNav_0, i9.RenderType_MatTabNav)), i1.ɵdid(2, 7520256, null, 1, i4.MatTabNav, [i1.ElementRef, [2, i10.Directionality], i1.NgZone, i1.ChangeDetectorRef, i11.ViewportRuler, [2, i5.Platform], [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _items: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UserManagementPage_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.navLinks; _ck(_v, 5, 0, currVal_5); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._showPaginationControls; var currVal_1 = (i1.ɵnov(_v, 2)._getLayoutDirection() == "rtl"); var currVal_2 = ((i1.ɵnov(_v, 2).color !== "warn") && (i1.ɵnov(_v, 2).color !== "accent")); var currVal_3 = (i1.ɵnov(_v, 2).color === "accent"); var currVal_4 = (i1.ɵnov(_v, 2).color === "warn"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_UserManagementPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-userManagement", [], null, null, null, View_UserManagementPage_0, RenderType_UserManagementPage)), i1.ɵdid(1, 245760, null, 0, i12.UserManagementPage, [i1.Injector, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserManagementPageNgFactory = i1.ɵccf("app-page-userManagement", i12.UserManagementPage, View_UserManagementPage_Host_0, {}, {}, []);
export { UserManagementPageNgFactory as UserManagementPageNgFactory };
