export enum InsuranceStatusEnum {
    DRAFT = 0,
    ACTIVE = 1,
    CANCELLED = 2,
    SUSPENDED = 3,
    WAITFORUOF = 4,
    PENDING= 5,
    EXPIRED = 6,
    WAITING_FOR_ACTIVATION = 7,
    INACTIVE = 8,
}