import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminInsuranceProvider } from '../../shared/provider/insurance.provider';
import { MatSelectChange, MatTableDataSource } from '@angular/material';
import moment from 'moment';
import { AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import Debug from 'debug';
import { ExportType, ExcelOptions } from 'mat-table-exporter';

const debug = Debug('modeso:ihub-admin:FailedPaymentHistoryPage');
@Component({
    selector: 'app-failed-payment-history',
    templateUrl: './failed-payment-history.page.html',
    styleUrls: ['./failed-payment-history.page.scss']
})
export class FailedPaymentHistoryPage extends BasePageComponent implements OnInit {

    displayedColumns: string[] = [
        'twintPaymentReference',
        'policy',
        'ihubTranscationReference',
        'transactionDate',
        'transactionTime',
        'product',
        'family',
        'variation',
        'price',
        'message'
    ];
    dataSource;

    timeFrameControl = new FormControl('', Validators.required);
    selectedDate: Date = new Date();
    selectedTimeFrame;
    timeFrames = ['Hour', 'Day', 'Week', 'Month', 'Year', 'All'];
    exportType = ExportType.XLSX;
    exportPaymentOptions: ExcelOptions = { fileName: 'Failed Payments', sheet: 'Failed Payments', columnWidths: undefined };
    @ViewChild('timePicker', { static: false }) timePicker: ElementRef;

    constructor(injector: Injector, private localStorageService: LocalStorageService,
                private adminInsurnaceProvider: AdminInsuranceProvider) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.adminInsurnaceProvider.getFailedPayments$().subscribe((failedPayments) => {
            debug(failedPayments);
            this.dataSource = new MatTableDataSource<any>(failedPayments);
        });
    }

    getPackage(family: boolean) {
        if (family) {
            return 'Family';
        } else {
            return 'Single';
        }
    }
    getSelectedTimeFilter(event: MatSelectChange) {
        this.selectedTimeFrame = event.value;
    }
    getDateAsString() {
        if (!this.selectedDate) {
            return null;
        }
        return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    getSelectedHour() {
        return this.timePicker.nativeElement.value;
    }
    getDate() {
        const getSelectedDate = this.getDateAsString();

        if (!getSelectedDate) {
            return 'now';
        }
        const selectedTime = this.getSelectedHour();
        if (!selectedTime) {
            return getSelectedDate;
        }
        return getSelectedDate + '-' + selectedTime;
    }


    onSearch() {
        if (this.timeFrameControl.invalid) {
            return;
        }
        const reportRequest: AnalyticsReportRequest = {
            kpi: 'PAYMENT',
            timePeriod: this.selectedTimeFrame,
            date: this.getDate()
        };

        this.adminInsurnaceProvider.dispatchGetFailedPayments(reportRequest);
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);

    }

}
