import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/insurance.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdminInsuranceService } from '../services/insurance.service';
import { IInsuranceCancelModel, IInsuranceForAnalytics, IInsurancePayment, IInsuranceStatusUpdateModel } from '../models/insurance.models';
import Debug from 'debug';
import { MatSnackBar } from '@angular/material';

const debug = Debug('modeso:twint-lib-admin-fe:AdminInsuranceEffects');


@Injectable()
export class AdminInsuranceEffects {
    constructor(private actions$: Actions, private service: AdminInsuranceService, private _snackBar: MatSnackBar ) { }

    getInsurances$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getInsurances.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<any>) => {
                    return this.service.getInsurances(action.payload)
                        .pipe(
                            map((response: IInsuranceForAnalytics[]) => fromActions.onGetInsurancesSuccessfully({ payload: response })),
                            catchError((error) => {
                                return of(fromActions.onGetInsurancesFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    getPayments$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getPayments.type),
            mergeMap(
                (payload) => {
                    return this.service.getPayments(payload)
                        .pipe(
                            map(
                                (response: IInsurancePayment[]) => {
                                    return fromActions.onGetPaymentsSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetPaymentsFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    updateInsuranceStatus$ = createEffect(
      () => this.actions$.pipe(
          ofType(fromActions.updateInsuranceStatus.type),
          mergeMap(
              (action: fromActions.ActionWithPayload<IInsuranceStatusUpdateModel>) => {
                  return this.service.updateInsuranceStatus(action.payload.id, action.payload.insuranceStatus, action.payload.endDate)
                      .pipe(
                          map((response: IInsuranceStatusUpdateModel) =>
                            fromActions.onUpdateInsuranceStatusSuccessfully({ payload: response })),
                          catchError((error) => {
                              return of(fromActions.onUpdateInsuranceStatusFailed({ payload: error }));
                          })
                      );
              }
          )
      )
    );
    updateInsuranceEnddate$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.cancelInusrance.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<IInsuranceCancelModel>) => {
                    return this.service.cancelInsurance(action.payload.id, action.payload.endDate , action.payload.insuranceStatus)
                        .pipe(
                            map((response: IInsuranceCancelModel) =>
                              fromActions.onCancelInusranceSuccessfully({ payload: response })),
                            catchError((error) => {
                                return of(fromActions.onCancelInusranceFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );
    
    getFailedPayments$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getFailedPayments.type),
            mergeMap(
                (payload) => {
                    return this.service.getFailedPayments(payload)
                        .pipe(
                            map(
                                (response: IInsurancePayment[]) => {
                                    return fromActions.onGetFailedPaymentsSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetFailedPaymentsFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );
    errorOnGetInsurances$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetInsurancesFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
            )
        ), { dispatch: false }
    );
    errorOnGetPayments$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetPaymentsFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
            )
        ), { dispatch: false }
    );
    errorOnUpdateInsurance$ = createEffect(
      () => this.actions$.pipe(
          ofType(fromActions.onUpdateInsuranceStatusFailed.type),
          tap(
              (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
          )
      ), { dispatch: false }
    );
    errorOnGetFailedPayments$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetFailedPaymentsFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    errorOnUpdateInsuranceEnddate$ =  createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onCancelInusranceFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
            )
        ), { dispatch: false }
    );
    updateInsuranceEndDateSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onCancelInusranceSuccessfully.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleSuccessEvent('Inusrance is cancelled successfully')
            )
        ), { dispatch: false }
    );

    importInsurances$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.importInusrances.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<any>) => {
                    return this.service.importInsurances(action.payload)
                        .pipe(
                            map((response: any) =>
                              fromActions.onImportInusrancesSuccessfully({ payload: response })),
                            catchError((error) => {
                                return of(fromActions.onImportInusrancesFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    handleAllErrors(error) {
        debug(error);
        return error;
    }

    handleSuccessEvent(message: string) {
        this._snackBar.open(message, null, {
            duration: 2000,
            panelClass: ['blue-snackbar']
        })
    }
}
