import { Component, Injector, OnInit } from '@angular/core';
import { AdminIssuersProvider } from '@modeso/twint-lib-admin-fe';
import { Router } from '@angular/router';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { AdminSpotlightProvider } from '../../shared/provider/spotlight.provider';
const debug = Debug('ihub:admin:IssuerManagmentPage');
export interface INavigationItem {
  link: string;
  name: string;
}
@Component({
  selector: 'app-issuer-managment',
  templateUrl: './issuer-managment.page.html',
  styleUrls: ['./issuer-managment.page.scss']
})
export class IssuerManagmentPage extends BasePageComponent implements OnInit {
  issuers: INavigationItem[];
  private firstLoad: boolean;
  constructor(protected issuerProvider: AdminIssuersProvider, protected sportlightProvider: AdminSpotlightProvider,
              private router: Router, private injector: Injector) {
    super(injector);
    this.issuers = [];
    this.firstLoad = true;
    const entities$ = this.getEntities();
    this.subscriptions.push(this.handleResponse(entities$));
  }

  protected getEntities(): any {
    return this.issuerProvider.getAllIssuers$();
  }

  protected handleResponse(entity$): any {
    return entity$.subscribe((allIssuers) => {
      if (!allIssuers) {
        this.issuerProvider.dispatchGetAllIssuers();
      } else {
        const sortedIssuers = allIssuers.sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1);
        this.issuers = sortedIssuers.map((x) => {
          return { link: x.issuerId, name: x.issuerName };
        });
        this.addNewIssuerLink();
        if (this.firstLoad) {
          this.firstLoad = false;
          this.router.navigateByUrl(this.getNavigationLink(sortedIssuers[0].issuerId));
        }
      }
    });
  }

  protected addNewIssuerLink() {
    this.issuers.push({
      link: 'addIssuer',
      name: '',
    });
  }

  protected getNavigationLink(issuerId: string) {
    return `/de-ch/product-managment/issuers/${issuerId}`;
  }

  ngOnInit() {
  }
}
