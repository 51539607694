import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUploadURL } from '../models/upload.model';

@Injectable({
    providedIn: 'root',
})

export class UploadService {

  private server = '';
  private microserviceName = 'files';

  constructor(private http: HttpClient) {
    this.server = environment.modeso.apiGateway;
  }

  public getUploadUrl(filename): Observable<IUploadURL> {
    return this.http.get<IUploadURL>(
      `${this.server}${this.microserviceName}/url-for/${filename}`);
  }

  public uploadFile(url: string, file, contentType: string): Observable<any>  {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': contentType,
    });
    return this.http.put(url, file, {
      headers,
    });
  }
}
