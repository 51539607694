import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { IInsuranceForAnalytics, IInsurancePayment } from '../models/insurance.models';
import * as fromActions from './../actions/insurance.actions';
import * as fromNavActions from './../actions/issuer-navigation.actions';
export const adminInsuranceFeatureKey = 'modesoInsuranceAdmin';


// State Declarations - START

export interface FeatureState {
    insurances: Array<IInsuranceForAnalytics>;
    payments: Array<IInsurancePayment>;
    failedPayment: Array<IInsurancePayment>;
    error: HttpErrorResponse;
    navTab: string;
    importInsurance: any;
}

export interface AppState {
    modesoInsuranceAdmin: FeatureState;
}

// State Declarations - END


// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoInsuranceAdmin;

export const featureInsurances = createSelector(
    selectFeature,
    (state: FeatureState) => state.insurances
);

export const featurePayments = createSelector(
    selectFeature,
    (state: FeatureState) => state.payments,
);

export const featureFailedPayments = createSelector(
    selectFeature,
    (state: FeatureState) => state.failedPayment,
);
export const featureError = createSelector(
    selectFeature,
    (state: FeatureState) => state.error,
);

export const selectNavTab = createSelector(
    selectFeature,
    (state: FeatureState) => state.navTab,
);

export const selectImportInsurance = createSelector(
    selectFeature,
    (state: FeatureState) => state.importInsurance,
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    insurances: new Array<IInsuranceForAnalytics>(),
    payments: new Array<IInsurancePayment>(),
    failedPayment: undefined,
    error: undefined,
    navTab: undefined,
    importInsurance: undefined
};

const adminReducer = createReducer(
    initialState,
    on(fromActions.getInsurances, (state) => ({ ...state, error: undefined, insurances: [] })),
    on(fromActions.onGetInsurancesSuccessfully, (state, action) => ({ ...state, insurances: action.payload })),
    on(fromActions.onGetInsurancesFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromActions.getPayments, (state) => ({ ...state, error: undefined, payments: [] })),
    on(fromActions.onGetPaymentsSuccessfully, (state, action) => ({ ...state, payments: action.payload })),
    on(fromActions.onGetPaymentsFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromNavActions.onNavigateToNewTab, (state, action) => ({ ...state, navTab: action.payload })),
    on(fromActions.updateInsuranceStatus, (state) => ({ ...state, error: undefined })),
    on(fromActions.onUpdateInsuranceStatusSuccessfully, (state, action) => {
      const updatedInsurances = state.insurances.map( (insurance) => {
        return (insurance._id === action.payload.id) ?
          { ...insurance, insuranceStatus: action.payload.insuranceStatus, endDate: action.payload.endDate } : insurance;
      });
      return { ...state, insurances: [...updatedInsurances] };
    }),
    on(fromActions.onUpdateInsuranceStatusFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromActions.getFailedPayments, (state) => ({ ...state, error: undefined, failedPayment: [] })),
    on(fromActions.onGetFailedPaymentsSuccessfully, (state, action) => ({ ...state, failedPayment: action.payload })),
    on(fromActions.onGetFailedPaymentsFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromActions.cancelInusrance, (state) => ({ ...state, error: undefined })),
    on(fromActions.onCancelInusranceSuccessfully, (state) => ({ ...state})),
    on(fromActions.onCancelInusranceFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromActions.importInusrances, (state) => ({ ...state, error: undefined  ,  importInsurance: undefined})),
    on(fromActions.onImportInusrancesSuccessfully, (state, action) => ({ ...state , importInsurance: action.payload })),
    on(fromActions.onImportInusrancesFailed, (state, action) => ({ ...state, error: action.payload })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return adminReducer(state, action);
}
