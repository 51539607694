import { Injectable } from '@angular/core';
import Debug from 'debug';
import { AdminProductService } from '../services/product.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap, concatMap } from 'rxjs/operators';
import * as fromActions from '../actions/product.actions';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material';
const debug = Debug('modeso:twint-lib-admin-fe:AdminInsuranceEffects');


@Injectable()
export class AdminProductEffects {
    constructor(private actions$: Actions, private service: AdminProductService, private snackBar: MatSnackBar) { }

    getAllProductsAllLanguages$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllProductsInAllLanguages.type),
            mergeMap(
                () => {
                    return this.service.getAllProductsAllLanguages()
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.getAllProductsInAllLanguagesSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.getAllProductsInAllLanguagesFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnGetAllProductsAllLanguages$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllProductsInAllLanguagesFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    updateDelayedPayment$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateDelayedPayments.type),
            concatMap(
                (action: fromActions.ActionWithPayload<any>) => {

                    return this.service.updateProductDelayedPayment(action.payload.productId, action.payload.delayedPayment)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.updateDelayedPaymentsSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.updateDelayedPaymentsFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnUpdateDelayedPayments$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateDelayedPaymentsFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.openSnackBar('Product failed to be updated', 'red-snackbar'),
            ),
        ), { dispatch: false },
    );
    onUpdateDelayedPaymentsSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateDelayedPaymentsSuccessfully.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.openSnackBar('Product Updated Successfully', 'blue-snackbar'),
            ),
        ), { dispatch: false },
    );
    public handleOnLoadAllProductErrors(error) {
        debug(error);
        return error;
    }

    private openSnackBar(message: string, style: string) { 
        this.snackBar.open(message, null, { duration: 2000, panelClass: [style] });
    }
}