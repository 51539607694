import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { AdminConfig, AdminConfigService } from './admin.config';
import * as fromReducer from './reducers/admin.reducer';
import { AdminEffects } from './effects/admin.effects';
import { AdminFraudLimitsEffects } from './effects/admin-fraudLimits.effects';
import { AdminService } from './services/admin.service';
import { AdminFraudLimitsService } from './services/admin-fraudLimits.service';
import { AdminReportEffects } from './effects/admin-report.effect';
import { AdminFraudDetectionProductsService } from './services/admin.fraudDetectionProducts.service';
import { AdminFraudDetectionProductsEffects } from './effects/admin.fraudDetectionProducts.effects';
import { AdminReportService } from './services/admin-report.service';
import { AdminManageContentService } from './services/admin-manageContent.service';
import { AdminManageContentEffects } from './effects/admin-manageContent.effect';
import { AdminWhiteListingProductsEffects } from './effects/admin.whiteListingProducts.effects';
import { AdminWhiteListingProductsService } from './services/admin.whiteListingProducts.service';
import { AdminManageProductContentService } from './services/admin-manageProductContent.service';
import { AdminManageProductContentEffects } from './effects/admin-manageProductContent.effect';
import { AdminWhiteListingUsersService } from './services/admin.whiteListingUsers.service';
import { AdminWhiteListingUsersEffects } from './effects/admin.whiteListingUsers.effects';
import { AdminAuditLogsEffects } from './effects/admin-auditLogs.effects';
import { AdminAuditLogsService } from './services/admin-auditLogs.service';
import { AdminIssuersEffects } from './effects/issuer.effects';
import { AdminIssuerService } from './services/issuer.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AdminUnLockingService } from './services/admin-unlock-admin-users';
import { AdminUnlockingEffects } from './effects/admin-unlock-users.effect';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducer.adminFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([AdminEffects, AdminFraudDetectionProductsEffects, AdminReportEffects, AdminFraudLimitsEffects,
      AdminWhiteListingProductsEffects, AdminManageContentEffects, AdminManageProductContentEffects,
      AdminWhiteListingUsersEffects, AdminAuditLogsEffects, AdminIssuersEffects, AdminUnlockingEffects]),
    MatSnackBarModule
  ],
  exports: []
})
export class AdminModule {
  static forRoot(config: AdminConfig): ModuleWithProviders {
    return {
      ngModule: AdminModule,
      providers: [
        AdminService, AdminFraudDetectionProductsService,
        AdminReportService, AdminFraudLimitsService, AdminManageContentService, AdminWhiteListingProductsService,
        AdminManageProductContentService, AdminWhiteListingUsersService, AdminAuditLogsService, AdminIssuerService,
        AdminUnLockingService,
        {
          provide: AdminConfigService,
          useValue: config
        }
      ]
    };
  }
}
