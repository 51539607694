import { createAction, props, Action } from '@ngrx/store';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse, UserResponse2FA } from '../models/administration/userResponse.model';
import { ChangePassword } from '../models/administration/changePassword.model';
import { SignupUser } from '../models/administration/signup.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminUsersResponse, IAdminUserModel } from '../models/administration/usersResponse.model';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */
export const login = createAction(
  '[Admin] login',
  props<{ payload: LoginUser }>()
);
export const onLoginSuccessfully = createAction(
  '[Admin] login successfully',
  props<{ payload: UserResponse | UserResponse2FA }>()
);

export const onLoginFailed = createAction(
  '[Admin] login failed',
  props<{ payload: any }>()
);
export const verify2FA = createAction(
  '[Admin] verify2FA',
  props<{ payload: string }>()
);
export const on2FAFailed = createAction(
  '[Admin] 2FA failed',
  props<{ payload: any }>()
);
export const on2FASuccess = createAction(
  '[Admin] 2FA Success',
  props<{ payload: any }>()
);

export const changePassword = createAction(
  '[Admin] changePassword',
  props<{ payload: ChangePassword }>()
);

export const onChangePasswordSuccessfully = createAction(
  '[Admin] changePassword successfully',
  props<{ payload: UserResponse }>()
);

export const onChangePasswordFailed = createAction(
  '[Admin] changePassword failed',
  props<{ payload: any }>()
);

export const signup = createAction(
  '[Admin] signup',
  props<{ payload: SignupUser }>()
);

export const onSignupSauccessfully = createAction(
  '[Admin] signup successfully',
  props<{ payload: UserResponse }>()
);

export const onSignupFailed = createAction(
  '[Admin] signup failed',
  props<{ payload: any }>()
);

export const onSignupAndErrorReset = createAction(
  '[Admin] signup and error reset',
);

export const logout = createAction(
  '[Admin] logout'
);

export const onLogoutSuccessfully = createAction(
  '[Admin] logout successfully',
);

export const onLogoutFailed = createAction(
  '[Admin] logout failed',
  props<{ payload: any }>()
);

export const getNewAccessToken = createAction(
  '[Admin] getNewAccessToken'
);

export const onGetNewAccessTokenSuccessfully = createAction(
  '[Admin] getNewAccessToekn successfully',
);

export const onGetNewAccessTokenFailed = createAction(
  '[Admin] getNewAccessToken failed',
  props<{ payload: any }>()
);

export const getAdminUsers = createAction(
  '[Admin] getAdminUsers'
);

export const onGetGetAdminUsersSuccessfully = createAction(
  '[Admin] onGetGetAdminUsersSuccessfully',
  props<{ payload: AdminUsersResponse }>()
);

export const onGetAdminUsersFailed = createAction(
  '[Admin] onGetAdminUsersFailed',
  props<{ payload: HttpErrorResponse }>()
);


export const updateUserById = createAction(
  '[Admin] updateUserById',
  props<{ payload: IAdminUserModel }>()
);

export const onUpdateUserByIdSuccessfully = createAction(
  '[Admin] onUpdateUserByIdSuccessfully',
  props<{ payload: IAdminUserModel }>()
);

export const onUpdateUserByIdFailed = createAction(
  '[Admin] onUpdateUserByIdFailed',
  props<{ payload: HttpErrorResponse }>()
);

export const deleteUserById = createAction(
  '[Admin] deleteUserById',
  props<{ payload: IAdminUserModel }>()
);

export const onDeleteUserByIdSuccessfully = createAction(
  '[Admin] onDeleteUserByIdSuccessfully',
  props<{ payload: IAdminUserModel }>()
);

export const onDeleteUserByIdFailed = createAction(
  '[Admin] onDeleteUserByIdFailed',
  props<{ payload: HttpErrorResponse }>()
);

export const getUserRoles = createAction(
  '[Admin] getUserRoles'
);

export const onGetUserRolesSuccessfully = createAction(
  '[Admin] onGetUserRolesSuccessfully',
  props<{ payload: string[] }>()
);

export const onGetUserRolesFailed = createAction(
  '[Admin] onGetUserRolesFailed',
  props<{ payload: HttpErrorResponse }>()
);


/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}
