import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslationsByKeyResponseDetails } from '@modeso/twint-lib-admin-fe';

@Component({
    selector: 'app-update-localization-by-key-issuer-dialog',
    templateUrl: 'updateLocalizationByKeyDialog.html',
    styleUrls: ['./updateLocalizationByKeyDialog.scss'],
})
export class UpdateLocalizationByKeyDialog {
    updateLocalizationByKeyForm: FormGroup;
    private domParser;

    constructor(
        public dialogRef: MatDialogRef<UpdateLocalizationByKeyDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.domParser = new DOMParser();

        this.updateLocalizationByKeyForm = new FormGroup({

            key: new FormControl({
                value: data.localization.key,
                disabled: true
            }, { validators: [Validators.required] }),
            'de-ch': new FormControl(this.unescape(data.localization.text['de-ch'])),
            'en-us': new FormControl(this.unescape(data.localization.text['en-us'])),
            'fr-ch': new FormControl(this.unescape(data.localization.text['fr-ch'])),
            'it-ch': new FormControl(this.unescape(data.localization.text['it-ch'])),
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    save() {
        if (this.updateLocalizationByKeyForm.invalid) {
            return;
        } else {
            const localization: TranslationsByKeyResponseDetails = {
                key: this.data.localization.key,
                text: {
                    'de-ch': this.updateLocalizationByKeyForm.value['de-ch'],
                    'en-us': this.updateLocalizationByKeyForm.value['en-us'],
                    'fr-ch': this.updateLocalizationByKeyForm.value['fr-ch'],
                    'it-ch': this.updateLocalizationByKeyForm.value['it-ch'],

                }
            };
            this.dialogRef.close(localization);

        }
    }

    buttonStatus() {
        return this.updateLocalizationByKeyForm.invalid;
    }

    unescape(text: string): string {
        return this.domParser.parseFromString(text, 'text/html').body.innerText;
    }
}
