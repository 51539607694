import { Action, createAction, props } from '@ngrx/store';
import { ISpecailDealAdmin } from '../models/administration/specialDealsAdmin.model';

export const onGetLockedAdminUsers = createAction(
    '[TSD Admin] GetLockedAdminUsers',
);
export const onGetLockedAdminUsersSuccessfully = createAction(
    '[TSD Admin] onGetLockedAdminUsersSuccessfully',
    props<{ payload: ISpecailDealAdmin[]}>(),
);
export const onGetLockedAdminUsersFailed = createAction(
    '[TSD Admin]  onGetLockedAdminUsersFailed',
    props<{ payload: any }>(),
);
export const onUnLockAdminUser = createAction(
    '[TSD Admin] onUnLockAdminUser',
    props<{ payload: string}>(),
);
export const onUnLockedAdminUserSuccessfully = createAction(
    '[TSD Admin] onUnLockedAdminUserSuccessfully',
    props<{ payload: ISpecailDealAdmin}>(),
);
export const onUnLockedAdminUsersFailed = createAction(
    '[TSD Admin]  onUnLockedAdminUsersFailed',
    props<{ payload: any }>(),
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}