import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from '../actions/admin.whiteListingProducts.actions';
import { AdminWhiteListingProductsService } from '../services/admin.whiteListingProducts.service';

const debug = Debug('modeso:twint-lib-admin-fe:AdminWhiteListingProductsEffects ');

@Injectable()
export class AdminWhiteListingProductsEffects {

    getAllProductsAllLanguages$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllProductsInAllLanguages.type),
            mergeMap(
                () => {
                    return this.service.getAllProductsAllLanguages()
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.getAllProductsInAllLanguagesSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.getAllProductsInAllLanguagesFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnGetAllProductsAllLanguages$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllProductsInAllLanguagesFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    whiteListProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.whiteListProduct.type),
            mergeMap(
                (payload) => {
                    return this.service.whiteListProduct(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.whiteListProductSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.whiteListProductFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnWhiteListProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.whiteListProductFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload),
            ),
        ), { dispatch: false },
    );
    unWhiteListProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.unWhiteListProduct.type),
            mergeMap(
                (payload) => {
                    return this.service.unWhiteListProduct(payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.unwhiteListProductSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.unwhiteListProductFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnUnWhiteListProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.unwhiteListProductFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    synchronizeProducts$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.synchronizeProducts.type),
            mergeMap(
                () => {
                    return this.service.synchronizeProducts()
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.synchronizeProductsSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.synchronizeProductsFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnSynchronizeProducts$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.synchronizeProductsFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload),
            ),
        ), { dispatch: false },
    );


    constructor(private actions$: Actions, private service: AdminWhiteListingProductsService) { }

    public handleOnLoadAllProductErrors(error) {
        debug(error);
        return error;
    }
}
