import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { FraudLimitsPage } from './pages/fraudLimits/fraudLimits.page';
import { LoginPage } from './pages/login/login.page';
import { AuthGuard } from './shared/auth.guard';
import { ChangePasswordPage } from './pages/changePassword/changePassword.page';
import { SignupPage } from './pages/signup/signup.page';
import { ReportsPage } from './pages/reports/reports.page';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { WhiteListingPage } from './pages/whiteListing/whiteListing.page';
import { ProductManagmentPage } from './pages/productManagment/productManagment.page';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
import { UserManagementPage } from './pages/userManagement/userManagement.page';
import { ManageUsersPage } from './pages/manageUsers/manageUsers.page';
import { ClosedUserGroupPage } from './pages/closedUserGroup/closedUserGroup.page';
import { NoAuthGuard } from './shared/noauth.guard';
import { AuditLogsPage } from './pages/auditLogs/auditLogs.page';
import { SamlAssertPage } from './pages/samlAssert/samlAssert.page';
import { InsurancesManagmentPage } from './pages/insurancesManagement/insurancesManagment.page';
import { PaymentHistoryPage } from './pages/payment-history/payment-history.page';
import { InsurancesPage } from './pages/insurances/insurances.page';
import { IssuerManagmentPage } from './pages/issuer-managment/issuer-managment.page';
import { ProductListComponenet } from './pages/issuer-managment/product-list-componenet/product-list-componenet.component';
import { AddIssuerComponent } from './pages/issuer-managment/add-issuer/add-issuer.component';
import { IssuerPageGuard } from './shared/issuer.guard';
import { FailedPaymentHistoryPage } from './pages/failed-payment-history/failed-payment-history.page';
import { SpotlightListComponenet } from './pages/issuer-managment/spotlight-list-componenet/spotlight-list-componenet.component';
import { SpotlightManagmentPage } from './pages/issuer-managment/spotlight-managment.page';
import { AddSpotlightComponent } from './pages/issuer-managment/add-spotlight/add-spotlight.component';
import { DelayPaymentForProductComponent } from './pages/delay-payment-for-product/delay-payment-for-product.component';

const routes: Routes = [
  {
    path: ':language',
    component: HomePage,
    canActivate: [AuthGuard]
  },

  {
    path: '',
    redirectTo: '/de-ch/login',
    pathMatch: 'full'
  },
  {
    path: ':language/fraudLimits',
    component: FraudLimitsPage,
    canActivate: [AuthGuard]
  },
  {
    path: ':language/manageContent',
    component: ManageContentPage,
    canActivate: [AuthGuard]
  },
  {
    path: ':language/login',
    component: LoginPage,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'saml/assert',
    component: SamlAssertPage,
    canActivate: [NoAuthGuard]
  },
  {
    path: ':language/cms',
    component: ContentManagementPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'text-and-labels',
        component: ManageContentPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'faq',
        component: ManageContentPage,
        data: { isFAQ: true },
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: ':language/changePassword',
    component: ChangePasswordPage,
    canActivate: [AuthGuard]
  },
  {
    path: ':language/signup',
    component: SignupPage,
    canActivate: [AuthGuard]
  },
  {
    path: ':language/product-managment',
    component: ProductManagmentPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'issuers',
        component: IssuerManagmentPage,
        canActivate: [AuthGuard, IssuerPageGuard],
        children: [
          { path: 'addIssuer',   component: AddIssuerComponent,  canActivate: [AuthGuard]},
          { path: 'addIssuer/:issuerId',   component: AddIssuerComponent,  canActivate: [AuthGuard]},
          { path: ':issuer',   component: ProductListComponenet,  canActivate: [AuthGuard]},
        ]
      },
      {
        path: 'spotlight',
        component: SpotlightManagmentPage,
        canActivate: [AuthGuard, IssuerPageGuard],
        children: [
          { path: 'addIssuer',   component: AddIssuerComponent,  canActivate: [AuthGuard]},
          { path: ':issuer/add',   component: AddSpotlightComponent,  canActivate: [AuthGuard]},
          { path: ':issuer/edit/:spotlightId',   component: AddSpotlightComponent,  canActivate: [AuthGuard]},
          { path: ':issuer',   component: SpotlightListComponenet,  canActivate: [AuthGuard]},
        ]
      },
      {
        path: 'whiteListing-products',
        component: WhiteListingPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'delay-payment',
        component: DelayPaymentForProductComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'closed-user-group',
        component: ClosedUserGroupPage,
        canActivate: [AuthGuard]
      },
      
    ]
  },
  {
    path: ':language/insurances-managment',
    component: InsurancesManagmentPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'insurances',
        component: InsurancesPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'payment-history',
        component: PaymentHistoryPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'failed-payment-history',
        component: FailedPaymentHistoryPage,
        canActivate: [AuthGuard]
      }


    ]
  },
  {
    path: ':language/user-management',
    component: UserManagementPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin-users',
        component: ManageUsersPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'add-admin-user',
        component: SignupPage,
        canActivate: [AuthGuard]
      },
      {
        path: 'twint-users',
        component: WhiteListingPage,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: ':language/reports',
    component: ReportsPage,
    canActivate: [AuthGuard]
  },
  {
    path: ':language/auditLogs',
    component: AuditLogsPage,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: HomePage,
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
