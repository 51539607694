import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { fromBuffer, FileTypeResult } from 'file-type/core';
import { IUploadURL, IMultilanguageImage } from '../../models/upload.model';
import { UploadService } from '../../services/upload.service';
import Debug from 'debug';
const debug = Debug('ihub:spotlight:AddImageDialog');


@Component({
  selector: 'add-image-dialog',
  templateUrl: 'addImageDialog.html',
  styleUrls: ['./addImageDialog.scss'],
})
export class AddImageDialog {

  uploadedImages: IMultilanguageImage[] = [];
  images = new Array(4);
  isUploadFinished = false;
  fileReader = [];
  isEdit = false;
  editedImages = [];
  errors = [];
  languages = ['de-ch', 'fr-ch', 'it-ch', 'en-us'];
  constructor(
    public dialogRef: MatDialogRef<AddImageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IMultilanguageImage[],
    private uploadService: UploadService) {
    dialogRef.disableClose = true;

    if (data) {
      debug(data)
      this.isEdit = true;
      this.uploadedImages.push(...data);
      data.map(image => this.fileReader.push({ url: image.url }));
    }
  }

  public dropped(files: NgxFileDropEntry[], index: string): void {

    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file( async (file: File) => {
        if (!this.isFileSizeAllowed(file.size) || ! await this.validateFile(file)) {
          this.errors[index] = true;
          return;
        }
        const fileUuid = uuidv4();
        const fileName = fileUuid + '.' + droppedFile.relativePath.split('.').pop();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadedImages[index] = {
            url: reader.result,
            language: this.languages[index],
            file,
            fileName,
          };
          this.fileReader[index] = {
            url: reader.result
          };
          if (this.isEdit) {
            this.editedImages.push({
              file,
              fileName,
              index
            });
          }
        };


      });
    }


  }

  removeFile(index) {
    this.errors[index] = undefined;
    this.uploadedImages[index] = undefined;
    this.fileReader[index] = undefined;
  }

  isValid() {
    const filteredImages = this.uploadedImages.filter((image) => {
      return image !== undefined;
    });

    return filteredImages.length === 4 && !this.errors.includes(true) ? true : false;
  }

  isFileSizeAllowed(size) {
    if (size < 15000000) {
      return true;
    }
    return false;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    if (this.isEdit) {
      if (this.editedImages.length > 0) {
        for (const [index, image] of this.editedImages.entries()) {
          this.isUploadFinished = true;

          this.uploadService.getUploadUrl(image.fileName)
            .subscribe((urlObj: IUploadURL) => {
              this.uploadedImages[image.index].url = urlObj.fetchUrl;
              this.uploadService.uploadFile(urlObj.uploadUrl, this.renameFile(image.file, image.fileName), urlObj.contentType)
              .subscribe((response) => {
                delete image.file;
                delete image.fileName;
                if (index === this.editedImages.length - 1) {
                  this.isUploadFinished = false;
                  this.dialogRef.close(this.uploadedImages);
                }
              });
            });
        }
      } else {
        this.dialogRef.close(this.uploadedImages);
      }
    } else {
      this.isUploadFinished = true;

      for (const [index, image] of this.uploadedImages.entries()) {

        this.uploadService.getUploadUrl(image.fileName)
          .subscribe((urlObj: IUploadURL) => {

            image['url'] = urlObj.fetchUrl;

            this.uploadService.uploadFile(urlObj.uploadUrl, this.renameFile(image.file, image.fileName), urlObj.contentType).subscribe((response) => {
              if (index === this.uploadedImages.length - 1) {
                this.isUploadFinished = false;
                this.dialogRef.close(this.uploadedImages);
              }
            });
            delete image.file;
            delete image.fileName;
          });

      }
    }

  }

  private renameFile(originalFile: File, newName: string): File {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }


  async validateFile(file) {
    const realFileType = await this.getTrueFileType(file);
    if (!realFileType) {
      return false;
    }
    if (realFileType.mime === 'image/jpeg' ||
      realFileType.mime === 'image/png') {
      return true;
    }
    return false;
  }

  async getTrueFileType(file): Promise<FileTypeResult | undefined> {
    return file.arrayBuffer().then(buffer => fromBuffer(buffer));
  }

}
