import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor() {
        this.authorized = 'authorized';
        this.userRole = 'userrole';
    }
    isUserAuthorized() {
        return localStorage.getItem(this.authorized) === 'true' ? true : false;
    }
    getUserRole() {
        return JSON.parse(localStorage.getItem(this.userRole));
    }
    setAuthorization() {
        localStorage.setItem(this.authorized, 'true');
    }
    removeAuthorization() {
        localStorage.removeItem(this.authorized);
    }
    setUserRole(role) {
        localStorage.setItem(this.userRole, JSON.stringify(role));
        LocalStorageService.UserRoleObserver$.emit(role);
    }
    removeUserRole() {
        localStorage.removeItem(this.userRole);
    }
    clear() {
        localStorage.clear();
    }
}
LocalStorageService.UserRoleObserver$ = new EventEmitter();
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
