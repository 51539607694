import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { Router } from '@angular/router';
import { AdminProvider } from '@modeso/twint-lib-admin-fe';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscriptions = new Array<Subscription>();
  isUserLoggedIn: boolean;

  constructor(private router: Router, private adminProvider: AdminProvider, private localStorageService: LocalStorageService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onLogout() {
    this.subscriptions.push(
      this.adminProvider.logout$().subscribe((response) => {
        if (response) {
          this.localStorageService.removeAuthorization();
          this.router.navigate(['']);
        }
      })
    );

  }



}
