import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
    selector: 'app-page-insuranceManagement',
    templateUrl: './insurancesManagment.page.html',
    styleUrls: ['./insurancesManagment.page.scss']
})
export class InsurancesManagmentPage extends BasePageComponent implements OnInit {
    background: string = '#0076bf';
    navLinks = [
        {
            label: 'Insurances',
            link: 'insurances',
            index: 0
        },
        {
            label: 'Payment History',
            link: 'payment-history',
            index: 1
        },
        {
            label: 'Failed Payment History',
            link: 'failed-payment-history',
            index: 1
        }
    ];
    activeLink = this.navLinks[0].link;
    constructor(injector: Injector, private router: Router) {
        super(injector);

    }

    ngOnInit() {
        super.ngOnInit();
    }


}
