import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props, Action } from '@ngrx/store';
import { ICreateOrUpdateIssuer, IIssuer, IIssuerAllProductsOrder, ISortingOrder } from '@modeso/types__ihub-lib-products-be';

export const onGetAllIssuers = createAction(
    '[Admin Issuers] onGetAllIssuers',
);

export const onGetIssuersSuccessfully = createAction(
    '[Admin Issuers] getAllIssuers Successfully',
    props<{ payload: IIssuerAllProductsOrder[] }>()
);

export const onGetIssuersFailed = createAction(
    '[Admin Issuers] getAllIssuers Failed',
    props<{ payload: HttpErrorResponse }>()
);


export const onAddIssuer = createAction(
    '[Admin Issuers] onAddIssuer',
    props<{ payload: ICreateOrUpdateIssuer }>()
);

export const onAddIssuerSuccessfully = createAction(
    '[Admin Issuers] onAddIssuer Successfully',
    props<{ payload: IIssuer }>()
);

export const onAddIssuerFailed = createAction(
    '[Admin Issuers] onAddIssuer Failed',
    props<{ payload: HttpErrorResponse }>()
);

export const onUpdateIssuer = createAction(
    '[Admin Issuers] onUpdateIssuer',
    props<{ payload: ICreateOrUpdateIssuer }>()
);

export const onUpdateIssuerSuccessfully = createAction(
    '[Admin Issuers] onUpdateIssuer Successfully',
    props<{ payload: IIssuer }>()
);

export const onUpdateIssuerFailed = createAction(
    '[Admin Issuers] onUpdateIssuer Failed',
    props<{ payload: HttpErrorResponse }>()
);

export const onAddOrUpdateSortingOrder = createAction(
    '[Admin Issuers] onAddOrUpdateSortingOrder',
    props<{ payload: ISortingOrder }>()
);

export const onAddOrUpdateSortingOrderSuccessfully = createAction(
    '[Admin Issuers] onAddOrUpdateSortingOrder Successfully',
    props<{ payload: IIssuerAllProductsOrder }>()
);

export const onAddOrUpdateSortingOrderFailed = createAction(
    '[Admin Issuers] onAddOrUpdateSortingOrder Failed',
    props<{ payload: HttpErrorResponse }>()
);


export const onRemoveSortingOrder = createAction(
    '[Admin Issuers] onRemoveSortingOrder',
    props<{ payload: any }>()
);

export const onRemoveSortingOrderSuccessfully = createAction(
    '[Admin Issuers] onRemoveSortingOrderSuccessfully Successfully',
    props<{ payload: ISortingOrder }>()
);

export const onRemoveSortingOrderFailed = createAction(
    '[Admin Issuers] onRemoveSortingOrderFailed Failed',
    props<{ payload: HttpErrorResponse }>()
);


export const onAddOrUpdateFeatureProduct = createAction(
    '[Admin Issuers] onAddOrUpdateFeatureProduct',
    props<{ payload: ISortingOrder }>()
);

export const onAddOrUpdateFeatureProductSuccessfully = createAction(
    '[Admin Issuers] onAddOrUpdateFeatureProduct Successfully',
    props<{ payload: string }>()
);

export const onAddOrUpdateFeatureProductFailed = createAction(
    '[Admin Issuers] onAddOrUpdateFeatureProduct Failed',
    props<{ payload: HttpErrorResponse }>()
);

export const onRemoveFeatureProduct = createAction(
    '[Admin Issuers] onRemoveFeatureProduct',
    props<{ payload: any }>()
);

export const onRemoveFeatureProductSuccessfully = createAction(
    '[Admin Issuers] onRemoveFeatureProductSuccessfully Successfully',
    props<{ payload: ISortingOrder }>()
);

export const onRemoveFeatureProductFailed = createAction(
    '[Admin Issuers] onRemoveFeatureProductFailed Failed',
    props<{ payload: HttpErrorResponse }>()
);

export const onSetCurrentIssuer = createAction(
    '[Admin Issuers] onSetCurrentIssuer',
    props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
