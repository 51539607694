export class ProductEnforceTcUpdate {
    public enforceTC: boolean;
}

export class ProductEnforceTcUpdateRequest {
    constructor( public productLineName: string, public enforceTC: boolean) {}
}

export class ProductEnforceTcUpdateResponse {
    public productLineName: string;
    public enforceTC: boolean;
}

export class ProductEnforceTcUpdateRequestModel {
    constructor(public enforceTC: boolean) {}
}

