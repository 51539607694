/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./skeleton/header/header.component.ngfactory";
import * as i3 from "./skeleton/header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "@modeso/twint-lib-admin-fe";
import * as i6 from "./shared/services/localStorage.service";
import * as i7 from "./skeleton/sidebar/sidebar.component.ngfactory";
import * as i8 from "./skeleton/sidebar/sidebar.component";
import * as i9 from "./skeleton/content-area/content-area.component.ngfactory";
import * as i10 from "./skeleton/content-area/content-area.component";
import * as i11 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["id", "admin-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 8634368, null, 0, i3.HeaderComponent, [i4.Router, i5.AdminProvider, i6.LocalStorageService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-sidebar", [], null, null, null, i7.View_SidebarComponent_0, i7.RenderType_SidebarComponent)), i1.ɵdid(5, 8634368, null, 0, i8.SidebarComponent, [i6.LocalStorageService, i5.AdminProvider, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-content-area", [], null, null, null, i9.View_ContentAreaComponent_0, i9.RenderType_ContentAreaComponent)), i1.ɵdid(7, 49152, null, 0, i10.ContentAreaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i11.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
