import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse } from '../models/administration/userResponse.model';
import { ChangePassword } from '../models/administration/changePassword.model';
import { SignupUser } from '../models/administration/signup.model';
import { IAdminUserModel } from '../models/administration/usersResponse.model';
import Debug from 'debug';

const debug = Debug('modeso:twint-lib-admin-fe:AdminService');
export interface AdminResponse {
  user: UserResponse;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private server = '';
  private microserviceName = 'admin';
  private readonly route = '/';
  private readonly loginUrl = 'login';
  private readonly verify2fa = '2fa/verify';
  private readonly changePasswordurl = 'changepassword';
  private readonly signupUrl = 'signup';
  private readonly logoutUrl = 'logout';
  private readonly accesstoken = 'token';
  private readonly users = 'users';
  private readonly userRoles = 'userRoles';


  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(AdminConfigService) private config) {
    this.server = config.apiGateway;
  }

  login(user: { payload: LoginUser }) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.loginUrl}`;
    return this.http.post<UserResponse>(url, user.payload);
  }
  verify2Fa(token: string) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.verify2fa}`;
    return this.http.post<UserResponse>(url, { token });
  }

  changePassword(passwords: { payload: ChangePassword }) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.changePasswordurl}`;
    return this.http.put<UserResponse>(url, passwords.payload);
  }

  signup(user: { payload: SignupUser }) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.signupUrl}`;
    return this.http.post<UserResponse>(url, user.payload);
  }

  logout() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.logoutUrl}`;
    return this.http.get(url);
  }

  getNewAccesToken() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.accesstoken}`;
    return this.http.get(url);
  }

  getAdminUsers() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.users}`;
    return this.http.get(url);
  }

  updateAdminUser(user: IAdminUserModel) {
    debug(user);
    const url = `${this.server}${this.microserviceName}${this.route}${this.users}/${user.id}`;
    return this.http.put(url, user);
  }

  deleteAdminUser(id: string) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.users}/${id}`;
    return this.http.delete(url);
  }

  getUserRoles() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.userRoles}`;
    return this.http.get(url);
  }
}
