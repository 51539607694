import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActionsAdminFraudLimits from './../actions/admin-fraudLimits.actions';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import * as AdminFromActions from './../actions/admin.actions';
import * as fromWhitelistUsersActions from './../actions/admin.whiteListingUsers.actions';
import { UserResponse } from '../models/administration/userResponse.model';
import * as FraudDetectionProductsFromActions from '../actions/admin.fraudDetectionProducts.actions';
import { IBlockedProductModel } from '../models/fraudDetectionProducts/blockedProducts.model';
import { UnBlockProductResponse } from '../services/admin.fraudDetectionProducts.service';
import * as fromAdminReportActions from './../actions/admin-reports.action';
import { reportResponse } from '../services/admin-report.service';
import * as fromAdminManageContentActions from './../actions/admin-manageContent.actions';
import * as fromAdminManageProductContentActions from './../actions/admin-manageProductContent.actions';
import { AllTranslationsDetails } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AllAvailbleProducts } from '../models/whiteListingProducts/getAllProductsResponse.model';
import * as AdminWhiteListProducts from './../actions/admin.whiteListingProducts.actions';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';
import { ManagedProduct, ManageProductsResponse, ProductContent } from '../models/manageProductContent/managedProductsResponse.model';
import { ProductContentUpdateResponse } from '../models/manageProductContent/updateProductContent.model';
import { AdminUsersResponse, IAdminUserModel } from '../models/administration/usersResponse.model';
import { WhitelistedUsersResponseModel } from '../models/whiteListUsers/whitelistedUserResponse.model';
import { UserSearchResponseModel } from '../models/whiteListUsers/userSearch.model';
import { AddOrRemoveWhitelistedUserResponseModel } from '../models/whiteListUsers/addOrRemoveWhitelistedUser.response.model';
import { IssuerLimitsOfAddOrUpdateRequest, IssuerLimitsOfDeleteRequest } from '../models/fraudLimits/issuerLimits.model';
import { ProductEnforceTcUpdateResponse } from '../models/manageProductContent/updateProductEnforceTC.model';
import * as fromActionsAdminAuditLogs from './../actions/admin-auditLogs.actions';
import { AuditLogsResponse } from '../models/auditLogs/auditLogsResponse.model';
import { UserRolesResponse } from '../models/administration/userRolesResponse.model';
import {IIssuerAllProductsOrder} from '@modeso/types__ihub-lib-products-be';
import * as fromIssuerActions from '../actions/admin-issuers.actions';
import * as fromUnLockAdminActions from '../actions/admin-unlock-users.actions';
import { ISpecailDealAdmin } from '../models/administration/specialDealsAdmin.model';

export const adminFeatureKey = 'modesoAdminMicroservice';

// State Declarations - START

export interface FeatureState {
    fraudLimits: Array<FraudLimitsResponse>;
    addOrUpdateLimitResponse: FraudLimitsResponse | IssuerLimitsOfDeleteRequest | IssuerLimitsOfAddOrUpdateRequest;
    login: UserResponse;
    signup: UserResponse;
    allProductsAllLanguages: AllAvailbleProducts[];
    whiteList: WhiteListingResponseModel;
    products: IBlockedProductModel;
    sycnhronize: string;
    unBlockState: UnBlockProductResponse;
    report: reportResponse;
    localizations: Array<AllTranslationsDetails>;
    productContent: ManageProductsResponse;
    adminUsers: AdminUsersResponse;
    whitelistedUsers: WhitelistedUsersResponseModel;
    searchUserResult: UserSearchResponseModel;
    issuers: IIssuerAllProductsOrder[];
    addedOrUpdatedIssuer: IIssuerAllProductsOrder;
    addOrRemoveUserFromWhitelistResponse: AddOrRemoveWhitelistedUserResponseModel;
    auditLogsResponse: AuditLogsResponse;
    userRoles: UserRolesResponse;
    lockedUsers: ISpecailDealAdmin[];
    error: HttpErrorResponse;
    errorFraudDefaultLimit: HttpErrorResponse;
    errorFraudIssuerLimit: HttpErrorResponse;
    salesOrders: any;
    featureProduct:string;
    currentIssuer:string;
}

export interface AppState {
    modesoAdminMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoAdminMicroservice;

export const featureFraudLimits = createSelector(
    selectFeature,
    (state: FeatureState) => state.fraudLimits
);

export const featureFraudUpdateResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.addOrUpdateLimitResponse,
);


export const selectFeatureLogin = createSelector(
    selectFeature,
    (state: FeatureState) => state.login
);

export const featureReport = createSelector(
    selectFeature,
    (state: FeatureState) => state.report
);

export const selectFeatureSignup = createSelector(
    selectFeature,
    (state: FeatureState) => state.signup
);

export const selectFeatureLocalizations = createSelector(
    selectFeature,
    (state: FeatureState) => state.localizations
);
export const selectFeatureProductContent = createSelector(
    selectFeature,
    (state: FeatureState) => state.productContent
);

export const selectFeatureProducts = createSelector(
    selectFeature,
    (state: FeatureState) => state.products);
export const selectFeatureUnBlockResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.unBlockState);

export const selectFeatureGetAllProductsAllLanguages = createSelector(
    selectFeature,
    (state: FeatureState) => state.allProductsAllLanguages);

export const selectFeatureWhiteListResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.whiteList);

export const selectFeatureSynchronizeProductsResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.sycnhronize);

export const selectFeatureAdminUsers = createSelector(
    selectFeature,
    (state: FeatureState) => state.adminUsers);

export const selectFeatureWhitelistedUsers = createSelector(
    selectFeature,
    (state: FeatureState) => state.whitelistedUsers);

export const selectFeatureSearchUserResult = createSelector(
    selectFeature,
    (state: FeatureState) => state.searchUserResult);

export const selectFeatureAddOrRemoveUserFromWhitelistResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.addOrRemoveUserFromWhitelistResponse);

export const selectFeatureUserRoles = createSelector(
    selectFeature,
    (state: FeatureState) => state.userRoles);

export const featureError = createSelector(
    selectFeature,
    (state: FeatureState) => state.error,
);
export const featureErrorFraudDefault = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorFraudDefaultLimit,
);
export const featureErrorFraudIssuer = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorFraudIssuerLimit,
);
export const featureAuditLogsResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.auditLogsResponse
);

export const selectIssuersFeature = createSelector(
    selectFeature,
    (state: FeatureState) => state.issuers
);

export const selectIssuerByName = (issuerId: string) => createSelector(
    selectIssuersFeature,
    (issuers: IIssuerAllProductsOrder[]) => {
        return issuers ? issuers.find((issuer) => issuer.issuerId === issuerId) : null;
    }
);
export const selectNewelyAddedOrUpdatedIssuer = createSelector(
    selectFeature,
    (state: FeatureState) => state.addedOrUpdatedIssuer
);
export const selectFeatureLockedUsers = createSelector(
    selectFeature,
    (state: FeatureState) => state.lockedUsers
);

export const featureSalesOrders = createSelector(
  selectFeature,
  (state: FeatureState) => state.salesOrders
);

export const getFeatureProduct = createSelector(
    selectFeature,
    (state: FeatureState) => state.featureProduct
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    fraudLimits: new Array<FraudLimitsResponse>(),
    addOrUpdateLimitResponse: undefined,
    login: undefined,
    signup: undefined,
    allProductsAllLanguages: undefined,
    whiteList: new WhiteListingResponseModel(),
    sycnhronize: '',
    products: { products: new Array<string>() },
    unBlockState: { message: '' },
    report: {} as reportResponse,
    localizations: new Array<AllTranslationsDetails>(),
    productContent: new ManageProductsResponse(),
    adminUsers: new AdminUsersResponse(),
    whitelistedUsers: new WhitelistedUsersResponseModel(),
    searchUserResult: new UserSearchResponseModel(),
    addOrRemoveUserFromWhitelistResponse: undefined,
    auditLogsResponse: new AuditLogsResponse(),
    userRoles: undefined,
    lockedUsers: undefined,
    error: undefined,
    errorFraudDefaultLimit: undefined,
    errorFraudIssuerLimit: undefined,
    issuers: undefined,
    addedOrUpdatedIssuer: undefined,
    salesOrders: undefined,
    featureProduct:undefined,
    currentIssuer:undefined
};

const adminReducer = createReducer(
    initialState,
    on(fromAdminManageProductContentActions.getAllProductContent, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.onGetAllProductContentSuccessfully, (state, action) =>
        ({ ...state, productContent: { products: action.payload, action: "GET" } as unknown as ManageProductsResponse, error: undefined })),
    on(fromAdminManageProductContentActions.updateContentByProductLine, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.updateEnforceTcByProductLine, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.onUpdateContentByProductLineSuccessfully, (state, action) => {

        const currentProducts = state.productContent;
        const update: ProductContentUpdateResponse = action.payload;
        console.log(update.productLineName);

        currentProducts.products.forEach((product: ManagedProduct) => {
            console.log(product.productLineName);
            if (product.productLineName === update.productLineName) {
                product.content.forEach((content: ProductContent) => {
                    if (content.locale === update.locale) {
                        content.twintDescription = update.description;
                    }
                });
            }
        });
        return {
            ...state,
            productContent: currentProducts
            , error: undefined

        };
    }),

    on(fromAdminManageProductContentActions.onUpdateEnforceTcByProductLineSuccessfully, (state, action) => {

        const currentProducts = state.productContent;
        const update: ProductEnforceTcUpdateResponse = action.payload;

        currentProducts.products.forEach((product: ManagedProduct) => {
            if (product.productLineName === update.productLineName) {
                product.enforceTC = update.enforceTC
            }
        });
        return {
            ...state,
            productContent: { products: currentProducts.products, action: "UPDATE_ENFORCE_TC" } as unknown as ManageProductsResponse
            , error: undefined
        };
    }),

    on(fromAdminManageProductContentActions.onGetAllProductContentFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromAdminManageProductContentActions.onUpdateContentByProductLineFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromAdminManageProductContentActions.onUpdateEnforceTcByProductLineFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromAdminManageContentActions.getAllLocalizations, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onGetAllLocalizationsSuccessfully, (state, action) =>
        ({ ...state, localizations: action.payload, error: undefined })),
    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromAdminManageContentActions.updateLocalizationByKey, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onUpdateLocalizationByKeySuccessfully, (state, action) =>
        ({
            ...state,
            localizations:
                [...state.localizations.map((translation: AllTranslationsDetails) => translation.key === action.payload.key ?
                    translation.text = action.payload.text : translation)]
            , error: undefined

        })),

    on(fromAdminManageContentActions.onUpdateLocalizationByKeyFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromActionsAdminFraudLimits.getFraudLimits, (state) => ({ ...state,
        errorFraudDefaultLimit: undefined,
        errorFraudIssuerLimit: undefined,
        addOrUpdateLimitResponse: undefined,
    })),
    on(fromActionsAdminFraudLimits.onGetFraudLimitsSuccessfully, (state, action) =>
        ({ ...state, fraudLimits: action.payload,
            errorFraudDefaultLimit: undefined,
            errorFraudIssuerLimit: undefined,
        })),
    on(fromActionsAdminFraudLimits.onGetFraudLimitsFailed, (state, action) => ({ ...state, fraudLimits: [],
        errorFraudDefaultLimit: action.payload,
        errorFraudIssuerLimit: action.payload,
     })),

    on(fromActionsAdminFraudLimits.updateDefaultFraudLimit, (state) => ({ ...state, errorFraudDefaultLimit: undefined })),
    on(fromActionsAdminFraudLimits.onUpdateDefaultFraudLimitSuccessfully, (state, action) =>
        ({
            ...state,
            // tslint:disable-next-line: max-line-length
            fraudLimits: [...state.fraudLimits.map(defaultFraudLimit => defaultFraudLimit.name === action.payload.name ? action.payload : defaultFraudLimit)],
            errorFraudDefaultLimit: undefined,
            addOrUpdateLimitResponse: action.payload,
        })),
    on(fromActionsAdminFraudLimits.onUpdateDefaultFraudLimitFailed, (state, action) => ({ ...state,
        errorFraudDefaultLimit: action.payload })),

    on(fromActionsAdminFraudLimits.addIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onAddIssuerSuccessfully, (state, action) =>
        ({ ...state, fraudLimits: [...state.fraudLimits, action.payload],
            addOrUpdateLimitResponse: action.payload,
            errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onAddIssuerFailed, (state, action) => ({ ...state, errorFraudIssuerLimit: action.payload })),

    on(fromActionsAdminFraudLimits.updateIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onUpdateIssuerSuccessfully, (state, action) =>
        ({
            ...state,
            fraudLimits: [...state.fraudLimits.map(fraudIssuer => fraudIssuer.name === action.payload.name ? action.payload : fraudIssuer)],
            addOrUpdateLimitResponse: action.payload,
            errorFraudIssuerLimit: undefined
        })),
    on(fromActionsAdminFraudLimits.onUpdateIssuerFailed, (state, action) => ({ ...state, errorFraudIssuerLimit: action.payload })),

    on(fromActionsAdminFraudLimits.deleteIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onDeleteIssuersuccessfully, (state, action) =>
        ({
            ...state,
            fraudLimits: [...state.fraudLimits.filter(element => element.name !== action.payload.issuerName)],
            addOrUpdateLimitResponse: action.payload,
            errorFraudIssuerLimit: undefined
        })),
    on(fromActionsAdminFraudLimits.onDeleteIssuerFailed, (state, action) => ({ ...state, errorFraudIssuerLimit: action.payload })),

    on(AdminFromActions.login, (state) => ({ ...state, error: undefined, login: undefined })),
    on(AdminFromActions.onLoginSuccessfully, (state, action) => ({ ...state, login: action.payload, error: undefined })),
    on(AdminFromActions.onLoginFailed, (state, action) => ({ ...state, login: undefined, error: action.payload })),
    on(AdminFromActions.getAdminUsers, (state) => ({ ...state, error: undefined })),

    on(AdminFromActions.onGetGetAdminUsersSuccessfully, (state, action) => ({
        ...state, adminUsers: action.payload,
        error: undefined
    })),
    on(AdminFromActions.onGetAdminUsersFailed, (state, action) => ({
        ...state, adminUsers: new AdminUsersResponse()
        , error: action.payload
    })),

    on(AdminFromActions.updateUserById, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onUpdateUserByIdSuccessfully, (state, action) => {

        const currentUsers = state.adminUsers;
        const update: IAdminUserModel = action.payload;
        currentUsers.forEach((user: IAdminUserModel) => {
            if (user.id === update.id) {
                user.role = update.role;
            }
        });
        return {
            ...state,
            adminUsers: currentUsers
            , error: undefined

        };
    }),
    on(AdminFromActions.onUpdateUserByIdFailed, (state, action) => ({
        ...state, adminUsers: new AdminUsersResponse()
        , error: action.payload
    })),

    on(AdminFromActions.deleteUserById, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onDeleteUserByIdSuccessfully, (state, action) => {

        const currentUsers = state.adminUsers;
        const update: IAdminUserModel = action.payload;
        const updatedUsers = currentUsers.filter((user) => {
            return user.id !== update.id;
        });
        return {
            ...state,
            adminUsers: updatedUsers
            , error: undefined

        };
    }),
    on(AdminFromActions.onDeleteUserByIdFailed, (state, action) => ({
        ...state, adminUsers: new AdminUsersResponse()
        , error: action.payload
    })),

    on(AdminFromActions.changePassword, (state) => ({})),
    on(AdminFromActions.onChangePasswordSuccessfully, (state, action) => ({ ...state, login: action.payload, error: undefined })),
    on(AdminFromActions.onChangePasswordFailed, (state, action) => ({ ...state, login: {}, error: action.payload })),

    on(AdminFromActions.signup, (state) => ({ ...state })),
    on(AdminFromActions.onSignupSauccessfully, (state, action) => ({ ...state, signup: action.payload, error: undefined })),
    on(AdminFromActions.onSignupFailed, (state, action) => ({ ...state, signup: {}, error: action.payload })),
    on(AdminFromActions.onSignupAndErrorReset, (state, action) => ({ ...state, signup: {}, error: undefined })), // Added



    on(AdminFromActions.logout, (state) => ({ ...state })),
    on(AdminFromActions.onLogoutSuccessfully, (state, action) => ({ ...state, login: {}, signup: {}, error: undefined })),
    on(AdminFromActions.onLogoutFailed, (state, action) => ({ ...state, login: {}, signup: {}, error: action.payload })),

    on(AdminFromActions.getNewAccessToken, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenSuccessfully, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenFailed, (state, action) => ({ ...state, error: action.payload })),

    on(FraudDetectionProductsFromActions.getBlockedProducts, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.getBlockedProductsSuccessfully, (state, action) =>
        ({ ...state, products: action.payload, error: undefined })),
    on(FraudDetectionProductsFromActions.getBlockedProductsFailed, (state, action) => ({ ...state, products: {}, error: action.payload })),

    on(FraudDetectionProductsFromActions.unBlockProduct, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.unBlockProductSuccessfully, (state: FeatureState, action) => {
        const currentBlockedProduct: string[] = [...state.products.products];
        const newBlcokedProducts = currentBlockedProduct.filter((blockedProduct: string) => blockedProduct !== action.payload.productName);

        return {
            ...state, unBlockState: action.payload.message, products: { products: newBlcokedProducts }, error: undefined
        };
    }
    ),
    on(FraudDetectionProductsFromActions.unBlockProductFailed, (state, action) =>
        ({ ...state, unBlockState: { message: '' }, error: action.payload })),

    on(FraudDetectionProductsFromActions.unblockShop, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.onUnblockShopSuccessfully, (state, action) =>
        ({ ...state, products: {}, unBlockState: action.payload, error: undefined })),
    on(FraudDetectionProductsFromActions.onUnblockShopFailed, (state, action) =>
        ({ ...state, unBlockState: { message: '' }, error: action.payload })),

    on(fromAdminReportActions.getReport, (state) => ({ ...state })),
    on(fromAdminReportActions.onGetReportSuccessfully, (state, action) => ({ ...state, report: action.payload, error: undefined })),
    on(fromAdminReportActions.onGetReportFailed, (state, action) => ({ ...state, report: {}, error: action.payload })),

    on(AdminWhiteListProducts.getAllProductsInAllLanguages, (state) => ({ ...state })),
    on(AdminWhiteListProducts.getAllProductsInAllLanguagesSuccessfully, (state, action) =>
        ({ ...state, allProductsAllLanguages: action.payload, error: undefined })),
    on(AdminWhiteListProducts.getAllProductsInAllLanguagesFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminWhiteListProducts.whiteListProduct, (state) => ({ ...state })),
    on(AdminWhiteListProducts.whiteListProductSuccessfully, (state, action) => ({ ...state, whiteList: action.payload, error: undefined })),
    on(AdminWhiteListProducts.whiteListProductFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminWhiteListProducts.unWhiteListProduct, (state) => ({ ...state })),
    on(AdminWhiteListProducts.unwhiteListProductSuccessfully, (state, action) =>
        ({ ...state, whiteList: action.payload, error: undefined })),
    on(AdminWhiteListProducts.unwhiteListProductFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminWhiteListProducts.synchronizeProducts, (state) => ({ ...state })),
    on(AdminWhiteListProducts.synchronizeProductsSuccessfully, (state, action) =>
        ({ ...state, sycnhronize: action.payload, error: undefined })),
    on(AdminWhiteListProducts.synchronizeProductsFailed, (state, action) => ({ ...state, error: action.payload })),

    on(fromWhitelistUsersActions.getWhitelistedUsers, (state) => ({ ...state, error: undefined })),

    on(fromWhitelistUsersActions.onGetWhitelistedUsersSuccessfully, (state, action) => ({
        ...state, whitelistedUsers: action.payload,
        error: undefined
    })),
    on(fromWhitelistUsersActions.onGetWhitelistedUsersFailed, (state, action) => ({
        ...state,
        whitelistedUsers: new WhitelistedUsersResponseModel()
        , error: action.payload
    })),

    on(fromWhitelistUsersActions.searchUser, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onSearchUserSuccessfully, (state, action) => ({
        ...state, searchUserResult: action.payload,
        error: undefined
    })),
    on(fromWhitelistUsersActions.onSearchUserFailed, (state, action) => ({
        ...state,
        searchUserResult: new UserSearchResponseModel()
        , error: action.payload
    })),

    on(fromWhitelistUsersActions.addUserToWhitelist, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onAddUserToWhitelistSuccessfully, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: action.payload,
        error: undefined
    })),
    on(fromWhitelistUsersActions.onAddUserToWhitelistFailed, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined
        , error: action.payload
    })),

    on(fromWhitelistUsersActions.removeUserFromWhitelist, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onRemoveUserFromWhitelistSuccessfully, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: action.payload,
        error: undefined
    })),
    on(fromWhitelistUsersActions.onRemoveUserFromWhitelistFailed, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined
        , error: action.payload
    })),
    on(fromWhitelistUsersActions.cleanAddOrDeleteToUserWhitelistResponse, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined
        , error: undefined
    })),

    on(fromActionsAdminAuditLogs.getAuditLogs, (state) => ({
        ...state,
        error: undefined
    })),
    on(fromActionsAdminAuditLogs.onGetAuditLogsSuccessfully, (state, action) => ({
        ...state,
        auditLogsResponse: action.payload,
        error: undefined
    })),
    on(fromActionsAdminAuditLogs.onGetAuditLogsFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(AdminFromActions.getUserRoles, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetUserRolesSuccessfully, (state, action) => ({
        ...state, userRoles: { roles: action.payload } as unknown as UserRolesResponse,
        error: undefined
    })),
    on(AdminFromActions.onGetUserRolesFailed, (state, action) => ({
        ...state, userRoles: undefined,
        error: action.payload
    })),
    on(fromIssuerActions.onGetAllIssuers, (state, action) => ({
        ...state, issuers: undefined, error: undefined,
    })),
    on(fromIssuerActions.onGetIssuersSuccessfully, (state: FeatureState, action) => {
        const issuers = action.payload as IIssuerAllProductsOrder[];
        let featureProductArray = issuers.filter((product)=> product.featureProduct && product.featureProduct.length !== 0 && product.issuerId === state.currentIssuer )[0]; 
        let featureProduct = featureProductArray ? featureProductArray.featureProduct[0]: null ;
        return { ...state, issuers: [...action.payload], error: undefined, featureProduct: featureProduct };
    }),
    on(fromIssuerActions.onGetIssuersFailed, (state, action) => ({
        ...state, issuers: undefined, error: action.payload,
    })),
    on(fromIssuerActions.onAddIssuer, (state, action) => ({
        ...state, addedOrUpdatedIssuer: undefined
    })),
    on(fromIssuerActions.onAddIssuerSuccessfully, (state: FeatureState, action) => {
        const defaultIssuer = {...state.issuers.find((x) => x.isDefault)};
        const newIssuer = {
            ...action.payload,
            issuerId: action.payload._id,
            hiddenProducts: defaultIssuer.hiddenProducts,
            visibleProducts: defaultIssuer.visibleProducts,
            isDefault: false,
            hasSortingOrder: false,
            } as IIssuerAllProductsOrder;
        return { ...state, issuers: [...state.issuers, newIssuer], addedOrUpdatedIssuer: newIssuer, error: undefined };
    }),
    on(fromIssuerActions.onAddIssuerFailed, (state, action) => ({
        ...state, error: action.payload, addedOrUpdatedIssuer: undefined,
    })),

    on(fromIssuerActions.onUpdateIssuer, (state, action) => ({
        ...state, addedOrUpdatedIssuer: undefined,
    })),
    on(fromIssuerActions.onUpdateIssuerSuccessfully, (state: FeatureState, action) => {
        const updatedArrayOfIssuers = [...state.issuers];
        const updatedIssuerIndex = state.issuers.findIndex((issuer) => issuer.issuerId === action.payload._id);
        updatedArrayOfIssuers[updatedIssuerIndex] = {
            ...updatedArrayOfIssuers[updatedIssuerIndex],
            ...action.payload
        };
        return { ...state, issuers: [...updatedArrayOfIssuers],
            addedOrUpdatedIssuer: updatedArrayOfIssuers[updatedIssuerIndex], error: undefined };
    }),
    on(fromIssuerActions.onUpdateIssuerFailed, (state, action) => ({
        ...state, error: action.payload, addedOrUpdatedIssuer: undefined
    })),

    on(fromIssuerActions.onAddOrUpdateSortingOrder, (state, action) => ({
        ...state,
    })),
    on(fromIssuerActions.onAddOrUpdateSortingOrderSuccessfully, (state: FeatureState, action) => {
        const updatedArrayOfIssuers = [...state.issuers];
        const updatedIssuerIndex = state.issuers.findIndex((issuer) => issuer.issuerId === action.payload.issuerId);
        updatedArrayOfIssuers[updatedIssuerIndex] = {
            ...updatedArrayOfIssuers[updatedIssuerIndex],
            ...action.payload,
            featureProduct:[state.featureProduct]
        };
        return { ...state, issuers: [...updatedArrayOfIssuers], error: undefined };
    }),
    on(fromIssuerActions.onAddOrUpdateSortingOrderFailed, (state, action) => ({
        ...state, error: action.payload,
    })),
    on(fromIssuerActions.onRemoveSortingOrder, (state, action) => ({
        ...state,
    })),
    on(fromIssuerActions.onRemoveSortingOrderSuccessfully, (state: FeatureState, action) => {
        const updatedArrayOfIssuers = [...state.issuers];
        const removedSortedIssuerIndex = state.issuers.findIndex((x) => x.issuerId === state.currentIssuer);
        const defaultIssuer = {...state.issuers.find((x) => x.isDefault)};
        state.issuers[removedSortedIssuerIndex] = {
            ...updatedArrayOfIssuers[removedSortedIssuerIndex],
            hiddenProducts: defaultIssuer.hiddenProducts,
            visibleProducts: defaultIssuer.visibleProducts,
            hasSortingOrder: false,
        };
        return { ...state, issuers: [...state.issuers], error: undefined };
    }),
    on(fromIssuerActions.onRemoveSortingOrderFailed, (state, action) => ({
        ...state, error: action.payload,
    })),
    on(fromUnLockAdminActions.onGetLockedAdminUsers, (state) => ({
        ...state,
        lockedUsers: [],
    })),
    on(fromUnLockAdminActions.onGetLockedAdminUsersSuccessfully, (state: FeatureState, action) => {
        return { ...state, lockedUsers: action.payload };
    }),
    on(fromUnLockAdminActions.onGetLockedAdminUsersFailed, (state, action) => ({
        ...state, error: action.payload,
    })),

    on(fromUnLockAdminActions.onUnLockAdminUser, (state) => ({
        ...state,
    })),
    on(fromUnLockAdminActions.onUnLockedAdminUserSuccessfully, (state: FeatureState, action) => {
        const updatedLockedUsers: ISpecailDealAdmin[] = [...state.lockedUsers];
        const unLockedUserIndex = state.lockedUsers.findIndex(x => x._id === action.payload._id);
        if (unLockedUserIndex > -1) {
            updatedLockedUsers.splice(unLockedUserIndex, 1);
        }
        return { ...state, lockedUsers: [...updatedLockedUsers]};
    }),
    on(fromUnLockAdminActions.onGetLockedAdminUsersFailed, (state, action) => ({
        ...state, error: action.payload,
    })),

    on(AdminFromActions.verify2FA, (state) => ({ ...state, error: undefined, login: undefined })),
    on(AdminFromActions.on2FASuccess, (state, action) => ({ ...state, error: undefined })),
    on(AdminFromActions.on2FAFailed, (state, action) => ({ ...state, login: undefined, error: action.payload })),

    on(fromAdminReportActions.filterSalesOrders, (state) => ({ ...state })),
    on(fromAdminReportActions.onFilterSalesOrdersSuccessfully, (state, action) => ({
      ...state,
      salesOrders: action.payload,
      error: undefined,
    })),
    on(fromAdminReportActions.onFilterSalesOrdersFailed, (state, action) => ({ ...state, salesOrders: undefined, error: action.payload })),

    on(fromIssuerActions.onAddOrUpdateFeatureProduct, (state, action) => ({
        ...state,
    })),
    on(fromIssuerActions.onAddOrUpdateFeatureProductSuccessfully, (state: FeatureState, action) => {
        return { ...state, featureProduct: action.payload ,error: undefined };
    }),
    on(fromIssuerActions.onAddOrUpdateFeatureProductFailed, (state, action) => ({
        ...state, error: action.payload,
    })),

    on(fromIssuerActions.onRemoveFeatureProduct, (state, action) => ({
        ...state,
    })),
    on(fromIssuerActions.onRemoveFeatureProductSuccessfully, (state: FeatureState, action) => {
        return { ...state, featureProduct: null ,error: undefined };
    }),
    on(fromIssuerActions.onRemoveFeatureProductFailed, (state, action) => ({
        ...state, error: action.payload,
    })),

    on(fromIssuerActions.onSetCurrentIssuer, (state, action) => ({
        ...state, currentIssuer: action.payload,
    })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return adminReducer(state, action);
}
