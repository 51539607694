/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spotlight-list-componenet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./spotlight-list-componenet.component";
import * as i10 from "@modeso/twint-lib-admin-fe";
import * as i11 from "../../../shared/provider/spotlight.provider";
import * as i12 from "@angular/router";
import * as i13 from "@angular/material/dialog";
var styles_SpotlightListComponenet = [i0.styles];
var RenderType_SpotlightListComponenet = i1.ɵcrt({ encapsulation: 0, styles: styles_SpotlightListComponenet, data: {} });
export { RenderType_SpotlightListComponenet as RenderType_SpotlightListComponenet };
function View_SpotlightListComponenet_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFileUrl(_v.parent.context.$implicit.image, "de-ch"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getFileUrl(_v.parent.context.$implicit.image, "fr-ch"); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.getFileUrl(_v.parent.context.$implicit.image, "it-ch"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getFileUrl(_v.parent.context.$implicit.image, "en-us"); _ck(_v, 4, 0, currVal_3); }); }
function View_SpotlightListComponenet_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 18, "div", [["cdkDrag", ""], ["class", "item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideProduct(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Hide"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteSpotlight(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Delete"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editSpotlight(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Edit"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.image; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.spotlightName; _ck(_v, 7, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_8 = (i1.ɵnov(_v, 17).disabled || null); var currVal_9 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_8, currVal_9); }); }
function View_SpotlightListComponenet_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "item-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(2, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.visibleProductList; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = i1.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_SpotlightListComponenet_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFileUrl(_v.parent.context.$implicit.image, "de-ch"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getFileUrl(_v.parent.context.$implicit.image, "fr-ch"); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.getFileUrl(_v.parent.context.$implicit.image, "it-ch"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getFileUrl(_v.parent.context.$implicit.image, "en-us"); _ck(_v, 4, 0, currVal_3); }); }
function View_SpotlightListComponenet_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "item item__hide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showProduct(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Show"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteSpotlight(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Delete"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editSpotlight(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Edit"]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.image; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.spotlightName; _ck(_v, 2, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 9).disabled || null); var currVal_5 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 12).disabled || null); var currVal_7 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_6, currVal_7); }); }
function View_SpotlightListComponenet_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "item-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenProductList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SpotlightListComponenet_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightListComponenet_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "issuer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["mat-raised-button", ""], ["style", "margin-right: 5px;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSpotlight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Add"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "sort-order-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-raised-button", ""], ["style", "margin-right: 5px;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSortingOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Save"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSortingOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Remove Order"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visibleProductList && _co.visibleProductList.length); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.hiddenProductList && _co.hiddenProductList.length); _ck(_v, 3, 0, currVal_1); var currVal_8 = (((_co.issuer == null) ? null : _co.issuer.isDefault) || !((_co.issuer == null) ? null : _co.issuer.hasSortingOrder)); _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_SpotlightListComponenet_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spotlight-list-componenet", [], null, null, null, View_SpotlightListComponenet_0, RenderType_SpotlightListComponenet)), i1.ɵdid(1, 245760, null, 0, i9.SpotlightListComponenet, [i10.AdminIssuersProvider, i11.AdminSpotlightProvider, i12.ActivatedRoute, i12.Router, i13.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpotlightListComponenetNgFactory = i1.ɵccf("app-spotlight-list-componenet", i9.SpotlightListComponenet, View_SpotlightListComponenet_Host_0, {}, {}, []);
export { SpotlightListComponenetNgFactory as SpotlightListComponenetNgFactory };
