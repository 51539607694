import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService, AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import { IInsuranceCancelModel, IInsuranceForAnalytics, IInsurancePayment, IInsuranceStatusUpdateModel } from '../models/insurance.models';
import { InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';

import Debug from 'debug';

const debug = Debug('modeso:ihub-admin:AdminInsuranceService');

@Injectable({
    providedIn: 'root',
})
export class AdminInsuranceService {

    private server = '';
    private analyticsMicroserviceName = 'analytics';
    private readonly updateInsuranceStatusRoute = 'updatestatus';
    private readonly insurances = 'insurances';
    private readonly importInsurancesRoute = '/import';

    constructor(private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }

    getInsurances(requestParams: any) {
        let query = `?forename=${requestParams.forename}&lastname=${requestParams.lastname}`;
        if (requestParams.policy) {
            query = `?policy=${requestParams.policy}`;
        } else {
            query = `?forename=${requestParams.forename}&lastname=${requestParams.lastname}`;
        }
        // tslint:disable-next-line:max-line-length
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}${query}`;
        return this.http.get<IInsuranceForAnalytics[]>(url);
    }

    getPayments(requestParams: { payload: AnalyticsReportRequest }) {
        debug(requestParams);
         // tslint:disable-next-line:max-line-length
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}/payments/${requestParams.payload.timePeriod}/${requestParams.payload.date}`;
        return this.http.get<IInsurancePayment[]>(url);
    }

    getFailedPayments(requestParams: { payload: AnalyticsReportRequest }) {
        const {timePeriod, date} = requestParams.payload;
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}/failedPayments/${timePeriod}/${date}`;
        debug(url);
        return this.http.get<IInsurancePayment[]>(url);
    }

    updateInsuranceStatus(id: string, insuranceStatus: InsuranceStatusEnum, endDate: Date) {
      const url = `${this.server}${this.insurances}/${this.updateInsuranceStatusRoute}/${id}`;
      return this.http.put<IInsuranceStatusUpdateModel>(url, {insuranceStatus, endDate});
    }

    cancelInsurance(id: string, endDate: Date, insuranceStatus:InsuranceStatusEnum) {
        const url = `${this.server}${this.insurances}/updateEnddate/${id}`;
        return this.http.put<IInsuranceCancelModel>(url, {endDate,insuranceStatus});
    }

    importInsurances (formData: any) {
       const url = `${this.server}${this.insurances}${this.importInsurancesRoute}`;
       return this.http.post<any>(url, formData);
    }
}
