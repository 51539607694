import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginUser, AdminProvider, UserResponse, UserResponse2FA } from '@modeso/twint-lib-admin-fe';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { environment } from '../../../environments/environment';
import Debug from 'debug';
const debug = Debug('dgoods:admin:LoginPage');
@Component({
    selector: 'app-page-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePageComponent implements OnInit {
    loginForm: FormGroup;
    verify2FAForm: FormGroup;

    validLogin = false;
    invalidUser = false;
    loginErrorMessage = 'User name or password is wrong!';
    verifyErrorMessage = '2FA Code is wrong!';
    serverError = 'Server Error';
    isLoginFailed = false;
    isServerError = false;
    isBadRequest = false;
    show2FA = false;
    badRequestErrorMessage: string;
    loginWithNevisUrl = environment.modeso.apiGateway + "saml/login";
    showLoginForm = environment.allowLoginWithUsernameAndPassword;

    constructor(injector: Injector, private router: Router, private adminProvider: AdminProvider,
        private localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit() {
        this.localStorageService.removeAuthorization();
        this.loginForm = new FormGroup({
            userName: new FormControl(null, { validators: [Validators.required] }),
            password: new FormControl(null, { validators: [Validators.required] })
        });
        this.verify2FAForm = new FormGroup({
            token: new FormControl(null, { validators: [Validators.required] }),
        });

        if (!environment.allowLoginWithUsernameAndPassword) {
            window.location.href = this.loginWithNevisUrl;
        }

    }

    onLoginUSer() {
        if (this.loginForm.invalid) {
            return;
        }

        const userData: LoginUser = { name: this.loginForm.value.userName, password: this.loginForm.value.password };

        this.subscriptions.push(
            this.adminProvider.login$(userData).pipe(take(2)).subscribe((loginResponse: UserResponse | UserResponse2FA) => {

                debug(loginResponse);
                if (loginResponse && loginResponse['user']) {
                    this.isLoginFailed = false;
                    this.isServerError = false;
                    this.localStorageService.setAuthorization();
                    this.router.navigate(['/de-ch']);
                } else if (loginResponse && loginResponse['is2FAEnabled']) {
                    debug("2FA");
                    this.show2FA = true;
                } else {
                    this.adminProvider.getError$().subscribe((errorResponse: any) => {
                        if (errorResponse) {
                            if (errorResponse.status.toString() === '404') {
                                this.isLoginFailed = true;
                                this.isServerError = false;
                                this.isBadRequest = false;
                            } else if (errorResponse.status.toString() === '400') {
                                this.isLoginFailed = false;
                                this.isBadRequest = true;
                                this.isServerError = false;
                                this.badRequestErrorMessage = errorResponse.error.error;
                            } else {
                                this.isServerError = true;
                                this.isLoginFailed = false;
                                this.isBadRequest = false;
                            }

                        }
                    });
                }
            })
        );

    }

    on2FA() {
        if (this.verify2FAForm.invalid) {
            return;
        }
        this.subscriptions.push(
            this.adminProvider.verify2FA$(this.verify2FAForm.value.token).pipe(take(2)).subscribe((loginResponse: UserResponse) => {

                debug(loginResponse);
                if (loginResponse && loginResponse.user) {
                    this.isLoginFailed = false;
                    this.isServerError = false;
                    this.localStorageService.setAuthorization();
                    this.router.navigate(['/de-ch']);
                } else {
                    this.adminProvider.getError$().subscribe((errorResponse: any) => {
                        if (errorResponse) {
                            if (errorResponse.status.toString() === '404') {
                                this.isLoginFailed = true;
                                this.isServerError = false;
                                this.isBadRequest = false;
                            } else if (errorResponse.status.toString() === '400') {
                                this.isLoginFailed = false;
                                this.isBadRequest = true;
                                this.isServerError = false;
                                this.badRequestErrorMessage = errorResponse.error.error;
                            } else {
                                this.isServerError = true;
                                this.isLoginFailed = false;
                                this.isBadRequest = false;
                            }

                        }
                    });
                }
            })
        );
    }

    buttonStatus() {
        return this.loginForm.invalid;
    }


}
