import { select } from '@ngrx/store';
import Debug from 'debug';
import * as fromFeature from './../reducers/insurance.reducer';
import * as fromActions from './../actions/issuer-navigation.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
const debug = Debug('modeso:twint-lib-admin-fs NavigationIssuerProvider');
export class NavigationIssuerProvider {
    constructor(store) {
        this.store = store;
    }
    dispatchSetNavigationTab(navTab) {
        this.store.dispatch(fromActions.onNavigateToNewTab({ payload: navTab }));
    }
    getNavigationTab$() {
        return this.store.pipe(select(fromFeature.selectNavTab));
    }
}
NavigationIssuerProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationIssuerProvider_Factory() { return new NavigationIssuerProvider(i0.ɵɵinject(i1.Store)); }, token: NavigationIssuerProvider, providedIn: "root" });
