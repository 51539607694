import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-manageProductContent.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AllTranslationsResponse } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';
import { AdminManageProductContentService } from '../services/admin-manageProductContent.service';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import { ProductContentUpdate, ProductContentUpdateResponse } from '../models/manageProductContent/updateProductContent.model';
import { ProductEnforceTcUpdateResponse } from '../models/manageProductContent/updateProductEnforceTC.model';


const debug = Debug('modeso:twint-lib-admin-fe:AdminManageProductContentEffects');


@Injectable()
export class AdminManageProductContentEffects {
    constructor(private actions$: Actions, private service: AdminManageProductContentService) { }

    getAllProductContent$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getAllProductContent.type),
            mergeMap(
                () => {
                    return this.service.getAllProducts()
                        .pipe(
                            map(
                                (response: ManageProductsResponse) => {
                                    return fromActions.onGetAllProductContentSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onGetAllProductContentFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetAllProductContent$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetAllProductContentFailed.type),
            tap(
                (action: fromActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    updateContentByProductLine$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateContentByProductLine.type),
            mergeMap(
                (payload: any) => {
                    debug(payload);
                    return this.service.updateContentByProductLine(payload)
                        .pipe(
                            map(
                                (response: ProductContentUpdateResponse) => {
                                    return fromActions.onUpdateContentByProductLineSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onUpdateContentByProductLineFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnUpdateContentByProductLine$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateContentByProductLineFailed.type),
            tap(
                (action: fromActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    updateEnforceTCByProductLine$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateEnforceTcByProductLine.type),
            mergeMap(
                (payload: any) => {
                    debug(payload);
                    return this.service.updateEnforceTCByProductLine(payload)
                        .pipe(
                            map(
                                (response: ProductEnforceTcUpdateResponse) => {
                                    return fromActions.onUpdateEnforceTcByProductLineSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onUpdateEnforceTcByProductLineFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnUpdateEnforceTcByProductLine$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateEnforceTcByProductLineFailed.type),
            tap(
                (action: fromActions.IActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllCartErrors(action.payload)
            )
        ), { dispatch: false }
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}

