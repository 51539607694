/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./popUp";
var styles_PopUpComponent = [];
var RenderType_PopUpComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopUpComponent, data: {} });
export { RenderType_PopUpComponent as RenderType_PopUpComponent };
export function View_PopUpComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).dialogRef.close(i0.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(10, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(11, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dialogRef.close(i0.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(14, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(15, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Confirm"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = false; _ck(_v, 10, 0, currVal_7); var currVal_12 = true; _ck(_v, 14, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.confirmationMessage; _ck(_v, 6, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 10).ariaLabel || null); var currVal_4 = i0.ɵnov(_v, 10).type; var currVal_5 = (i0.ɵnov(_v, 11).disabled || null); var currVal_6 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i0.ɵnov(_v, 14).ariaLabel || null); var currVal_9 = i0.ɵnov(_v, 14).type; var currVal_10 = (i0.ɵnov(_v, 15).disabled || null); var currVal_11 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_PopUpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popUp-component", [], null, null, null, View_PopUpComponent_0, RenderType_PopUpComponent)), i0.ɵdid(1, 245760, null, 0, i6.PopUpComponent, [i1.MAT_DIALOG_DATA, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopUpComponentNgFactory = i0.ɵccf("app-popUp-component", i6.PopUpComponent, View_PopUpComponent_Host_0, {}, {}, []);
export { PopUpComponentNgFactory as PopUpComponentNgFactory };
