import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService, AllAvailbleProducts } from '@modeso/twint-lib-admin-fe';
import Debug from 'debug';
import {Observable} from 'rxjs';
import { IProductWithDelayedPayments } from '../models/productWithDelayedPayment.model';
const debug = Debug('modeso:ihub-admin:AdminProductService');

@Injectable({
    providedIn: 'root',
})
export class AdminProductService {

    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly getAllProductsAllLanguagesRoute = "allProductsAllLanguages";
    private readonly delayedPaymentRoute = "delayedPayment";
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }
    public getAllProductsAllLanguages(): Observable<IProductWithDelayedPayments[]>{
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.getAllProductsAllLanguagesRoute}`;
        return this.http.get<IProductWithDelayedPayments[]>(url);
    }
    public updateProductDelayedPayment(productId: string, delayedPayment: number): Observable<IProductWithDelayedPayments>{
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.delayedPaymentRoute}/${productId}`;
        return this.http.put<IProductWithDelayedPayments>(url, {delayedPayment});
    }


}