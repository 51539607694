/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/tabs";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/material/core";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/cdk/scrolling";
import * as i14 from "./navigation-tabs.component";
import * as i15 from "../../../shared/provider/navigation.provider";
var styles_NavigationTabsComponent = [i0.styles];
var RenderType_NavigationTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationTabsComponent, data: {} });
export { RenderType_NavigationTabsComponent as RenderType_NavigationTabsComponent };
function View_NavigationTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NavigationTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setNavTab(_v.context.$implicit.link) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 147456, [[1, 4]], 0, i6.MatTabLink, [i6.MatTabNav, i1.ElementRef, i1.NgZone, i7.Platform, [2, i8.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { active: [0, "active"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationTabsComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _v.context.$implicit.link; _ck(_v, 2, 0, currVal_7); var currVal_8 = (_co.activeLink == _v.context.$implicit.link); _ck(_v, 3, 0, currVal_8); var currVal_10 = !_v.context.$implicit.name; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; var currVal_2 = (i1.ɵnov(_v, 3).active ? "page" : null); var currVal_3 = i1.ɵnov(_v, 3).disabled; var currVal_4 = i1.ɵnov(_v, 3).tabIndex; var currVal_5 = i1.ɵnov(_v, 3).disabled; var currVal_6 = i1.ɵnov(_v, 3).active; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_9); }); }
function View_NavigationTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nav", [["class", "mat-tab-nav-bar mat-tab-header"], ["mat-tab-nav-bar", ""]], [[2, "mat-tab-header-pagination-controls-enabled", null], [2, "mat-tab-header-rtl", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null]], null, null, i11.View_MatTabNav_0, i11.RenderType_MatTabNav)), i1.ɵdid(1, 7520256, null, 1, i6.MatTabNav, [i1.ElementRef, [2, i12.Directionality], i1.NgZone, i1.ChangeDetectorRef, i13.ViewportRuler, [2, i7.Platform], [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _items: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavigationTabsComponent_2)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.mappedNavigation; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._showPaginationControls; var currVal_1 = (i1.ɵnov(_v, 1)._getLayoutDirection() == "rtl"); var currVal_2 = ((i1.ɵnov(_v, 1).color !== "warn") && (i1.ɵnov(_v, 1).color !== "accent")); var currVal_3 = (i1.ɵnov(_v, 1).color === "accent"); var currVal_4 = (i1.ɵnov(_v, 1).color === "warn"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_NavigationTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationTabsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mappedNavigation; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_NavigationTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-tabs", [], null, null, null, View_NavigationTabsComponent_0, RenderType_NavigationTabsComponent)), i1.ɵdid(1, 245760, null, 0, i14.NavigationTabsComponent, [i15.NavigationIssuerProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationTabsComponentNgFactory = i1.ɵccf("app-navigation-tabs", i14.NavigationTabsComponent, View_NavigationTabsComponent_Host_0, { issuers: "issuers" }, {}, []);
export { NavigationTabsComponentNgFactory as NavigationTabsComponentNgFactory };
