import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
    selector: 'app-page-productManagment',
    templateUrl: './productManagment.page.html',
    styleUrls: ['./productManagment.page.scss']
})
export class ProductManagmentPage extends BasePageComponent implements OnInit {
    background: string = '#0076bf';
    navLinks = [
        {
            label: 'Issuer Specification',
            link: 'issuers',
            index: 0
        }, {
            label: 'Product Whitelisting',
            link: 'whiteListing-products',
            index: 1
        }, {
            label: 'Closed User Group',
            link: 'closed-user-group',
            index: 2
        }, {
            label: 'Spotlight',
            link: 'spotlight',
            index: 3
        },
        {
            label: 'Discounts',
            link: 'delay-payment',
            index: 4
        }
    ];
    activeLink = this.navLinks[0].link;
    constructor(injector: Injector, private router: Router) {
        super(injector);

    }

    ngOnInit() {
        super.ngOnInit();
    }


}
