import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ICreateOrUpdateIssuer, ICreateOrUpdateSpotlight, ISortingOrder, ISpotlight } from '@modeso/types__ihub-lib-products-be';
import * as fromActions from '../actions/spotlight.actions';
import { SpotlightService } from '../services/spotlight.service';
import * as fromFeature from './../reducers/spotlight.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
const debug = Debug('ihub:spotlight:AdminSpotlightEffects ');

@Injectable()
export class AdminSpotlightEffects {

    constructor(private actions$: Actions, private service: SpotlightService, private store: Store<fromFeature.AppState>,
                private snackBar: MatSnackBar) { }

    getAllSpotlights$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetSpotlights.type),
            mergeMap(
                () => {
                    debug('Effect getAllSpotlights is Dispatched');
                    return this.service.getAllSportlight()
                        .pipe(
                            map(
                                (response) => {
                                    debug(response);
                                    return fromActions.onGetSpotlightsSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetSpotlightsFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    addSpotlight$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddSpotlight.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ICreateOrUpdateSpotlight>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateSpotlgiht(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onAddSpotlightSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => of(fromActions.onAddSpotlightFailed({payload: error}))),
                        );
                },
            ),
        ),
    );

    updateSpotlight$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateSpotlight.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ICreateOrUpdateSpotlight>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateSpotlgiht(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onUpdateSpotlightSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onUpdateSpotlightFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    deleteSpotlight$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onDeleteSpotlight.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ISpotlight>) => {
                    debug('Effect is Dispatched');
                    return this.service.deleteSpotlight(action.payload._id)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onDeleteSpotlightSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onDeleteSpotlightFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

 
    public handleOnLoadAllErrors(error) {
        // debug(error);
        // return error;
    }
}
