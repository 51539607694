import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '@modeso/twint-lib-admin-fe';
import { ISpotlight,  ICreateOrUpdateSpotlight, IIssuerAllSpotlightOrder } from '@modeso/types__ihub-lib-products-be';
import { Observable } from 'rxjs';
import Debug from 'debug';

const debug = Debug('ihub:spotlight:SpotlightService');

@Injectable({
    providedIn: 'root',
})
export class SpotlightService {

    private server = '';
    private readonly spotlightsMicroServiceName = 'spotlights/';
    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }

    public getAllSportlight() {
        debug("getAllSportlight");
        const url = `${this.server}${this.spotlightsMicroServiceName}`;
        return this.http.get<IIssuerAllSpotlightOrder[]>(url);
    }
    public addOrUpdateSpotlgiht(spotlight: ICreateOrUpdateSpotlight): Observable<ISpotlight> {
        debug("addOrUpdateSpotlgiht");
        const url = `${this.server}${this.spotlightsMicroServiceName}`;
        return this.http.post<ISpotlight>(url, spotlight);
    }

    public deleteSpotlight(spotlightId: string) {
        debug("deleteSpotlight");
        const url = `${this.server}${this.spotlightsMicroServiceName}${spotlightId}`;
        return this.http.delete<IIssuerAllSpotlightOrder[]>(url);
    }
}
