<div class="container-1" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="container-1" *ngIf="hasReadPermission()">

  <div class="upload-container">
    <h3>Import Insurances:</h3>
    <ngx-file-drop [multiple]="false" dropZoneLabel="Drop files here" (onFileDrop)="dropExcelFile($event)" >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div>
          <span style="margin-right: 15px;">Enter a valid excel format file:</span>
          <button  type="button" (click)="openFileSelector()" class="mr">Upload</button>
          <span *ngIf="fileName" class="mr">{{fileName}}</span>
          <button type="button" (click)="importInsurances()" [disabled]="disableImportSubmitBtn">Submit</button>
          <span *ngIf="error" class="error">(Only XLSX files are allowed)</span>
        </div>
        </ng-template>
    </ngx-file-drop>

    <div *ngIf="importResponse">
      <span *ngIf="importResponse && importResponse.validInsurances?.length !== 0 ;else noSuccessfulImport">
        <span class="fnt-weight">{{importResponse.validInsurances?.length}} new insurances are imported successfully</span>
      </span>
      <div>
        <ng-template #noSuccessfulImport>
          <span class="fnt-weight">No new insurances are imported</span>
        </ng-template>
      </div>
      <span *ngIf="importResponse && importResponse.duplicateInsurances?.length !== 0">
        <span class="invalid-insurances">Duplicate Insurances</span>
        <ul *ngFor="let insurance of importResponse.duplicateInsurances">
          <li>{{insurance}}</li>
        </ul>
      </span>
      <span *ngIf="importResponse && importResponse.invalidInsurances?.length !== 0">
        <span class="invalid-insurances">Invalid Insurances</span>
        <ul *ngFor="let insurance of importResponse.invalidInsurances">
          <li>{{insurance}}</li>
        </ul>
      </span>

    </div>
  </div>

  <div>
    <h3>Search For Insurances:</h3>
    <div class="row-1">
      <form [formGroup]="profileForm" (ngSubmit)="onSubmitByName()">
        <div class="form-container">
          <label for="first-name" class="mr-7">First Name: </label>
          <input id="first-name" type="text" formControlName="forename" />
        </div>
  
        <div class="form-container">
          <label for="last-name" class="mr-7">Last Name: </label>
          <input id="last-name" type="text" formControlName="lastname" />
        </div>
  
        <button type="submit" [disabled]="!profileForm.valid">Submit</button>
      </form>
    </div>
    <div class="row-1">
      <form [formGroup]="policyForm" (ngSubmit)="onSubmitByPolicy()">
        
        <div class="form-container">
          <label for="policy-number" class="mr-7">Policy Number: </label>
          <input id="policy-number" type="text" formControlName="policy" />
        </div>
    
        <button type="submit" [disabled]="!policyForm.valid">Submit</button>
      </form>
    </div>
  </div>

  <div *ngIf="dataSource.length == 0">No Data to display</div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="dataSource.length > 0">
      <ng-container matColumnDef="insuranceStatus">
        <th mat-header-cell *matHeaderCellDef>insuranceStatus</th>
        <td mat-cell *matCellDef="let element">
          {{element.insuranceStatus | getEnumKeyFromValue: InsuranceStatusEnum | titlecase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="userToken">
        <th mat-header-cell *matHeaderCellDef>userToken</th>
        <td mat-cell *matCellDef="let element">{{element.userToken}}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>createdAt</th>
        <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef>updatedAt</th>
        <td mat-cell *matCellDef="let element">{{element.updatedAt}}</td>
      </ng-container>

      <ng-container matColumnDef="policyNumber">
        <th mat-header-cell *matHeaderCellDef>policyNumber</th>
        <td mat-cell *matCellDef="let element">{{element.policyNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>endDate</th>
        <td mat-cell *matCellDef="let element">
          <ng-container> 
            <mat-form-field appearance="fill">
              <input
                matInput
                [matDatepicker]="dp3"
                [min]="element.validFrom"
                [(ngModel)]="element.endDate"
              />
              <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
              <mat-datepicker #dp3></mat-datepicker>
            </mat-form-field>
          </ng-container>
        
        </td>
      </ng-container>

      <ng-container matColumnDef="forename">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.forename}}</td>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">{{element.lastname}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <ng-container matColumnDef="birthday">
        <th mat-header-cell *matHeaderCellDef>birthday</th>
        <td mat-cell *matCellDef="let element">{{element.birthday}}</td>
      </ng-container>

      <ng-container matColumnDef="buttons" >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" >
          <ng-container *ngIf="hasWritePermission()">
            <button class="my-1 action-button" [disabled]="element.insuranceStatus === InsuranceStatusEnum.SUSPENDED || element.insuranceStatus === InsuranceStatusEnum.DRAFT" (click)="updateInsuranceStatus(element, InsuranceStatusEnum.SUSPENDED)">Suspend</button>
            <button class="my-1 action-button" [disabled]="!element.endDate || element.insuranceStatus === InsuranceStatusEnum.DRAFT" (click)="cancelInsurance(element)">Cancel</button>
            <button class="my-1 action-button" [disabled]="element.insuranceStatus !== InsuranceStatusEnum.SUSPENDED" (click)="updateInsuranceStatus(element, InsuranceStatusEnum.ACTIVE)">Activate</button>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
