import { Component, Injector, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AddOrRemoveWhitelistedUserResponseModel, AdminWhiteListingUsersProvider,
  UserResponseModel, WhitelistedUserResponseModel, WhitelistedUsersResponseModel
} from '@modeso/twint-lib-admin-fe';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
import { PopUpComponent } from '../popUp/popUp';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';

const debug = Debug('modeso:dgoods-admin:ClosedUserGroupPage');

const filterPredicate = (data: WhitelistedUserResponseModel, filter: string) => {
  if (data.customerUuid.toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  return false;
};

@Component({
  selector: 'app-page-closed-user-group',
  templateUrl: './closedUserGroup.page.html',
  styleUrls: ['./closedUserGroup.page.scss'],
})
export class ClosedUserGroupPage extends BasePageComponent implements OnInit {
  users: WhitelistedUsersResponseModel = new WhitelistedUsersResponseModel();
  dataSource: MatTableDataSource<WhitelistedUserResponseModel>;
  selection = new SelectionModel<any>();

  isError = false;
  isServerError = false;
  needsUpdates = false;

  manageColumns: string[] = ['select', 'CustomerUuid'];
  updatedNotificationMessage = 'User is updated successfully';
  serverErrorMessage = 'Server Error';
  errorMessage: string;
  filterValue = '';

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  errorhandler = (errorResponse: HttpErrorResponse) => {
    if (errorResponse === undefined) {
      return;
    }
    if (errorResponse.status === 404) {
      this.isServerError = false;
      this.isError = true;
      this.errorMessage = errorResponse.error.message;
    } else {
      this.isError = false;
      this.isServerError = true;
    }
  }

  constructor(injector: Injector, private provider: AdminWhiteListingUsersProvider, public dialog: MatDialog,
    private _snackBar: MatSnackBar, private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService) {
    super(injector);
  }

  ngOnInit() {
    this.initWhitelistUsers();
    this.initErrors();
    this.initRemoveWhitelistedUser();
    this.getWhitelistedUsers();
    super.ngOnInit();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.filterValue = filterValue;
  }

  initWhitelistUsers() {
    this.subscriptions.push(
      this.provider.getWhitelisteUsers$().pipe().subscribe((response: WhitelistedUsersResponseModel) => {

        this.isError = false;
        this.isServerError = false;

        this.users = response;

        this.dataSource = new MatTableDataSource<WhitelistedUserResponseModel>(this.users);
        this.dataSource.filterPredicate = filterPredicate;
        this.changeDetector.detectChanges();
        this.dataSource.paginator = this.paginator;


      })
    );


  }

  initErrors() {
    this.subscriptions.push(
      this.provider.getError$().subscribe(this.errorhandler)
    );
  }

  getWhitelistedUsers() {
    this.provider.requestWhitelistedUsers$();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

  selectedRow() {
    if (!this.selection.selected[0]) {
      return null;
    }
    return this.selection.selected[0];
  }


  updateButtonStatus() {
    return !this.selectedRow();
  }

  initRemoveWhitelistedUser() {
    this.subscriptions.push(
      this.provider.getAddOrRemoveUserFromWhitelistResponse$().pipe().subscribe((response: AddOrRemoveWhitelistedUserResponseModel) => {

        debug('getAddOrRemoveUserFromWhitelistResponse');
        this.isError = false;
        this.isServerError = false;

        if (response && response.message) {

          if (response.action === 'DELETE') {
            this.users = this.users.filter((user) => {
              return user.customerUuid !== response.customerUuid;
            });
          }
          if (response.action === 'ADD') {
            const newUser = new WhitelistedUserResponseModel(response.customerUuid);
            this.users.push(newUser);
          }


          this.dataSource = new MatTableDataSource<WhitelistedUserResponseModel>(this.users);
          this.dataSource.filterPredicate = filterPredicate;
          this.changeDetector.detectChanges();
          this.dataSource.paginator = this.paginator;

          this._snackBar.open(response.message, null, {
            duration: 2000,
            panelClass: ['blue-snackbar']
          });
        }

      })
    );

  }

  onRemoveWhitelistedUser() {

    const selectedUser: WhitelistedUserResponseModel = this.selectedRow();

    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Remove User from Closed User Group',
        confirmationMessage: 'Are you sure you want to remove user with id ' + selectedUser.customerUuid + ' from the Closed User Group?',
      },
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.provider.removeUserFromWhitelist$(selectedUser.customerUuid);
      }
    }));
  }

  onAddToWhitelist(selectedUser: UserResponseModel) {

    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Add User to Closed User Group',
        confirmationMessage: 'Are you sure you want to add user with id ' + selectedUser.userToken + ' to the Closed User Group?',
      },
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        debug('send add User To Whitelist');
        this.provider.addUserToWhitelist$(selectedUser.userToken);
      }
    }));
  }

  hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.DIGITAL_VAUCHERS_ADMIN,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
      UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
      UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  hasWritePermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.DIGITAL_VAUCHERS_ADMIN,
      UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
      UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }
}
