import { HttpErrorResponse } from '@angular/common/http';
import { ICreateOrUpdateSpotlight, IIssuerAllSpotlightOrder, ISpotlight } from '@modeso/types__ihub-lib-products-be';
import { Action, createAction, props } from '@ngrx/store';

export const onGetSpotlights = createAction(
    '[Admin Spotlights] onGetAllSpotlight',
);
export const onGetSpotlightsSuccessfully = createAction(
    '[Admin Spotlights] onGetSpotlightsSuccessfully',
    props<{ payload: IIssuerAllSpotlightOrder[] }>()
);

export const onGetSpotlightsFailed = createAction(
    '[Admin Spotlights] onGetSpotlightsFailed',
    props<{ payload: HttpErrorResponse }>()
);

export const onSelectSpotlight = createAction(
    '[Admin Spotlights] onSelectSpotlight',
    props<{ payload?: string }>()
);


export const onAddSpotlight = createAction(
    '[Admin Spotlights] onAddSpotlight',
    props<{ payload: ICreateOrUpdateSpotlight }>()
);

export const onAddSpotlightSuccessfully = createAction(
    '[Admin Spotlights] onAddSpotlight Successfully',
    props<{ payload: ISpotlight }>()
);

export const onAddSpotlightFailed = createAction(
    '[Admin Spotlights] onAddSpotlight Failed',
    props<{ payload: HttpErrorResponse }>()
);

export const onUpdateSpotlight = createAction(
    '[Admin Spotlights] onUpdateSpotlight',
    props<{ payload: ICreateOrUpdateSpotlight }>()
);

export const onUpdateSpotlightSuccessfully = createAction(
    '[Admin Spotlights] onUpdateSpotlight Successfully',
    props<{ payload: ISpotlight }>()
);

export const onUpdateSpotlightFailed = createAction(
    '[Admin Spotlights] onUpdateSpotlight Failed',
    props<{ payload: HttpErrorResponse }>()
);


export const onDeleteSpotlight = createAction(
    '[Admin Spotlights] onDeleteSpotlight',
    props<{ payload: ISpotlight }>()
);

export const onDeleteSpotlightSuccessfully = createAction(
    '[Admin Spotlights] onDeleteSpotlight Successfully',
    props<{ payload: IIssuerAllSpotlightOrder[] }>()
);

export const onDeleteSpotlightFailed = createAction(
    '[Admin Spotlights] onDeleteSpotlight Failed',
    props<{ payload: HttpErrorResponse }>()
);


export interface ActionWithPayload<T> extends Action {
    payload: T;
}
