<div cdkDropList class="item-list" (cdkDropListDropped)="drop($event)" *ngIf="visibleProductList && visibleProductList.length">
    <div class="item" *ngFor="let product of visibleProductList" cdkDrag>
        <span style="width: 30%"> {{product.productTitle }} - {{product.productId }}</span>
        <button mat-button *ngIf="product.productId === featureProduct" (click)="setFeatureProduct(product.productId)"><mat-icon>star</mat-icon></button>
        <button mat-button *ngIf="product.productId !== featureProduct || featureProduct === null" (click)="setFeatureProduct(product.productId)"><mat-icon>star_border</mat-icon></button>
        <button mat-button (click)="hideProduct(product)">Hide</button>
    </div>
</div>

<div class="item-list" *ngIf="hiddenProductList && hiddenProductList.length">
    <div class="item item__hide" *ngFor="let product of hiddenProductList">
        <span> {{product.productTitle}} - {{product.productId }}</span>  
        <button mat-button (click)="showProduct(product)">Show</button>
  </div> 
</div>

<div class="action-buttons">
    <div class="issuer-actions">
        <button mat-raised-button (click)="editIssuer()">Edit Issuer</button>
    </div>
    <div class="sort-order-actions">
        <button mat-raised-button style="margin-right: 5px;" (click)="updateSortingOrder()">Save</button>
        <button mat-raised-button  (click)="removeSortingOrder()" [disabled]="issuer?.isDefault || !issuer?.hasSortingOrder">Remove Order</button>
    </div>
</div>