import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ICreateOrUpdateIssuer, IIssuerAllProductsOrder, IIssuer, ISortingOrder } from '@modeso/types__ihub-lib-products-be';
import { Observable } from 'rxjs';
import { AdminConfigService } from '../admin.config';

@Injectable({
    providedIn: 'root',
})
export class AdminIssuerService {

    private server = '';
    private readonly productMicroServiceName = 'products/';
    private readonly issuersRoute = 'issuers';
    private readonly sortingOrdersRoute = 'sortingOrders';
    private readonly featureProductRoute = 'feature';
    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient, @Inject(AdminConfigService) private config) {
        this.server = config.apiGateway;
    }

    public getAllIssuers() {
        const url = `${this.server}${this.issuersRoute}`;
        return this.http.get<IIssuerAllProductsOrder[]>(url);
    }
    public addOrUpdateIssuer(issuer: ICreateOrUpdateIssuer): Observable<IIssuer> {
        const url = `${this.server}${this.productMicroServiceName}${this.issuersRoute}`;
        return this.http.post<IIssuer>(url, issuer);
    }

    public addOrUpdateSortingOrder(issuer: ISortingOrder): Observable<IIssuerAllProductsOrder> {
       const url = `${this.server}${this.productMicroServiceName}${this.sortingOrdersRoute}`;
       return this.http.post<IIssuerAllProductsOrder>(url, issuer);
    }

    public removeSortingOrderOfIssuer(data: any) {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: {
              type:data.type
            },
          };
        const url = `${this.server}${this.productMicroServiceName}${this.sortingOrdersRoute}/${data.issuerId}`;
        return this.http.delete<ISortingOrder>(url,options);
    }

    public addOrUpdateFeatureProduct(issuer: ISortingOrder): Observable<string> {
        const url = `${this.server}${this.productMicroServiceName}${this.featureProductRoute}`;
        return this.http.post<string>(url, issuer);
    }
}
