import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../shared/services/localStorage.service";
const debug = Debug('dgoods:admin:SessionOrchestrator');
export class SessionOrchestrator {
    constructor(router, localStorageService) {
        this.router = router;
        this.localStorageService = localStorageService;
    }
    onSessionExpired() {
        debug(this.router.url);
        if (!this.router.isActive('de-ch/login', false)) {
            debug('onSessionExired redirect to login');
            this.localStorageService.clear();
            this.router.navigateByUrl('de-ch/login');
        }
    }
}
SessionOrchestrator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionOrchestrator_Factory() { return new SessionOrchestrator(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocalStorageService)); }, token: SessionOrchestrator, providedIn: "root" });
