import { HttpErrorResponse } from "@angular/common/http";
import { AllAvailbleProducts } from "@modeso/twint-lib-admin-fe";
import { Action, createAction, props } from "@ngrx/store";
import { IProductWithDelayedPayments } from "../models/productWithDelayedPayment.model";

export const getAllProductsInAllLanguages = createAction(
    '[Admin WhiteListing Products] get all products in all languages',
);
export const getAllProductsInAllLanguagesSuccessfully = createAction(
    '[Admin WhiteListing Products] get all products in all languages successfully',
    props<{ payload: IProductWithDelayedPayments[] }>(),
);
export const getAllProductsInAllLanguagesFailed = createAction(
    '[Admin WhiteListing Products] get all products in all languages failed',
    props<{ payload: any }>(),
);

export const updateDelayedPayments = createAction(
    '[Update Delayed Payments] update Delayed Payment',
    props<{ payload: { productId: string, delayedPayment: number} }>(),
);
export const updateDelayedPaymentsSuccessfully = createAction(
    '[Update Delayed Payments] update Delayed Payment successfully',
    props<{ payload: IProductWithDelayedPayments }>(),
);
export const updateDelayedPaymentsFailed = createAction(
    '[Update Delayed Payments] update Delayed Payment failed',
    props<{ payload: any }>(),
);


export interface ActionWithPayload<T> extends Action {
    payload: T;
}