import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import { createAction, props, Action } from '@ngrx/store';
import { IInsuranceCancelModel, IInsuranceForAnalytics, IInsurancePayment, IInsuranceStatusUpdateModel } from '../models/insurance.models';

export const getInsurances = createAction('[Admin Insurances] getInsurances',
    props<{ payload: any }>());

export const onGetInsurancesSuccessfully = createAction(
    '[Admin Insurances] onGetInsurancesSuccessfully',
    props<{ payload: IInsuranceForAnalytics[] }>()
);

export const onGetInsurancesFailed = createAction(
    '[Admin Insurances] onGetInsurancesFailed',
    props<{ payload: HttpErrorResponse }>()
);


export const getPayments = createAction('[Admin Insurances] getPayments',
    props<{ payload: AnalyticsReportRequest }>());

export const onGetPaymentsSuccessfully = createAction(
    '[Admin Insurances] onGetPaymentsSuccessfully',
    props<{ payload: IInsurancePayment[] }>()
);

export const onGetPaymentsFailed = createAction(
    '[Admin Insurances] onGetPaymentsFailed',
    props<{ payload: HttpErrorResponse }>()
);


export const updateInsuranceStatus = createAction('[Admin Insurances] updateInsuranceStatus',
    props<{ payload: IInsuranceStatusUpdateModel }>());

export const onUpdateInsuranceStatusSuccessfully = createAction(
    '[Admin Insurances] onUpdateInsuranceStatusSuccessfully',
    props<{ payload: IInsuranceStatusUpdateModel }>()
);

export const onUpdateInsuranceStatusFailed = createAction(
    '[Admin Insurances] onUpdateInsuranceStatusFailed',
    props<{ payload: HttpErrorResponse }>()
);

export const getFailedPayments = createAction('[Admin Insurances] getFailedPayments',
    props<{ payload: AnalyticsReportRequest }>());

export const onGetFailedPaymentsSuccessfully = createAction(
    '[Admin Insurances] onGetFailedPaymentsSuccessfully',
    props<{ payload: IInsurancePayment[] }>()
);

export const onGetFailedPaymentsFailed = createAction(
    '[Admin Insurances] onGetFailedPaymentsFailed',
    props<{ payload: HttpErrorResponse }>()
);
export const cancelInusrance = createAction('[Admin Insurances] cancelInusrance',
    props<{ payload: IInsuranceCancelModel }>());

export const onCancelInusranceSuccessfully = createAction(
    '[Admin Insurances] onCancelInusranceSuccessfully',
    props<{ payload: IInsuranceCancelModel }>()
);

export const onCancelInusranceFailed = createAction(
    '[Admin Insurances] onCancelInusranceFailed',
    props<{ payload: HttpErrorResponse }>()
);

export const importInusrances = createAction('[Admin Insurances] importInusrances',
    props<{ payload: any }>());

export const onImportInusrancesSuccessfully = createAction(
    '[Admin Insurances] onImportInusrancesSuccessfully',
    props<{ payload: any }>()
);

export const onImportInusrancesFailed = createAction(
    '[Admin Insurances] onImportInusrancesFailed',
    props<{ payload: HttpErrorResponse }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}