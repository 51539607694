import * as fromFeature from './../reducers/insurance.reducer';
import { select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromActions from './../actions/insurance.actions';
import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
const debug = Debug('modeso:twint-lib-admin-fe:AdminInsuranceProvider');
export class AdminInsuranceProvider {
    constructor(store) {
        this.store = store;
    }
    getInsurance$() {
        return this.store.pipe(select(fromFeature.featureInsurances));
    }
    fetchInsurancesByName(filter1) {
        this.store.dispatch(fromActions.getInsurances({ payload: filter1 }));
    }
    fetchInsurancesByPolicy(filter1) {
        this.store.dispatch(fromActions.getInsurances({ payload: filter1 }));
    }
    fetchPayments(filter1) {
        this.store.dispatch(fromActions.getPayments({ payload: filter1 }));
    }
    getPayments$() {
        return this.store.pipe(select(fromFeature.featurePayments));
    }
    updateInsuranceStatus(id, endDate, insuranceStatus) {
        this.store.dispatch(fromActions.updateInsuranceStatus({ payload: { id, endDate, insuranceStatus } }));
    }
    dispatchGetFailedPayments(filter1) {
        this.store.dispatch(fromActions.getFailedPayments({ payload: filter1 }));
    }
    getFailedPayments$() {
        return this.store.pipe(select(fromFeature.featureFailedPayments));
    }
    dispatchCancelInsurance(id, endDate, insuranceStatus) {
        this.store.dispatch(fromActions.cancelInusrance({ payload: { id, endDate, insuranceStatus } }));
    }
    importInsurances(body) {
        this.store.dispatch(fromActions.importInusrances({ payload: body }));
        return this.store.pipe(select(fromFeature.selectImportInsurance));
    }
    getError$() {
        return this.store.pipe(select(fromFeature.featureError), filter((error) => error != null));
    }
}
AdminInsuranceProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminInsuranceProvider_Factory() { return new AdminInsuranceProvider(i0.ɵɵinject(i1.Store)); }, token: AdminInsuranceProvider, providedIn: "root" });
