import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { fromBuffer } from 'file-type/core';
import Debug from 'debug';
const debug = Debug('ihub:spotlight:AddImageDialog');
export class AddImageDialog {
    constructor(dialogRef, data, uploadService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.uploadService = uploadService;
        this.uploadedImages = [];
        this.images = new Array(4);
        this.isUploadFinished = false;
        this.fileReader = [];
        this.isEdit = false;
        this.editedImages = [];
        this.errors = [];
        this.languages = ['de-ch', 'fr-ch', 'it-ch', 'en-us'];
        dialogRef.disableClose = true;
        if (data) {
            debug(data);
            this.isEdit = true;
            this.uploadedImages.push(...data);
            data.map(image => this.fileReader.push({ url: image.url }));
        }
    }
    dropped(files, index) {
        for (const droppedFile of files) {
            const fileEntry = droppedFile.fileEntry;
            fileEntry.file((file) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.isFileSizeAllowed(file.size) || !(yield this.validateFile(file))) {
                    this.errors[index] = true;
                    return;
                }
                const fileUuid = uuidv4();
                const fileName = fileUuid + '.' + droppedFile.relativePath.split('.').pop();
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.uploadedImages[index] = {
                        url: reader.result,
                        language: this.languages[index],
                        file,
                        fileName,
                    };
                    this.fileReader[index] = {
                        url: reader.result
                    };
                    if (this.isEdit) {
                        this.editedImages.push({
                            file,
                            fileName,
                            index
                        });
                    }
                };
            }));
        }
    }
    removeFile(index) {
        this.errors[index] = undefined;
        this.uploadedImages[index] = undefined;
        this.fileReader[index] = undefined;
    }
    isValid() {
        const filteredImages = this.uploadedImages.filter((image) => {
            return image !== undefined;
        });
        return filteredImages.length === 4 && !this.errors.includes(true) ? true : false;
    }
    isFileSizeAllowed(size) {
        if (size < 15000000) {
            return true;
        }
        return false;
    }
    onNoClick() {
        this.dialogRef.close();
    }
    save() {
        if (this.isEdit) {
            if (this.editedImages.length > 0) {
                for (const [index, image] of this.editedImages.entries()) {
                    this.isUploadFinished = true;
                    this.uploadService.getUploadUrl(image.fileName)
                        .subscribe((urlObj) => {
                        this.uploadedImages[image.index].url = urlObj.fetchUrl;
                        this.uploadService.uploadFile(urlObj.uploadUrl, this.renameFile(image.file, image.fileName), urlObj.contentType)
                            .subscribe((response) => {
                            delete image.file;
                            delete image.fileName;
                            if (index === this.editedImages.length - 1) {
                                this.isUploadFinished = false;
                                this.dialogRef.close(this.uploadedImages);
                            }
                        });
                    });
                }
            }
            else {
                this.dialogRef.close(this.uploadedImages);
            }
        }
        else {
            this.isUploadFinished = true;
            for (const [index, image] of this.uploadedImages.entries()) {
                this.uploadService.getUploadUrl(image.fileName)
                    .subscribe((urlObj) => {
                    image['url'] = urlObj.fetchUrl;
                    this.uploadService.uploadFile(urlObj.uploadUrl, this.renameFile(image.file, image.fileName), urlObj.contentType).subscribe((response) => {
                        if (index === this.uploadedImages.length - 1) {
                            this.isUploadFinished = false;
                            this.dialogRef.close(this.uploadedImages);
                        }
                    });
                    delete image.file;
                    delete image.fileName;
                });
            }
        }
    }
    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    validateFile(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const realFileType = yield this.getTrueFileType(file);
            if (!realFileType) {
                return false;
            }
            if (realFileType.mime === 'image/jpeg' ||
                realFileType.mime === 'image/png') {
                return true;
            }
            return false;
        });
    }
    getTrueFileType(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return file.arrayBuffer().then(buffer => fromBuffer(buffer));
        });
    }
}
