export class ManageProductsResponse {
    public products: ManagedProduct[];
    public action: string;
    
    constructor() {
        this.products = new Array<ManagedProduct>();
    }
}

export class ManagedProduct {
    public productLineName: string;
    public content: ProductContent[];
    public enforceTC: boolean;
}
export class ProductContent {
    public defaultDescription: string;
    public twintDescription: string;
    public locale: string;
}
