import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../actions/admin.whiteListingProducts.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { AllAvailbleProducts, AllProductsAvailablePricesModel } from '../models/whiteListingProducts/getAllProductsResponse.model';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';
const debug = Debug('modeso:twint-lib-admin-fe:AdminWhiteListingProductsProvider');
@Injectable({
    providedIn: 'root',
})
export class AdminWhiteListingProductsProvider {

    constructor(
        private store: Store<fromFeature.AppState>) { }

    public getAllProductsInAllLanguages$(): Observable<AllAvailbleProducts[]> {
        this.store.dispatch(fromActions.getAllProductsInAllLanguages());
        return this.store.pipe(select(fromFeature.selectFeatureGetAllProductsAllLanguages), map((allProductsAllLanguages: AllAvailbleProducts[]) => {
            if (allProductsAllLanguages) {
                return allProductsAllLanguages;
            } else {
                debug('get all products in all languages response is undefined');

            }
        }));
    }
    public whiteListProduct$(productLineName: string): Observable<WhiteListingResponseModel> {
        this.store.dispatch(fromActions.whiteListProduct({ payload: productLineName }));
        return this.store.pipe(select(fromFeature.selectFeatureWhiteListResponse), map((whiteListResponse: WhiteListingResponseModel) => {
            if (whiteListResponse) {
                return whiteListResponse;
            } else {
                debug('whiteList response is undefined');

            }
        }));
    }
    public unWhiteListProduct$(productLineName: string): Observable<WhiteListingResponseModel> {
        this.store.dispatch(fromActions.unWhiteListProduct({ payload: productLineName }));
        return this.store.pipe(select(fromFeature.selectFeatureWhiteListResponse), map((unWhiteListResponse: WhiteListingResponseModel) => {
            if (unWhiteListResponse) {
                return unWhiteListResponse;
            } else {
                debug('unWhiteList response is undefined');
            }
        }));
    }

    public synchronizeProducts$(): Observable<string> {
        this.store.dispatch(fromActions.synchronizeProducts());
        return this.store.pipe(select(fromFeature.selectFeatureSynchronizeProductsResponse), map((response: string) => {
            if (response) {
                return response;
            } else {
                debug('unWhiteList response is undefined');
            }
        }));
    }

    public getError$(): Observable<any> {
        return this.store.pipe(select(fromFeature.featureError),
            map((error: any) => {
                return error;
            })
        );
    }

}
