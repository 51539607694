import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@modeso/twint-lib-admin-fe";
const debug = Debug('modeso:ihub-admin:AdminProductService');
export class AdminProductService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.server = '';
        this.productMicroServiceName = 'products';
        this.productsRoute = '/';
        this.getAllProductsAllLanguagesRoute = "allProductsAllLanguages";
        this.delayedPaymentRoute = "delayedPayment";
        this.server = config.apiGateway;
    }
    getAllProductsAllLanguages() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.getAllProductsAllLanguagesRoute}`;
        return this.http.get(url);
    }
    updateProductDelayedPayment(productId, delayedPayment) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.delayedPaymentRoute}/${productId}`;
        return this.http.put(url, { delayedPayment });
    }
}
AdminProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminProductService_Factory() { return new AdminProductService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AdminConfigService)); }, token: AdminProductService, providedIn: "root" });
