/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-list-componenet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/cdk/drag-drop";
import * as i9 from "@angular/common";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "./product-list-componenet.component";
import * as i12 from "@modeso/twint-lib-admin-fe";
import * as i13 from "../../../shared/provider/spotlight.provider";
import * as i14 from "@angular/router";
import * as i15 from "@angular/material/dialog";
var styles_ProductListComponenet = [i0.styles];
var RenderType_ProductListComponenet = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductListComponenet, data: {} });
export { RenderType_ProductListComponenet as RenderType_ProductListComponenet };
function View_ProductListComponenet_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setFeatureProduct(_v.parent.context.$implicit.productId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["star"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_ProductListComponenet_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setFeatureProduct(_v.parent.context.$implicit.productId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["star_border"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_ProductListComponenet_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 14, "div", [["cdkDrag", ""], ["class", "item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i8.ɵb, null, [i8.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i8.CdkDrag, [i1.ElementRef, [3, i8.CDK_DROP_LIST], i9.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i8.CDK_DRAG_CONFIG, [2, i10.Directionality], i8.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["style", "width: 30%"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " - ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_3)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_4)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideProduct(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Hide"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_v.context.$implicit.productId === _co.featureProduct); _ck(_v, 9, 0, currVal_4); var currVal_5 = ((_v.context.$implicit.productId !== _co.featureProduct) || (_co.featureProduct === null)); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.productTitle; var currVal_3 = _v.context.$implicit.productId; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_6 = (i1.ɵnov(_v, 13).disabled || null); var currVal_7 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_6, currVal_7); }); }
function View_ProductListComponenet_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "item-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i8.CDK_DROP_LIST, null, [i8.CdkDropList]), i1.ɵdid(2, 1196032, null, 1, i8.CdkDropList, [i1.ElementRef, i8.DragDrop, i1.ChangeDetectorRef, [2, i10.Directionality], [3, i8.CdkDropListGroup]], null, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i8.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_2)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.visibleProductList; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = i1.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i1.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ProductListComponenet_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "item item__hide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " - ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showProduct(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Show"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.productTitle; var currVal_1 = _v.context.$implicit.productId; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_ProductListComponenet_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "item-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_6)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenProductList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProductListComponenet_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductListComponenet_5)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "issuer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editIssuer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Edit Issuer"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "sort-order-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-raised-button", ""], ["style", "margin-right: 5px;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSortingOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Save"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSortingOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Remove Order"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visibleProductList && _co.visibleProductList.length); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.hiddenProductList && _co.hiddenProductList.length); _ck(_v, 3, 0, currVal_1); var currVal_8 = (((_co.issuer == null) ? null : _co.issuer.isDefault) || !((_co.issuer == null) ? null : _co.issuer.hasSortingOrder)); _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_ProductListComponenet_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-list-componenet", [], null, null, null, View_ProductListComponenet_0, RenderType_ProductListComponenet)), i1.ɵdid(1, 245760, null, 0, i11.ProductListComponenet, [i12.AdminIssuersProvider, i13.AdminSpotlightProvider, i14.ActivatedRoute, i14.Router, i15.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductListComponenetNgFactory = i1.ɵccf("app-product-list-componenet", i11.ProductListComponenet, View_ProductListComponenet_Host_0, {}, {}, []);
export { ProductListComponenetNgFactory as ProductListComponenetNgFactory };
