<div class="container">
  <form class="login-form" [formGroup]="loginForm" *ngIf="showLoginForm && !show2FA">
    <div class="form-group">
      <div class="input-wrapper">
        <input
          id="userName"
          class="form-control"
          type="text"
          formControlName="userName"
          placeholder="User Name"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="input-wrapper">
        <input
          id="password"
          class="form-control"
          type="password"
          formControlName="password"
          placeholder="Password"
        />
      </div>
    </div>
    <div class="row errorMessages">
      <span class="unauthorized" *ngIf="isLoginFailed"
        >{{loginErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isBadRequest"
        >{{badRequestErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isServerError">{{serverError}}</span>
    </div>

    <div class="login-button">
      <button
        id="saveAddressButton"
        [disabled]="buttonStatus()"
        (click)="onLoginUSer()"
      >
        Login
      </button>
    </div>
  </form>
  <form class="2fa-form" [formGroup]="verify2FAForm" *ngIf="show2FA">
    
    <div class="form-group">
      <div class="input-wrapper">
        <input
          id="token"
          class="form-control"
          formControlName="token"
          placeholder="Token"
        />
      </div>
    </div>
    <div class="row errorMessages">
      <span class="unauthorized" *ngIf="isLoginFailed"
        >{{verifyErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isBadRequest"
        >{{badRequestErrorMessage}}</span
      >
      <span class="unauthorized" *ngIf="isServerError">{{serverError}}</span>
    </div>

    <div class="2fa-button">
      <button
        [disabled]="verify2FAForm.invalid"
        (click)="on2FA()"
      >
        Verify
      </button>
    </div>
  </form>
  <div class="login-button">
    <button><a href="{{loginWithNevisUrl}}">Login With Nevis</a></button>
  </div>
</div>
