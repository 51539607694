import { Component, Injector, OnInit } from '@angular/core';
import { AdminIssuersProvider } from '@modeso/twint-lib-admin-fe';
import { Router } from '@angular/router';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { IssuerManagmentPage } from './issuer-managment.page';
const debug = Debug('ihub:admin:IssuerManagmentPage');
export interface INavigationItem {
  link: string;
  name: string;
}
@Component({
  selector: 'app-spotlight-managment',
  templateUrl: './issuer-managment.page.html',
  styleUrls: ['./issuer-managment.page.scss']
})
export class SpotlightManagmentPage extends IssuerManagmentPage implements OnInit {

 
  protected getNavigationLink(issuerId: string) {
    return `/de-ch/product-managment/spotlight/${issuerId}`;
  }

  protected addNewIssuerLink() {
    this.issuers.push({
      link: 'addIssuer',
      name: '',
    });
  }

  ngOnInit() {
  }
}
