import { AnalyticsReportRequest } from '@modeso/twint-lib-admin-fe';
import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@modeso/twint-lib-admin-fe";
const debug = Debug('modeso:ihub-admin:AdminInsuranceService');
export class AdminInsuranceService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.server = '';
        this.analyticsMicroserviceName = 'analytics';
        this.updateInsuranceStatusRoute = 'updatestatus';
        this.insurances = 'insurances';
        this.importInsurancesRoute = '/import';
        this.server = config.apiGateway;
    }
    getInsurances(requestParams) {
        let query = `?forename=${requestParams.forename}&lastname=${requestParams.lastname}`;
        if (requestParams.policy) {
            query = `?policy=${requestParams.policy}`;
        }
        else {
            query = `?forename=${requestParams.forename}&lastname=${requestParams.lastname}`;
        }
        // tslint:disable-next-line:max-line-length
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}${query}`;
        return this.http.get(url);
    }
    getPayments(requestParams) {
        debug(requestParams);
        // tslint:disable-next-line:max-line-length
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}/payments/${requestParams.payload.timePeriod}/${requestParams.payload.date}`;
        return this.http.get(url);
    }
    getFailedPayments(requestParams) {
        const { timePeriod, date } = requestParams.payload;
        const url = `${this.server}${this.analyticsMicroserviceName}/${this.insurances}/failedPayments/${timePeriod}/${date}`;
        debug(url);
        return this.http.get(url);
    }
    updateInsuranceStatus(id, insuranceStatus, endDate) {
        const url = `${this.server}${this.insurances}/${this.updateInsuranceStatusRoute}/${id}`;
        return this.http.put(url, { insuranceStatus, endDate });
    }
    cancelInsurance(id, endDate, insuranceStatus) {
        const url = `${this.server}${this.insurances}/updateEnddate/${id}`;
        return this.http.put(url, { endDate, insuranceStatus });
    }
    importInsurances(formData) {
        const url = `${this.server}${this.insurances}${this.importInsurancesRoute}`;
        return this.http.post(url, formData);
    }
}
AdminInsuranceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminInsuranceService_Factory() { return new AdminInsuranceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AdminConfigService)); }, token: AdminInsuranceService, providedIn: "root" });
