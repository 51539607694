import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AdminIssuersProvider } from '@modeso/twint-lib-admin-fe';
import { ISortingOrder } from '@modeso/types__ihub-lib-products-be';
@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { issuerId: string}, private adminProvider: AdminIssuersProvider) {

  }
  ngOnInit() {
  }

  confirmDeletion() {
    this.adminProvider.removeSortingOrderOfIssuer(this.data);
  }

}
