import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from '../actions/admin.whiteListingUsers.actions';
import { UserSearchRequestModel } from '../models/whiteListUsers/userSearch.model';
import { AdminWhiteListingUsersService } from '../services/admin.whiteListingUsers.service';
import * as fromFeature from './../reducers/admin.reducer';
const debug = Debug('modeso:twint-lib-admin-fe:AdminWhiteListingUsersEffects ');

@Injectable()
export class AdminWhiteListingUsersEffects {

    constructor(private actions$: Actions,
        private service: AdminWhiteListingUsersService,
        private store: Store<fromFeature.AppState>) { }

    getWhitelistedUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getWhitelistedUsers.type),
            mergeMap(
                () => {
                    return this.service.getWhitelisteUsers()
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onGetWhitelistedUsersSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetWhitelistedUsersFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnGetWhitelisteUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetWhitelistedUsersFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    searchUser$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.searchUser.type),
            mergeMap(
                (payload: { payload: UserSearchRequestModel }) => {
                    return this.service.searchUser(payload.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onSearchUserSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onSearchUserFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnSearchUser$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onSearchUserFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    addUserToWhitelist$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.addUserToWhitelist.type),
            mergeMap(
                (payload: { payload: string }) => {
                    return this.service.addUserToWhitelist(payload.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onAddUserToWhitelistSuccessfully({ payload: response });
                                },
                            ),
                            tap(
                                () => {
                                    setTimeout(() => {
                                        debug('cleanAddOrDeleteToUserWhitelistResponse');
                                        this.store.dispatch(fromActions.cleanAddOrDeleteToUserWhitelistResponse());
                                    }, 1000);
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onAddUserToWhitelistFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnAddUserToWhitelist$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddUserToWhitelistFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    removeUserFromWhitelist$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.removeUserFromWhitelist.type),
            mergeMap(
                (payload: { payload: string }) => {
                    return this.service.removeUserFromWhitelist(payload.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onRemoveUserFromWhitelistSuccessfully({ payload: response });
                                },
                            ),
                            tap(
                                () => {
                                    setTimeout(() => {
                                        debug('cleanAddOrDeleteToUserWhitelistResponse');
                                        this.store.dispatch(fromActions.cleanAddOrDeleteToUserWhitelistResponse());
                                    }, 1000);
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onRemoveUserFromWhitelistFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    errorOnRemoveUserFromWhitelist$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddUserToWhitelistFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
            ),
        ), { dispatch: false },
    );

    public handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }
}
