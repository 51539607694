import { Component, OnDestroy, OnInit } from '@angular/core';
import {  IIssuerAllSpotlightOrder, ISpotlight, MultiLanguagesString } from '@modeso/types__ihub-lib-products-be';
import {Subscription} from 'rxjs';
import Debug from 'debug';
import { ProductListComponenet } from '../product-list-componenet/product-list-componenet.component';
import { PopUpComponent } from '../../popUp/popUp';
const debug = Debug('ihub:admin:SpotlightListComponenet');
@Component({
  selector: 'app-spotlight-list-componenet',
  templateUrl: './spotlight-list-componenet.component.html',
  styleUrls: ['./spotlight-list-componenet.component.scss']
})
export class SpotlightListComponenet extends ProductListComponenet implements OnInit, OnDestroy {
  protected issuerSpotlights: IIssuerAllSpotlightOrder;
  selectedIssuer: any;
  protected subscribeToList(param): Subscription {

    debug('SpotlightListComponenet', param['issuer']);

    this.selectedIssuer = param['issuer'];
    this.spotlightProvider.dispatchGetAllSpotlights();
    return this.spotlightProvider.getSpotlightOrders$().subscribe((value: IIssuerAllSpotlightOrder[]) => {
      debug('getSpotlightOrders response', value);
      if (!value) {
        this.visibleProductList = [{
          spotlightName: 'none',
        }];
        return;
      }
      if (value.length === 0) {
        this.visibleProductList = [{
          spotlightName: 'none',
        }];
      } else {
        this.issuerSpotlights = value.find((issuerSort) => this.selectedIssuer === issuerSort.issuerId);
        this.issuerId = this.issuerSpotlights.issuerId;
        this.visibleProductList = this.issuerSpotlights.visibleSpotlights;
        this.hiddenProductList = this.issuerSpotlights.hiddenSpotlights;
      }
    });
  }

  mapVisibleProducts() {
    return this.visibleProductList.map((spotlight) => spotlight._id);
  }
  getTypeToSave() {
    return 'spotlight';
  }

  addSpotlight() {
    this.router.navigateByUrl(`/de-ch/product-managment/spotlight/${this.selectedIssuer}/add`);
  }

  deleteSpotlight(spotlight: ISpotlight) {



    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
          title: 'Delete Spotligth',
          confirmationMessage: 'Are you sure you want to delete ' + spotlight.spotlightName + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      debug(result);
      if (result) {
        this.spotlightProvider.dispatchDeleteSpotlight(spotlight);
      }

    });
    //TODO show dialog: Do you want to delete this spotlight? Can't be undone.

    //If yes, send request to backend and update all issuers.

  }
  
  editSpotlight(spotlight: ISpotlight) {
    if(!spotlight) {
      this.router.navigateByUrl(`/de-ch/product-managment/spotlight/addIssuer/${spotlight._id}`);
    } else {
      this.router.navigateByUrl(`/de-ch/product-managment/spotlight/${this.selectedIssuer}/edit/${spotlight._id}`);
    }
  }

  getFileUrl(image: MultiLanguagesString, lang: string) {
    try {
      return `${image[lang]}`;
      
    } catch (error) {
      debug(error);
    }
    return '';
  }
}
