import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ICreateOrUpdateIssuer, ISortingOrder } from '@modeso/types__ihub-lib-products-be';
import * as fromActions from '../actions/admin-issuers.actions';
import { AdminIssuerService } from '../services/issuer.service';
import * as fromFeature from './../reducers/admin.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
const debug = Debug('modeso:twint-lib-admin-fe:AdminIssuersEffects ');

@Injectable()
export class AdminIssuersEffects {

    constructor(private actions$: Actions, private service: AdminIssuerService, private store: Store<fromFeature.AppState>,
                private snackBar: MatSnackBar) { }

    getAllIssuers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetAllIssuers.type),
            mergeMap(
                () => {
                    debug('Effect is Dispatched');
                    return this.service.getAllIssuers()
                        .pipe(
                            map(
                                (response) => {
                                    debug(response);
                                    return fromActions.onGetIssuersSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetIssuersFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    addIssuers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddIssuer.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ICreateOrUpdateIssuer>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateIssuer(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onAddIssuerSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => of(fromActions.onAddIssuerFailed({payload: error}))),
                        );
                },
            ),
        ),
    );

    updateIssuers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onUpdateIssuer.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ICreateOrUpdateIssuer>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateIssuer(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    return fromActions.onUpdateIssuerSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onUpdateIssuerFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    addOrUpdateSortingOrder$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddOrUpdateSortingOrder.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ISortingOrder>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateSortingOrder(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    debug('addOrUpdateSortingOrder', response);
                                    return fromActions.onAddOrUpdateSortingOrderSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onAddOrUpdateSortingOrderFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );
    addOrUpdateSortingOrderSucceded$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddOrUpdateSortingOrderSuccessfully.type),
            tap(() => this.snackBar.open('Sorting Order is Successfully Saved', '', { duration: 3000}))
        ),  { dispatch: false }
    );
    removeSortingOrder$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onRemoveSortingOrder.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<string>) => {
                    debug('Effect is Dispatched');
                    return this.service.removeSortingOrderOfIssuer(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    debug('removeSortingOrder', response);
                                    return fromActions.onRemoveSortingOrderSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onRemoveSortingOrderFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    onAddOrUpdateFeatureProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onAddOrUpdateFeatureProduct.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<ISortingOrder>) => {
                    debug('Effect is Dispatched');
                    return this.service.addOrUpdateFeatureProduct(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    debug('addOrUpdateFeatureProduct', response);
                                    return fromActions.onAddOrUpdateFeatureProductSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onAddOrUpdateFeatureProductFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );

    removeFeatureProduct$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onRemoveFeatureProduct.type),
            mergeMap(
                (action: fromActions.ActionWithPayload<string>) => {
                    debug('Effect is Dispatched');
                    return this.service.removeSortingOrderOfIssuer(action.payload)
                        .pipe(
                            map(
                                (response) => {
                                    debug('removeSortingOrder', response);
                                    return fromActions.onRemoveFeatureProductSuccessfully({ payload: response });
                                },
                            ),
                            catchError((error) => {
                                return of(fromActions.onRemoveFeatureProductFailed({ payload: error }));
                            }),
                        );
                },
            ),
        ),
    );


    public handleOnLoadAllErrors(error) {
        // debug(error);
        // return error;
    }
}
