/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./issuer-managment.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation-tabs/navigation-tabs.component.ngfactory";
import * as i3 from "./navigation-tabs/navigation-tabs.component";
import * as i4 from "../../shared/provider/navigation.provider";
import * as i5 from "@angular/common";
import * as i6 from "./spotlight-managment.page";
import * as i7 from "@modeso/twint-lib-admin-fe";
import * as i8 from "../../shared/provider/spotlight.provider";
import * as i9 from "@angular/router";
var styles_SpotlightManagmentPage = [i0.styles];
var RenderType_SpotlightManagmentPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SpotlightManagmentPage, data: {} });
export { RenderType_SpotlightManagmentPage as RenderType_SpotlightManagmentPage };
function View_SpotlightManagmentPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-tabs", [], null, null, null, i2.View_NavigationTabsComponent_0, i2.RenderType_NavigationTabsComponent)), i1.ɵdid(2, 245760, null, 0, i3.NavigationTabsComponent, [i4.NavigationIssuerProvider], { issuers: [0, "issuers"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issuers; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SpotlightManagmentPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotlightManagmentPage_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issuers; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SpotlightManagmentPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spotlight-managment", [], null, null, null, View_SpotlightManagmentPage_0, RenderType_SpotlightManagmentPage)), i1.ɵdid(1, 245760, null, 0, i6.SpotlightManagmentPage, [i7.AdminIssuersProvider, i8.AdminSpotlightProvider, i9.Router, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpotlightManagmentPageNgFactory = i1.ɵccf("app-spotlight-managment", i6.SpotlightManagmentPage, View_SpotlightManagmentPage_Host_0, {}, {}, []);
export { SpotlightManagmentPageNgFactory as SpotlightManagmentPageNgFactory };
