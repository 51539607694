import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@modeso/twint-lib-admin-fe";
const debug = Debug('ihub:spotlight:SpotlightService');
export class SpotlightService {
    /**
     *
     * @param http
     * @param config
     */
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.server = '';
        this.spotlightsMicroServiceName = 'spotlights/';
        this.server = config.apiGateway;
    }
    getAllSportlight() {
        debug("getAllSportlight");
        const url = `${this.server}${this.spotlightsMicroServiceName}`;
        return this.http.get(url);
    }
    addOrUpdateSpotlgiht(spotlight) {
        debug("addOrUpdateSpotlgiht");
        const url = `${this.server}${this.spotlightsMicroServiceName}`;
        return this.http.post(url, spotlight);
    }
    deleteSpotlight(spotlightId) {
        debug("deleteSpotlight");
        const url = `${this.server}${this.spotlightsMicroServiceName}${spotlightId}`;
        return this.http.delete(url);
    }
}
SpotlightService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpotlightService_Factory() { return new SpotlightService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AdminConfigService)); }, token: SpotlightService, providedIn: "root" });
