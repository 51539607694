import { Action, createReducer, createSelector, on } from "@ngrx/store";
import { IProductWithDelayedPayments } from "../models/productWithDelayedPayment.model";
import * as AdminProducts from './../actions/product.actions';
export const adminProductFeatureKey = 'adminProductFeatureKey';
export interface FeatureState {
    allProductsAllLanguages: IProductWithDelayedPayments[];
    delayedpaymentSuccess: boolean;
}

export interface AppState {
    adminProductFeatureKey: FeatureState;

}
export const initialState: FeatureState = { 
    allProductsAllLanguages: undefined,
    delayedpaymentSuccess: false,
}
// State Declarations - END


// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.adminProductFeatureKey;
export const selectFeatureGetAllProductsAllLanguages = createSelector(
    selectFeature,
    (state: FeatureState) => state.allProductsAllLanguages);
export const selectFeatureDelayedPayment = createSelector(
        selectFeature, (state: FeatureState) => state.delayedpaymentSuccess);

   
const adminReducer = createReducer(
    initialState,
    on(AdminProducts.getAllProductsInAllLanguages, (state) => ({ ...state })),
    on(AdminProducts.getAllProductsInAllLanguagesSuccessfully, (state, action) =>  ({ ...state, allProductsAllLanguages: action.payload, error: undefined })),
    on(AdminProducts.getAllProductsInAllLanguagesFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminProducts.updateDelayedPayments, (state) => ({ ...state, delayedpaymentSuccess: false })),
    on(AdminProducts.updateDelayedPaymentsSuccessfully, (state, action) =>  {
        const newState = { ...state };
        newState.allProductsAllLanguages.find(product => {
            if (product.productId === action.payload.productId) {
                product.delayPaymentByMonth = action.payload.delayPaymentByMonth;
            }
        });
        newState.delayedpaymentSuccess = true;
        return newState;
    }
   ),
    on(AdminProducts.updateDelayedPaymentsFailed, (state, action) => ({ ...state, error: action.payload, delayedpaymentSuccess: false })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return adminReducer(state, action);
}

