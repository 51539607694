export class UserResponse {
  public user: string;
  public role: string;
  public message?: string;
  constructor() { }
}
export class UserResponse2FA {
  public is2FAEnabled: boolean;
  public message?: string;
  constructor() { }
}


export enum UserRoles {
  DIGITAL_VAUCHERS_PRODUCT_MANAGER = 'DIGITAL_VAUCHERS_PRODUCT_MANAGER',
  DIGITAL_VAUCHERS_REPORTING_MANAGER = 'DIGITAL_VAUCHERS_REPORTING_MANAGER',
  DIGITAL_VAUCHERS_FRAUD_MANAGER = 'DIGITAL_VAUCHERS_FRAUD_MANAGER',
  DIGITAL_VAUCHERS_FRAUD_VIEWER = 'DIGITAL_VAUCHERS_FRAUD_VIEWER',
  DIGITAL_VAUCHERS_CONTENT_MANAGER = 'DIGITAL_VAUCHERS_CONTENT_MANAGER',
  DIGITAL_VAUCHERS_ADMIN = 'DIGITAL_VAUCHERS_ADMIN',
  UNDEFINED = 'UNDEFINED',
}
