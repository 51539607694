import { Injectable } from '@angular/core';
import * as fromFeature from './../reducers/spotlight.reducer';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from './../actions/spotlight.actions';
import Debug from 'debug';
import { ICreateOrUpdateSpotlight, IIssuerAllSpotlightOrder, ISpotlight } from '@modeso/types__ihub-lib-products-be';

const debug = Debug('modeso:ihub:AdminSpotlightProvider');

@Injectable({
    providedIn: 'root'
})
export class AdminSpotlightProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public getSpotlightOrders$(): Observable<IIssuerAllSpotlightOrder[]> {
        return this.store.pipe(select(fromFeature.featureSpotlightsOrder));
    }

    public getSpotlights$(): Observable<ISpotlight[]> {
        return this.store.pipe(select(fromFeature.featureSpotlights));
    }

    public getActiveSpotlight$(): Observable<ISpotlight> {
        return this.store.pipe(select(fromFeature.featureActiveSpotlights));
    }

    public selectSpotlight(spotlightId?: string): void {
        this.store.dispatch(fromActions.onSelectSpotlight({payload: spotlightId}));
    }

    public dispatchGetAllSpotlights() {
        debug('Action Dispatched');
        this.store.dispatch(fromActions.onGetSpotlights());
    }

    public dispatchDeleteSpotlight(spotlight: ISpotlight) {
        debug('Action dispatchDeleteSpotlight Dispatched');
        this.store.dispatch(fromActions.onDeleteSpotlight({payload: spotlight}));
    }
 
    public dispatchCreateSpotlight(spotlight: ICreateOrUpdateSpotlight) {
        this.store.dispatch(fromActions.onAddSpotlight({payload: spotlight}));
    }

    public dispatchUpdateSpotlight(spotlight: ICreateOrUpdateSpotlight) {
        this.store.dispatch(fromActions.onUpdateSpotlight({payload: spotlight}));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null)
        );
    }
}

