import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { Router } from '@angular/router';
import { AdminWhiteListingProductsProvider, AllProductsAvailablePricesModel } from '@modeso/twint-lib-admin-fe';
import { MatDialog } from '@angular/material';
import { PopUpComponent } from '../popUp/popUp';
import { take } from 'rxjs/operators';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({
    selector: 'app-page-whiteListing',
    templateUrl: './whiteListing.page.html',
    styleUrls: ['./whiteListing.page.scss']
})
export class WhiteListingPage extends BasePageComponent implements OnInit {
    products: AllProductsAvailablePricesModel[] = [];
    errorInUnWhiteListProductMessage = 'Couldn\'t unwhitelist the product';
    errorInWhiteListProductMessage = 'Couldn\'t whitelist the product';
    errorInGetProductsMessage = 'couldn\'t get products';
    errorInSynchronizeProductMessage = 'Error occured while synchronizing products';
    successfullySynchronizeProductMessage = 'Succesfully Synchronized Products';
    successfullyUnWhiteListedProductMessage = 'Succesfully unwhitelisted Products';
    successfullyWhiteListedProductMessage = 'Succesfully whitelisted products';
    errorInGetProducts = false;
    errorInUnWhiteListProduct = false;
    errorInWhiteListProduct = false;
    errorInSynchronizeProducts = false;
    succesfullyWhiteListedProduct = false;
    succesfullyUnWhiteListedProduct = false;
    succesfullySynchronizedProduct = false;
    synchronizationInProgress = false;
    whiteListedProducts: AllProductsAvailablePricesModel[] = [];
    unWhiteListedProducts: AllProductsAvailablePricesModel[] = [];
    constructor(injector: Injector,
        private router: Router,
        private adminWhiteListingProvider: AdminWhiteListingProductsProvider,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getAllProductsAllLanguages();
    }
    unWhiteListProduct(productLineName: string) {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Unpublished Product',
                confirmationMessage: 'Are you sure you want to unpublish ' + productLineName + ' ?',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.subscriptions.push(
                    this.adminWhiteListingProvider.unWhiteListProduct$(productLineName)
                        .pipe(take(2)).subscribe((message: any) => {
                            if (message.message) {
                                this.succesfullyUnWhiteListedProduct = true;
                                this.succesfullyWhiteListedProduct = false;
                                this.succesfullySynchronizedProduct = false;


                                this.errorInUnWhiteListProduct = false;
                                this.errorInWhiteListProduct = false;
                                this.errorInUnWhiteListProduct = false;
                                this.errorInGetProducts = false;

                                this.whiteListedProducts = [];
                                this.unWhiteListedProducts = [];
                                this.products.forEach((product) => {
                                    if (product.productLineName === productLineName) {
                                        product.whiteListed = false;
                                    }
                                    if (product.whiteListed) {
                                        this.whiteListedProducts.push(product);
                                    } else {
                                        this.unWhiteListedProducts.push(product);
                                    }
                                });

                            } else {
                                this.adminWhiteListingProvider.getError$().subscribe((errorResponse: any) => {
                                    if (errorResponse) {
                                        this.errorInUnWhiteListProduct = true;
                                        this.errorInWhiteListProduct = false;
                                        this.errorInGetProducts = false;
                                    }
                                });
                            }

                        }),
                );
            }

        });

    }
    whiteListProduct(productLineName: string) {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Whitelisted Product',
                confirmationMessage: 'Are you sure you want to whitelist ' + productLineName + ' ?',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.subscriptions.push(
                    this.adminWhiteListingProvider.whiteListProduct$(productLineName)
                        .pipe(take(2)).subscribe((message: any) => {
                            if (message.message) {
                                this.succesfullyWhiteListedProduct = true;
                                this.succesfullyUnWhiteListedProduct = false;
                                this.succesfullySynchronizedProduct = false;

                                this.errorInUnWhiteListProduct = false;
                                this.errorInWhiteListProduct = false;
                                this.errorInUnWhiteListProduct = false;
                                this.errorInGetProducts = false;

                                this.whiteListedProducts = [];
                                this.unWhiteListedProducts = [];
                                this.products.forEach((product) => {
                                    if (product.productLineName === productLineName) {
                                        product.whiteListed = true;
                                    }
                                    if (product.whiteListed) {
                                        this.whiteListedProducts.push(product);
                                    } else {
                                        this.unWhiteListedProducts.push(product);
                                    }
                                });

                            } else {
                                this.adminWhiteListingProvider.getError$().subscribe((errorResponse: any) => {
                                    if (errorResponse) {
                                        this.errorInWhiteListProduct = true;
                                        this.errorInUnWhiteListProduct = false;
                                        this.errorInGetProducts = false;
                                    }
                                });
                            }
                        }),
                );
            }

        });
    }

    synchronizeProducts() {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Synchronize Products',
                confirmationMessage: 'Are you sure you want to synchronize products?',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.synchronizationInProgress = true;
                this.subscriptions.push(
                    this.adminWhiteListingProvider.synchronizeProducts$()
                        .pipe(take(2)).subscribe((message: any) => {
                            if (message) {
                                this.synchronizationInProgress = false;
                                this.succesfullySynchronizedProduct = true;
                                this.succesfullyUnWhiteListedProduct = false;
                                this.succesfullyWhiteListedProduct = false;

                                this.errorInUnWhiteListProduct = false;
                                this.errorInWhiteListProduct = false;
                                this.errorInUnWhiteListProduct = false;
                                this.errorInGetProducts = false;
                                this.getAllProductsAllLanguages();
                            } else {
                                this.adminWhiteListingProvider.getError$().subscribe((errorResponse: any) => {
                                    if (errorResponse) {
                                        this.synchronizationInProgress = false;
                                        this.errorInSynchronizeProducts = true;
                                        this.errorInGetProducts = false;

                                    }
                                });
                            }
                        }),
                );
            }
        });
    }

    getAllProductsAllLanguages() {
        this.subscriptions.push(
            this.adminWhiteListingProvider.getAllProductsInAllLanguages$()
                .pipe(take(2)).subscribe((allProducts: any[]) => {
                    if (allProducts) {
                        this.errorInGetProducts = false;
                        this.products = allProducts;
                        this.whiteListedProducts = [];
                        this.unWhiteListedProducts = [];
                        allProducts.forEach((product) => {
                            if (product.whiteListed) {
                                this.whiteListedProducts.push(product);
                            } else {
                                this.unWhiteListedProducts.push(product);
                            }
                        });
                    } else {
                        this.adminWhiteListingProvider.getError$().subscribe((errorResponse: any) => {
                            if (errorResponse) {
                                this.errorInGetProducts = true;
                            }
                        });
                    }
                }),
        );

    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
