import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { SidebarComponent } from './skeleton/sidebar/sidebar.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomePage } from './pages/home/home.page';
import { FraudLimitsPage } from './pages/fraudLimits/fraudLimits.page';
import { LoginPage } from './pages/login/login.page';
import { ChangePasswordPage } from './pages/changePassword/changePassword.page';
import { SignupPage } from './pages/signup/signup.page';
import { ReportsPage } from './pages/reports/reports.page';
import { BasePageComponent } from './pages/base.page';
import { BlockedProductsPage } from './pages/blockedProducts/blockedProducts.page';
import { SharedModule } from './shared/shared.module';
import * as fromAppState from './state/app.state.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { UpdateDefaultDialog } from './pages/fraudLimits/updateDialog/updateDefaultDialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminConfig, AdminModule } from '@modeso/twint-lib-admin-fe';
import { SessionOrchestrator } from './orchestrator/session.orchestrator';
import { PopUpComponent } from './pages/popUp/popUp';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule, MatInputModule, MatTabsModule } from '@angular/material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material';
import { WhiteListingPage } from './pages/whiteListing/whiteListing.page';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddOrUpdateIssuerDialog } from './pages/fraudLimits/addOrUpdateIssuerDialog/addOrUpdateIssuerDialog';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateLocalizationByKeyDialog } from './pages/manageContent/updatelocalizationByKeyDialog/updateLocalizationByKeyDialog';
import { ProductManagmentPage } from './pages/productManagment/productManagment.page';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
// tslint:disable-next-line: max-line-length
import { UpdateContentByProductLineDialog } from './pages/productContentManagement/updateContentByProductLineDialog/updateContentByProductLineDialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UserManagementPage } from './pages/userManagement/userManagement.page';
import { ManageUsersPage } from './pages/manageUsers/manageUsers.page';
import { UpdateUserByIdDialog } from './pages/manageUsers/updateUserByIdDialog/updateUserByIdDialog';
import { ClosedUserGroupPage } from './pages/closedUserGroup/closedUserGroup.page';
import { UserSearchFormComponent } from './pages/closedUserGroup/userSearchForm/userSearchForm.page';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AuditLogsPage } from './pages/auditLogs/auditLogs.page';
import { SamlAssertPage } from './pages/samlAssert/samlAssert.page';
import { InsuranceStatusNamePipe } from './shared/util/insuranceStatusName.pipe';
import { PaymentStatusNamePipe } from './shared/util/paymentStatus.enum';
import { InsurancesManagmentPage } from './pages/insurancesManagement/insurancesManagment.page';
import { PaymentHistoryPage } from './pages/payment-history/payment-history.page';
import { InsurancesPage } from './pages/insurances/insurances.page';
import { NavigationTabsComponent } from './pages/issuer-managment/navigation-tabs/navigation-tabs.component';
import { IssuerManagmentPage } from './pages/issuer-managment/issuer-managment.page';
import { ProductListComponenet } from './pages/issuer-managment/product-list-componenet/product-list-componenet.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';
import { AddIssuerComponent } from './pages/issuer-managment/add-issuer/add-issuer.component';
import {MatButtonModule} from '@angular/material/button';
import { ConfirmDeleteModalComponent } from './pages/issuer-managment/confirm-delete-modal/confirm-delete-modal.component';

import {FailedPaymentHistoryPage} from './pages/failed-payment-history/failed-payment-history.page';
import { SpotlightListComponenet } from './pages/issuer-managment/spotlight-list-componenet/spotlight-list-componenet.component';
import { SpotlightManagmentPage } from './pages/issuer-managment/spotlight-managment.page';
import { AddSpotlightComponent } from './pages/issuer-managment/add-spotlight/add-spotlight.component';
import { GoogleStorageRequestInterceptor } from './shared/interceptors/googlestoragerequest.interceptor';
import { AuthHandler, AUTH_INTERCEPTOR } from './shared/interceptors/auth.session.interceptor';
import { DelayPaymentForProductComponent } from './pages/delay-payment-for-product/delay-payment-for-product.component';
import { RemoveUnderscorePipe } from './shared/util/removeUnderscore.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';

const adminConfig: AdminConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'admin'
};
@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    HeaderComponent,
    SidebarComponent,
    ContentAreaComponent,
    HomePage,
    FraudLimitsPage,
    UpdateDefaultDialog,
    LoginPage,
    ChangePasswordPage,
    SignupPage,
    BlockedProductsPage,
    PopUpComponent,
    ReportsPage,
    AddOrUpdateIssuerDialog,
    InsurancesManagmentPage,
    PaymentHistoryPage,
    ManageContentPage, UpdateLocalizationByKeyDialog,
    WhiteListingPage,
    InsurancesPage,
    AddOrUpdateIssuerDialog,
    ProductManagmentPage, UpdateContentByProductLineDialog,
    ContentManagementPage,
    UserManagementPage, ManageUsersPage, UpdateUserByIdDialog,
    ClosedUserGroupPage, UserSearchFormComponent,
    AuditLogsPage,
    SamlAssertPage,
    InsuranceStatusNamePipe,
    PaymentStatusNamePipe,
    NavigationTabsComponent,
    IssuerManagmentPage,
    ProductListComponenet,
    AddIssuerComponent,
    ConfirmDeleteModalComponent,
    FailedPaymentHistoryPage,
    SpotlightManagmentPage,
    SpotlightListComponenet,
    AddSpotlightComponent,
    DelayPaymentForProductComponent,
    RemoveUnderscorePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot([]),
    StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    AppRoutingModule,
    SharedModule.forRoot(adminConfig),
    FormsModule,
    ReactiveFormsModule,
    AdminModule.forRoot(adminConfig),
    BrowserAnimationsModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule, MatFormFieldModule, MatInputModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatSnackBarModule, MatCheckboxModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    AngularEditorModule,
    MatTableExporterModule,
    DragDropModule,
    MatIconModule,
    MatButtonModule,
    NgxFileDropModule,

  ],
  entryComponents: [
    PopUpComponent, UpdateDefaultDialog, AddOrUpdateIssuerDialog, UpdateLocalizationByKeyDialog, UpdateContentByProductLineDialog,
    UpdateUserByIdDialog, ConfirmDeleteModalComponent
  ],
  providers: [
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
    InsuranceStatusNamePipe,
    PaymentStatusNamePipe,
    RemoveUnderscorePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
