<div class="container" *ngIf="!hasReadPermission()">
    Sorry you don't have read permission.
</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="alert alert-danger" *ngIf="errorInUnWhiteListProduct">
        {{errorInUnWhiteListProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInWhiteListProduct">
        {{errorInWhiteListProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInSynchronizeProducts">
        {{errorInSynchronizeProductMessage}}
    </div>
    <div class="alert alert-danger" *ngIf="errorInGetProducts">
        {{errorInGetProductsMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullyWhiteListedProduct">
        {{successfullyWhiteListedProductMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullyUnWhiteListedProduct">
        {{successfullyUnWhiteListedProductMessage}}
    </div>
    <div class="alert alert-success" *ngIf="succesfullySynchronizedProduct">
        {{successfullySynchronizeProductMessage}}
    </div>

    <h3>Public available Products</h3>
    <table *ngFor="let whiteListedProduct of whiteListedProducts;let id=index"
        class="table table-striped table-bordered table-hover">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Locale</th>
                <th scope="col">Title</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of whiteListedProduct.title | keyvalue | slice:1; let productId=index">
                <th scope="row" >{{productId}}</th>
                <td>{{product.key}}</td>
                <td>{{product.value}}</td>
            </tr>
        </tbody>
        <button *ngIf="hasWritePermission()" (click)="unWhiteListProduct(whiteListedProduct.product)">Unpublish</button>

    </table>

    <h3>Unpublished Products</h3>
    <table *ngFor="let unWhiteListedProduct of unWhiteListedProducts;let id=index"
        class="table table-striped table-bordered table-hover ">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">Locale</th>
                <th scope="col">Title</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of unWhiteListedProduct.title | keyvalue | slice:1; let productId=index">
                <th scope="row">{{productId}}</th>
                <td>{{product.key}}</td>
                <td>{{product.value}}</td>
            </tr>
        </tbody>
        <button *ngIf="hasWritePermission()" (click)="whiteListProduct(unWhiteListedProduct.product)">Publish</button>
    </table>