import * as i0 from "@angular/core";
import * as i1 from "./services/localStorage.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(localStorageService, router) {
        this.localStorageService = localStorageService;
        this.router = router;
    }
    canActivate() {
        if (this.localStorageService.isUserAuthorized()) {
            return true;
        }
        else {
            this.router.navigate(['']);
            return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
