import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationIssuerProvider } from '../../../shared/provider/navigation.provider';
import { INavigationItem } from '../issuer-managment.page';
@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabsComponent implements OnInit, OnDestroy {


  private issuersSubject =  new BehaviorSubject(null);
  private subscription: Subscription[] = [];
  activeLink: string;
  mappedNavigation: INavigationItem[] = [];
  @Input()
  set issuers(value: INavigationItem[]) {
    if (value) {
      this.issuersSubject.next(value);
    }
  }
  constructor(private navigationTab: NavigationIssuerProvider) {
    this.navigationTab.getNavigationTab$().subscribe((navTab) => {
      if (!navTab) {
        return;
      }
      this.activeLink = navTab;
    });
  }
  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
   this.subscription.push(this.issuersSubject.subscribe((issuers: INavigationItem[]) => {
    if (!issuers || !issuers.length) {
      return;
    }
    this.mappedNavigation = [...issuers];
    if (!this.activeLink) {
      this.activeLink =  this.mappedNavigation[0].link;
    }
   }));
  }

  setNavTab(navTab: string) {
    this.navigationTab.dispatchSetNavigationTab(navTab);
  }
}
