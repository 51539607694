import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICreateOrUpdateIssuer, IIssuerAllProductsOrder, ISortingOrder } from '@modeso/types__ihub-lib-products-be';
import * as fromActions from '../actions/admin-issuers.actions';
import * as fromFeature from './../reducers/admin.reducer';

const debug = Debug('modeso:twint-lib-admin-fe:AdminIssuersProvider');
@Injectable({
    providedIn: 'root',
})
export class AdminIssuersProvider {

    constructor(
        private store: Store<fromFeature.AppState>) { }

    public getAllIssuers$(): Observable<IIssuerAllProductsOrder[]> {
        return this.store.pipe(select(fromFeature.selectIssuersFeature));
    }

    public dispatchGetAllIssuers() {
       debug('Action Dispatched');
       this.store.dispatch(fromActions.onGetAllIssuers());
    }

    public getIssuerById$(issuerId: string) {
       this.store.dispatch(fromActions.onSetCurrentIssuer({payload: issuerId}));
       return this.store.pipe(select(fromFeature.selectIssuerByName(issuerId)));
    }

    public dispatchCreateIssuer(issuer: ICreateOrUpdateIssuer) {
        this.store.dispatch(fromActions.onAddIssuer({payload: issuer}));
    }

    public dispatchUpdateIssuer(issuer: ICreateOrUpdateIssuer) {
        this.store.dispatch(fromActions.onUpdateIssuer({payload: issuer}));
    }

    public dispatchCreateOrUpdateSortingOrder(sortingOrder: ISortingOrder) {
        this.store.dispatch(fromActions.onAddOrUpdateSortingOrder({payload: sortingOrder}));
    }

    public removeSortingOrderOfIssuer(data: any) {
        this.store.dispatch(fromActions.onRemoveSortingOrder({payload: data}));
    }

    public getNewelyAddedOrUpdateIssuer$(): Observable<IIssuerAllProductsOrder> {
        return this.store.pipe(select(fromFeature.selectNewelyAddedOrUpdatedIssuer));
    }

    public dispatchSetProductFeature(featureProduct: ISortingOrder) {
        this.store.dispatch(fromActions.onAddOrUpdateFeatureProduct({payload: featureProduct}));
    }

    public getFeatureProduct$(): Observable<string> {
        return this.store.pipe(select(fromFeature.getFeatureProduct));
    }

    public removeFeatureProduct(data: any) {
        this.store.dispatch(fromActions.onRemoveFeatureProduct({payload: data}));
    }
      
    public getError$(): Observable<any> {
        return this.store.pipe(select(fromFeature.featureError),
            map((error: any) => {
                return error;
            })
        );
    }


}
