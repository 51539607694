import { StoreModule } from '@ngrx/store';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromReducer from './reducers/insurance.reducer';
import * as fromReducerSpotlight from './reducers/spotlight.reducer';
import * as fromReducerProduct from './reducers/product.reducer';
import { AdminInsuranceEffects } from './effects/insurance.effects';
import { EffectsModule } from '@ngrx/effects';
import { AdminConfig, AdminConfigService } from '@modeso/twint-lib-admin-fe';
import { AdminInsuranceService } from './services/insurance.service';
import { GetEnumKeyFromValuePipe } from './util/getEnumKeyFromValue.pipe';
import { AdminSpotlightEffects } from './effects/spotlight.effect';
import { UploadService } from './services/upload.service';
import { AddImageDialog } from './components/addImageDialog/addImageDialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminProductEffects } from './effects/product.effects';

@NgModule({
    declarations: [
      GetEnumKeyFromValuePipe,
      AddImageDialog
    ],
    providers: [
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromReducer.adminInsuranceFeatureKey, fromReducer.reducer),
        StoreModule.forFeature(fromReducerSpotlight.adminSpotlightFeatureKey, fromReducerSpotlight.reducer),
        StoreModule.forFeature(fromReducerProduct.adminProductFeatureKey, fromReducerProduct.reducer),
        EffectsModule.forFeature([AdminInsuranceEffects, AdminProductEffects]),
        EffectsModule.forFeature([AdminSpotlightEffects]),
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        NgxFileDropModule,
    ],
    exports: [
      GetEnumKeyFromValuePipe,
      AddImageDialog,
    ],
    entryComponents: [
        AddImageDialog
    ]
})
export class SharedModule {
    static forRoot(config: AdminConfig): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                UploadService,
                AdminInsuranceService,
                {
                    provide: AdminConfigService,
                    useValue: config
                }
            ]
        };
    }

}
