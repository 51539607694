import Debug from 'debug';
const debug = Debug('modeso:dgoods-admin:PermissionHelper');

export class PermissionHelper {
    public static hasPermission(assignedRole: string[], priviledRoles: string[]): boolean {
        const roles = assignedRole;
        const rolesWithWritePermission = priviledRoles;

        let hasWritePermission = false;
        rolesWithWritePermission.forEach((priviledgedRole) => {
            roles.forEach((role) => {
                if (priviledgedRole === role) {
                    hasWritePermission = true;
                }
            });
        });
        return hasWritePermission;
    }
}
