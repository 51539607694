/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addImageDialog.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/ngx-file-drop/ngx-file-drop.ngfactory";
import * as i5 from "ngx-file-drop";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i8 from "@angular/material/progress-spinner";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./addImageDialog";
import * as i13 from "../../services/upload.service";
var styles_AddImageDialog = [i0.styles];
var RenderType_AddImageDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_AddImageDialog, data: {} });
export { RenderType_AddImageDialog as RenderType_AddImageDialog };
function View_AddImageDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "browse-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.openFileSelector() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["style", "color: grey;font-size: 50px;width: 50px; height: 50px;"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_AddImageDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngx-file-drop", [["dropZoneLabel", "Drop 8 here"], ["style", "width: 120px;"]], null, [[null, "onFileDrop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileDrop" === en)) {
        var pd_0 = (_co.dropped($event, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_NgxFileDropComponent_0, i4.RenderType_NgxFileDropComponent)), i1.ɵdid(1, 180224, null, 1, i5.NgxFileDropComponent, [i1.NgZone, i1.Renderer2], { dropZoneLabel: [0, "dropZoneLabel"] }, { onFileDrop: "onFileDrop" }), i1.ɵqud(335544320, 1, { contentTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_AddImageDialog_3)), i1.ɵdid(4, 16384, null, 0, i5.NgxFileDropContentTemplateDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "Drop 8 here"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AddImageDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "upload-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.fileReader[_v.parent.context.index].url, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AddImageDialog_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["matSuffix", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "cancel-mat-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AddImageDialog_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error While Uploading file Incompatible Format or File Size exceeds 15 MB"]))], null, null); }
function View_AddImageDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "upload-rectangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["style", "overflow: hidden;display:inline-block;width:200px;margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_5)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["style", "display: table-row;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_6)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !((_co.uploadedImages[_v.context.index] == null) ? null : _co.uploadedImages[_v.context.index].url); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.uploadedImages[_v.context.index] == null) ? null : _co.uploadedImages[_v.context.index].url); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.uploadedImages[_v.context.index] == null) ? null : _co.uploadedImages[_v.context.index].url); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.errors[_v.context.index]; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages[_v.context.index]; _ck(_v, 2, 0, currVal_0); }); }
function View_AddImageDialog_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", " display: flex; position: fixed; top: 0; left: 0; right: 0; bottom: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin:auto;"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i7.View_MatSpinner_0, i7.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i8.MatSpinner, [i1.ElementRef, i9.Platform, [2, i6.DOCUMENT], [2, i10.ANIMATION_MODULE_TYPE], i8.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AddImageDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""], ["style", "text-align: center;"]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i11.MatDialogTitle, [[2, i11.MatDialogRef], i1.ElementRef, i11.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Add Images"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"], ["style", "width: 848px;height:250px;"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i11.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_1)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i11.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["cdkFocusInitial", ""], ["class", "save-button"], ["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "cancel-button"], ["mat-button", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 606208, null, 0, i11.MatDialogClose, [[2, i11.MatDialogRef], i1.ElementRef, i11.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddImageDialog_7)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.images; _ck(_v, 8, 0, currVal_1); var currVal_5 = false; _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.isUploadFinished; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.isValid(); _ck(_v, 11, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 14).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 14).type; _ck(_v, 13, 0, currVal_3, currVal_4); }); }
export function View_AddImageDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "add-image-dialog", [], null, null, null, View_AddImageDialog_0, RenderType_AddImageDialog)), i1.ɵdid(1, 49152, null, 0, i12.AddImageDialog, [i11.MatDialogRef, i11.MAT_DIALOG_DATA, i13.UploadService], null, null)], null, null); }
var AddImageDialogNgFactory = i1.ɵccf("add-image-dialog", i12.AddImageDialog, View_AddImageDialog_Host_0, {}, {}, []);
export { AddImageDialogNgFactory as AddImageDialogNgFactory };
