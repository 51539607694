import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,  Router } from '@angular/router';
import { AdminIssuersProvider } from '@modeso/twint-lib-admin-fe';
import { ICreateOrUpdateIssuer } from '@modeso/types__ihub-lib-products-be';
import {take, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { NavigationIssuerProvider } from '../../../shared/provider/navigation.provider';
import Debug from 'debug';
const debug = Debug('ihub:admin:AddIssuerComponent');
@Component({
  selector: 'app-add-issuer',
  templateUrl: './add-issuer.component.html',
  styleUrls: ['./add-issuer.component.scss']
})
export class AddIssuerComponent  implements OnInit, OnDestroy {
  issuerForm: FormGroup;
  issuer: ICreateOrUpdateIssuer;
  subscriptions: Subscription[] = [];
  alreadyExistErrorMessage: string;
  constructor(private fb: FormBuilder, private adminProvider: AdminIssuersProvider,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private navigationProvider: NavigationIssuerProvider) {
    this.subscriptions.push(this.adminProvider.getError$().subscribe((err) => {
      if (!err) {
        this.alreadyExistErrorMessage = '';
       
        return;
      }
      if (err.error.duplicateKey) {
        const alreadyExistFieldValueError = Object.keys(err.error.duplicateKey)[0];
        const words = alreadyExistFieldValueError.split(/(?=[A-Z])/);
        const contorlName = `${words[0].charAt(0).toUpperCase()}${words[0].slice(1)} ${words.slice(1).join(' ')}`;
        this.alreadyExistErrorMessage = `${contorlName} already exists`;
      }
      debug(err)
    }));
    this.activatedRoute.params.pipe(take(1)).subscribe((param) => {
      if (!param['issuerId']) {
        debug("initAddMode");
        this.initAddMode();
      } else {
        debug("initEditMode");
        this.adminProvider.getIssuerById$(param['issuerId']).pipe(take(2)).subscribe((issuer) => {
          if (!issuer) {
            this.initAddMode();
          } else {
            this.issuer = issuer;
            this.initForm();
          }
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
  }

  initForm() {
    this.issuerForm = this.fb.group({
      issuerName: [this.issuer.issuerName, [Validators.required]],
      androidIssuer: [this.issuer.androidIssuer, [Validators.required]],
      iosIssuer: [this.issuer.iosIssuer, [Validators.required]],
    });
  }

  get issuerName() {
    return this.issuerForm.get('issuerName');
  }
  get androidIssuer() {
    return this.issuerForm.get('androidIssuer');
  }
  get iosIssuer() {
    return this.issuerForm.get('iosIssuer');
  }

  onSubmit() {
    const issuer = this.issuerForm.value as ICreateOrUpdateIssuer;
    if (this.issuer.issuerId) {
      issuer.issuerId = this.issuer.issuerId;
      this.adminProvider.dispatchUpdateIssuer(issuer);
    } else {
      this.adminProvider.dispatchCreateIssuer(issuer);
    }
    this.adminProvider.getNewelyAddedOrUpdateIssuer$().pipe(takeWhile((newIssuer) => !newIssuer, true)).subscribe((value) => {
      if (!value) {
        return;
      }
      this.navigationProvider.dispatchSetNavigationTab(value.issuerId);
      this.router.navigate([`/de-ch/product-managment/issuers/${value.issuerId}`]);
    });
  }

  initAddMode() {
    this.issuer = {} as ICreateOrUpdateIssuer;
    this.initForm();
  }
}
