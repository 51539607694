import { Injectable, InjectionToken, Inject } from '@angular/core';
import Debug from 'debug';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay, take, finalize } from 'rxjs/operators';
import { AdminProvider } from '@modeso/twint-lib-admin-fe';
const debug = Debug('ihub:session:AuthHandler');

export interface IAuthHandlerDelegate {
    onSessionExpired();
}

export const AUTH_INTERCEPTOR = new InjectionToken<IAuthHandlerDelegate>('AUTH_INTERCEPTOR');
@Injectable()
export class AuthHandler implements HttpInterceptor {
    refreshRequestIsRunning = false;

    constructor(private sessionProviders: AdminProvider,
        @Inject(AUTH_INTERCEPTOR) private authHandlerDelegate: IAuthHandlerDelegate) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq;

        if (!request.url.includes('storage.googleapis.com')) {
            debug("storage.googleapis.com is not included");
            modifiedReq = request.clone({
                headers: request.headers.set('content-language', 'en-us'),
                withCredentials: true,
            });
        } else {
            debug("storage.googleapis.com is included");
            modifiedReq = request.clone({});
        }
        // return next.handle(modifiedReq);


        return next.handle(modifiedReq).pipe(
            catchError(err => {
                if (request.url.endsWith('admin/token')) {
                    this.refreshRequestIsRunning = false;
                }
                if (err.status === 440) {
                    // redirect to checkin again
                    // localStorage.clear();

                    if (this.authHandlerDelegate) {
                        debug('onSessionExired');
                        debug(request.url);
                        this.authHandlerDelegate.onSessionExpired();
                    } else {
                        debug('onSessionExired event not fired. No delegate defined for the AuthHandler.');
                    }
                }

                return throwError(err);
            }),
            retryWhen(errors => errors
                .pipe(
                    concatMap((error) => {
                        if ((error.status === 401)) {
                            debug('JWT Token exired, lease new one');
                            debug(request.url);
                            if (!this.refreshRequestIsRunning) {
                                this.sessionProviders.getNewAccsessToken$();
                                this.refreshRequestIsRunning = true;
                            }
                            return of(error.status);
                        }
                        return throwError(error);
                    }),
                    take(2),
                    delay(1000)
                )
            ),
            finalize(() => {
                if (request.url.endsWith('admin/token')) {
                    this.refreshRequestIsRunning = false;
                }
                debug('first finalize() block executed', request.url);
            }),
        );

    }
}


