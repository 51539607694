<div cdkDropList class="item-list" (cdkDropListDropped)="drop($event)" *ngIf="visibleProductList && visibleProductList.length">
    <div class="item" *ngFor="let product of visibleProductList" cdkDrag>
       <span> {{product.spotlightName}}</span> 
       <div *ngIf="product.image">

           <img [src]="getFileUrl(product.image, 'de-ch')" /> 
           <img [src]="getFileUrl(product.image, 'fr-ch')" /> 
           <img [src]="getFileUrl(product.image, 'it-ch')" /> 
           <img [src]="getFileUrl(product.image, 'en-us')" /> 
           
       </div>
       <button mat-button (click)="hideProduct(product)">Hide</button>
       <button mat-button (click)="deleteSpotlight(product)">Delete</button>
       <button mat-button (click)="editSpotlight(product)">Edit</button>
    </div>
</div>

<div class="item-list" *ngIf="hiddenProductList && hiddenProductList.length">
    <div class="item item__hide" *ngFor="let product of hiddenProductList">
        <span> {{product.spotlightName}}</span> 
        <div *ngIf="product.image">

            <img [src]="getFileUrl(product.image, 'de-ch')" /> 
            <img [src]="getFileUrl(product.image, 'fr-ch')" /> 
            <img [src]="getFileUrl(product.image, 'it-ch')" /> 
            <img [src]="getFileUrl(product.image, 'en-us')" /> 
            
        </div>
        <button mat-button (click)="showProduct(product)">Show</button>
        <button mat-button (click)="deleteSpotlight(product)">Delete</button>
        <button mat-button (click)="editSpotlight(product)">Edit</button>
  </div> 
</div>

<div class="action-buttons">
    <div class="issuer-actions">
        <button mat-raised-button style="margin-right: 5px;" (click)="addSpotlight()">Add</button>
    </div>
    <div class="sort-order-actions">
        <button mat-raised-button style="margin-right: 5px;" (click)="updateSortingOrder()">Save</button>
        <button mat-raised-button  (click)="removeSortingOrder()" [disabled]="issuer?.isDefault || !issuer?.hasSortingOrder">Remove Order</button>
    </div>
</div>