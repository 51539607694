<div class="reports container" *ngIf="!hasReadPermission()">
  Sorry you don't have read permission.
</div>
<div class="reports container" *ngIf="hasReadPermission()">
  <div class="row">
    <div class="col-lg-3">
      <div>
        <h4>Select Kpi</h4>
        <mat-form-field appearance="fill">
          <mat-label>Kpis</mat-label>
          <mat-select
            [formControl]="kpiControl"
            (selectionChange)="getSelectedKpi($event)"
            required
          >
            <mat-option *ngFor="let kpi of kpis" [value]="kpi">
              {{kpi}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="kpiControl.hasError('required')"
            >Please choose a kpi</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-3">
      <div>
        <h4>Select Time Filter</h4>
        <mat-form-field appearance="fill">
          <mat-label>Time Filter</mat-label>
          <mat-select
            [formControl]="timeFrameControl"
            (selectionChange)="getSelectedTimeFilter($event)"
            required
          >
            <mat-option
              *ngFor="let timeFrame of timeFrames"
              [value]="timeFrame"
            >
              {{timeFrame}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="timeFrameControl.hasError('required')"
            >Please choose a time period</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-3">
      <div>
        <h4>Select Date</h4>
        <mat-form-field appearance="fill">
          <mat-label>Select Date</mat-label>
          <input
            matInput
            [matDatepicker]="dp3"
            [(ngModel)]="selectedDate"
            disabled
          />
          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
          <mat-datepicker #dp3 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-3">
      <div>
        <h4>Select Time</h4>
        <mat-form-field appearance="fill" class="custom_mat_form_time">
          <div class="time-picker">
            <input
              matInput
              [ngxTimepicker]="toggleTimepicker"
              [disableClick]="true"
              readonly
              [format]="24"
              disabled
              #timePicker
              placeholder="Select time"
            />
            <ngx-material-timepicker-toggle
              [for]="toggleTimepicker"
            ></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              #toggleTimepicker
            ></ngx-material-timepicker>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="search-container">
      <div class="search-button">
        <button
          id="saveAddressButton"
          [disabled]="buttonStatus()"
          (click)="onSearch()"
        >
          Search
        </button>
      </div>
    </div>
  </div>

  <div class="row errorMessages">
    <span class="error-text" *ngIf="isBadRequest"
      >{{badRequestErrorMessage}}</span
    >
    <span class="error-text" *ngIf="isServerError">{{serverError}}</span>
  </div>

  <div *ngIf="isInsurance" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="insuranceDataSource"
        matTableExporter
        #goodsExporter="matTableExporter"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="productId">
          <th mat-header-cell *matHeaderCellDef>Product Id</th>
          <td mat-cell *matCellDef="let element">
            {{element._id.product || element._id}}
          </td>
        </ng-container>

        <!-- Terminal Id Column -->
        <ng-container matColumnDef="Terminal Id">
          <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
          <td mat-cell *matCellDef="let element">{{element._id.terminalId}}</td>
        </ng-container>
        <ng-container matColumnDef="Price">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            {{element.totalAmountOfActiveInsurance}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Active">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let element">
            {{element.activeInsurance}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Canceled">
          <th mat-header-cell *matHeaderCellDef>Canceled</th>
          <td mat-cell *matCellDef="let element">
            {{element.canceledInsurance}}
          </td>
        </ng-container>

        <ng-container matColumnDef="WAITFORUOF">
          <th mat-header-cell *matHeaderCellDef>Wait For User</th>
          <td mat-cell *matCellDef="let element">
            {{element.waitForUserInsurance}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Draft">
          <th mat-header-cell *matHeaderCellDef>Draft</th>
          <td mat-cell *matCellDef="let element">{{element.draftInsurance}}</td>
        </ng-container>
        <ng-container matColumnDef="Suspended">
          <th mat-header-cell *matHeaderCellDef>Suspended</th>
          <td mat-cell *matCellDef="let element">
            {{element.suspendedInsurance}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Updated_At">
          <th mat-header-cell *matHeaderCellDef>Updated At</th>
          <td mat-cell *matCellDef="let element">
            {{element._id.updatedAt}}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="goodsAndSessionsDisplayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: goodsAndSessionsDisplayedColumns;"
        ></tr>
      </table>

      <mat-paginator
        #goodsPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="goodsExportExcelButton"
        (click)="goodsExporter.exportTable(exportType, exportGoodsOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>

  <div *ngIf="isSales" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="salesDataSource"
        matTableExporter
        #salesExporter="matTableExporter"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="forename">
          <th mat-header-cell *matHeaderCellDef>Vorname</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.forename}}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef>Nachname</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.lastname}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Reference">
          <th mat-header-cell *matHeaderCellDef>UUID</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element._id}}
          </td>
        </ng-container>

        <ng-container matColumnDef="birthday">
          <th mat-header-cell *matHeaderCellDef>Geburtstag</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.birthday}}
          </td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef>Strasse</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.street}}
          </td>
        </ng-container>
        <ng-container matColumnDef="streetNumber">
          <th mat-header-cell *matHeaderCellDef>Hausnummer</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.streetNumber}}
          </td>
        </ng-container>
        <ng-container matColumnDef="zipcode">
          <th mat-header-cell *matHeaderCellDef>PLZ</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.zipcode}}
          </td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>Ort</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.city}}
          </td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Tel</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.phone}}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.email}}
          </td>
        </ng-container>
        <ng-container matColumnDef="receiveEmails">
          <th mat-header-cell *matHeaderCellDef>Marketingfreigabe</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.receiveEmails}}
          </td>
        </ng-container>

        <!-- Total Orders Column -->
        <ng-container matColumnDef="policyNumber">
          <th mat-header-cell *matHeaderCellDef>Policy Number</th>
          <td mat-cell *matCellDef="let element">{{element.policyNumber}}</td>
        </ng-container>

        <!-- Cancel by shop Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let element">{{element.product}}</td>
        </ng-container>

        <!-- Is predfined Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Active Insurance Amount</th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.price}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">
            Insurance Package
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <span *ngIf="element.family">Family</span>
            <span *ngIf="!element.family">Single</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Variation">
          <th mat-header-cell *matHeaderCellDef>Variation</th>
          <td mat-cell style="text-align: center" *matCellDef="let element">
            {{element.variation}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Insurance Status">
          <th mat-header-cell *matHeaderCellDef>Insurance Status</th>
          <td mat-cell *matCellDef="let element">
            {{element.insuranceStatus | insuranceStatusName | titlecase | removeUnderscore }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element">{{element.endDate }}</td>
        </ng-container>
        <ng-container matColumnDef="Payment Status">
          <th mat-header-cell *matHeaderCellDef>Payment Status</th>
          <td mat-cell *matCellDef="let element">
            {{element.paymentStatus | paymentStatusName | titlecase }}
          </td>
        </ng-container>

        <!-- Use as gift Column -->
        <ng-container matColumnDef="createdAt">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            createdAt
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.createdAt}}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            updatedAt
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.updatedAt}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Terminal">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            Terminal
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.terminalId}}
          </td>
        </ng-container>
        <ng-container matColumnDef="User token">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            User Token
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.userToken}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Language">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            Language
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            {{element.language}}
          </td>
        </ng-container>

        <ng-container matColumnDef="CancelationReason">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            Cancelation Reason
          </th>
          <td style="text-align: center" mat-cell *matCellDef="let element">
            <span>{{element.insuranceCancelationReason}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="AdditionalAttributes">
          <th mat-header-cell *matHeaderCellDef>Additional Attributes</th>
            <td mat-cell  *matCellDef="let element" >
              <ng-container *ngIf="element.additionalAttributes && element.additionalAttributes.length > 0" >
                <code>
                  <pre>{{element.additionalAttributes[0] | json}}</pre>
                </code>
              </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="DisplayAttributes">
          <th mat-header-cell *matHeaderCellDef>Display Attributes</th>
            <td mat-cell  *matCellDef="let element" >
              <ng-container *ngIf="element.displayAttributes && element.displayAttributes.length > 0" >
                <code>
                  <pre>{{element.displayAttributes[0] | json}}</pre>
                </code>
              </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="salesDisplayColums"></tr>
        <tr mat-row *matRowDef="let row; columns: salesDisplayColums;"></tr>
      </table>

      <mat-paginator
        #salesPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="salesExportExcelButton"
        (click)="salesExporter.exportTable(exportType, exportSalesOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>

  <div *ngIf="isUsers" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="usersDataSource"
        matTableExporter
        #usersExporter="matTableExporter"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{element._id}}</td>
        </ng-container>
        <!-- Terminal Column -->
        <ng-container matColumnDef="Terminal">
          <th mat-header-cell *matHeaderCellDef>Terminal</th>
          <td mat-cell *matCellDef="let element">{{element.terminalId}}</td>
        </ng-container>

        <!-- User token Column -->
        <ng-container matColumnDef="User token">
          <th mat-header-cell *matHeaderCellDef>User token</th>
          <td mat-cell *matCellDef="let element">{{element.userToken}}</td>
        </ng-container>

        <!-- User Issuer Column -->
        <ng-container matColumnDef="User Issuer">
          <th mat-header-cell *matHeaderCellDef>User Issuer</th>
          <td mat-cell *matCellDef="let element">{{element.userIssuer}}</td>
        </ng-container>

        <!-- Created at Column -->
        <ng-container matColumnDef="Created at">
          <th mat-header-cell *matHeaderCellDef>Created at</th>
          <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
        </ng-container>

        <!-- Updated at Column -->
        <ng-container matColumnDef="Updated at">
          <th mat-header-cell *matHeaderCellDef>Updated at</th>
          <td mat-cell *matCellDef="let element">{{element.updatedAt}}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>

        <!-- Twint T&C Column -->
        <ng-container matColumnDef="Twint T&C">
          <th mat-header-cell *matHeaderCellDef>Twint T&C</th>
          <td mat-cell *matCellDef="let element">{{element.twintTC}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></tr>
      </table>

      <mat-paginator
        #usersPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="usersExportExcelButton"
        (click)="usersExporter.exportTable(exportType, exportUsersOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>

  <div *ngIf="isSessions" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="sessionsDataSource"
        matTableExporter
        #sessionsExporter="matTableExporter"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="Terminal">
          <th mat-header-cell *matHeaderCellDef>Terminal</th>
          <td mat-cell *matCellDef="let element">{{element._id}}</td>
        </ng-container>

        <!-- Count of Sessions Column -->
        <ng-container matColumnDef="Count of Sessions">
          <th mat-header-cell *matHeaderCellDef>Count of Sessions</th>
          <td mat-cell *matCellDef="let element">{{element.count}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="sessionsDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: sessionsDisplayColumns;"></tr>
      </table>
      <mat-paginator
        #sessionsPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="sessionsExportExcelButton"
        (click)="sessionsExporter.exportTable(exportType, exportSessionsOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>

  <div *ngIf="isViews" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="viewsDataSource"
        matTableExporter
        #viewsExporter="matTableExporter"
      >
        <!-- ID Column -->
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">
            {{element._id.product || element._id}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Terminal Id">
          <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
          <td mat-cell *matCellDef="let element">{{element._id.terminalId}}</td>
        </ng-container>
        <!-- Total visits Column -->
        <ng-container matColumnDef="Total visits">
          <th mat-header-cell *matHeaderCellDef>Total visits</th>
          <td mat-cell *matCellDef="let element">{{element.totalVisits}}</td>
        </ng-container>

        <!-- Unique users Column -->
        <ng-container matColumnDef="Unique users">
          <th mat-header-cell *matHeaderCellDef>Unique users</th>
          <td mat-cell *matCellDef="let element">{{element.uniqueUsers}}</td>
        </ng-container>

        <!-- Average per person Column -->
        <ng-container matColumnDef="Average per person">
          <th mat-header-cell *matHeaderCellDef>Average per person</th>
          <td mat-cell *matCellDef="let element">{{element.avgPerUser}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="viewsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: viewsDisplayedColumns;"></tr>
      </table>

      <mat-paginator
        #viewsPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="viewsExportExcelButton"
        (click)="viewsExporter.exportTable(exportType, exportViewsOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>
  <div *ngIf="isTerminal" class="table-container">
    <div>
      <table
        mat-table
        [dataSource]="terminalsDataSource"
        matTableExporter
        #terminalExporter="matTableExporter"
      >
        <!-- Terminal ID Column -->
        <ng-container matColumnDef="Terminal">
          <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
          <td mat-cell *matCellDef="let element">{{element._id}}</td>
        </ng-container>

        <!-- Total visits Column -->
        <ng-container matColumnDef="Checkins">
          <th mat-header-cell *matHeaderCellDef>Total Checkins</th>
          <td mat-cell *matCellDef="let element">
            {{element.totalNumberOfCheckIn}}
          </td>
        </ng-container>

        <!-- Total Checkouts Column -->
        <ng-container matColumnDef="Total Checkouts">
          <th mat-header-cell *matHeaderCellDef>Total Checkouts</th>
          <td mat-cell *matCellDef="let element">{{element.totalCheckouts}}</td>
        </ng-container>

        <!-- SuccessFul CheckOuts Column -->
        <ng-container matColumnDef="SuccessFul CheckOuts">
          <th mat-header-cell *matHeaderCellDef>SuccessFul CheckOuts</th>
          <td mat-cell *matCellDef="let element">
            {{element.successFullCheckOuts}}
          </td>
        </ng-container>
        <!-- Failed CheckOuts Column -->
        <ng-container matColumnDef="Failed CheckOuts">
          <th mat-header-cell *matHeaderCellDef>Failed CheckOuts</th>
          <td mat-cell *matCellDef="let element">
            {{element.failedCheckouts}}
          </td>
        </ng-container>
        <!-- Total Amount of Succesful CheckOuts Column -->
        <ng-container matColumnDef="Total Amount of CheckOuts">
          <th mat-header-cell *matHeaderCellDef>
            Total Amount of SuccessFul CheckOuts
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.totalPriceOfSuccessfullCheckouts}}
          </td>
        </ng-container>
        <!-- Conversion Rate-->
        <ng-container matColumnDef="Conversion Rate">
          <th mat-header-cell *matHeaderCellDef>Conversion Rate %</th>
          <td mat-cell *matCellDef="let element">
            {{element.conversionRate}}
            <span *ngIf="element.conversionRate !== 0">% </span>
          </td>
        </ng-container>
        <!--Drop Off Rate-->
        <ng-container matColumnDef="Drop Off Rate">
          <th mat-header-cell *matHeaderCellDef>Drop Off Rate %</th>
          <td mat-cell *matCellDef="let element">
            {{element.dropOffRate}}
            <span *ngIf="element.dropOffRate !== 0">% </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="terminalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: terminalColumns;"></tr>
      </table>

      <mat-paginator
        #terminalPaginator
        [pageSizeOptions]="[5, 10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div class="export-excel-button">
      <button
        id="terminalsExportExcelButton"
        (click)="terminalExporter.exportTable(exportType, exportTerminalsOptions)"
      >
        Export Excel
      </button>
    </div>
  </div>
</div>
